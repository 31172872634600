import React, {useState} from 'react';
import {Formik, Field} from 'formik';
import * as Yup from 'yup';
import {Container, Button, Row, Col, FormGroup, InputGroup, Form} from 'react-bootstrap';
import {useTranslation} from "react-i18next";
import * as ibantools from "ibantools"

function PaymentForm() {
    const {t, i18n} = useTranslation();
    const [paymentMethod, setPaymentMethod] = useState('');

    const initialValues = {
        paymentMethod: '',
        iban: '',
        cardNumber: '',
        expirationDate: '',
        cvv: '',
    };


    const validationSchema = Yup.object().shape({
        paymentMethod: Yup.string().required(t('common.inputRequired')),
        iban: Yup.string().when('paymentMethod', {
            is: () => 'virement',
            then: () => Yup.string().required(t('common.inputRequired')).test('is-valid-iban', i18n.t('common.invalidBAN'), (value) => {
                return ibantools.isValidIBAN(value)
            }),
        }),
        cardNumber: Yup.string().when('paymentMethod', {
            is: () => 'carte',
            then: () => Yup.string().required(t('common.inputRequired')),
        }),
        expirationDate: Yup.string().when('paymentMethod', {
            is: () => 'carte',
            then: () => Yup.string().required(t('common.inputRequired')).test('is-valid-format',t('common.invalidExpirationDateFormat'),(value)=>{
                const regex = /^(0[1-9]|1[0-2])\/\d{2}$/;
                return regex.test(value);
            }),
        }),
        cvv: Yup.string().when('paymentMethod', {
            is: () => 'carte',
            then: () => Yup.string().required(t('common.inputRequired')).max(3, t("common.inputMinLength",{value:3})).min(3,t('common.inputMaxLength',{value:3})),
        }),
    });

    const handleSubmit = (values: any) => {
        alert(JSON.stringify(values, null, 2));
    };

    return (
        <Container className="mt-5">
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({handleSubmit,values, setFieldValue,touched,errors}) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Row>
                            <Col xs={12} md={12} lg={12}>
                                <h4>{t('common.paymentMethods')}</h4>
                            </Col>
                        </Row>
                        <Row className="py-2">
                            <Col sm={12} md={12}>
                                <label>{t('common.pleaseSelectPaymentMode')}</label>
                            </Col>
                            <Col sm={12} md={12} className="d-flex flex-row py-3">
                                <div className="form-check">
                                    <Field
                                        type="radio"
                                        name="paymentMethod"
                                        value="virement"
                                        className="form-check-input"
                                        onClick={() => setPaymentMethod('virement')}
                                    />
                                    <label className="form-check-label">{t('common.transfert')}</label>
                                </div>
                                <div className="form-check">
                                    <Field
                                        type="radio"
                                        name="paymentMethod"
                                        value="carte"
                                        className="form-check-input"
                                        onClick={() => setPaymentMethod('carte')}
                                    />
                                    <label className="form-check-label">{t('common.card')}</label>
                                </div>
                            </Col>
                        </Row>

                        {values.paymentMethod === 'virement' && (
                            <Row className="py-2">

                                <FormGroup controlId="paymentMethodIBAN" as={Col}>
                                    <Form.Label>N° {t('common.iban')}</Form.Label>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text id="iban-addon">
                                            {t('common.iban')}
                                        </InputGroup.Text>
                                        <Form.Control aria-descibanedby="iban-addon"
                                                      aria-label={t('common.iban')}
                                                      name="iban"

                                                      onChange={(e) => setFieldValue('iban', e.target.value)}
                                                      isInvalid={touched?.iban && !!errors?.iban}/>
                                        <Form.Control.Feedback type="invalid">
                                            {errors?.iban}
                                        </Form.Control.Feedback>

                                    </InputGroup>

                                </FormGroup>

                            </Row>
                        )}

                        {values.paymentMethod === 'carte' && (
                            <>
                                <Row className="mb-3">
                                    <Col sm={12} md={12} lg={12}>
                                        <Form.Group controlId="paymentFormCardNumber">
                                            <Form.Label>{t('common.cardNumber')}</Form.Label>
                                            <Form.Control type="text" placeholder={t('common.pleaseEnterCardNumber')}
                                                          aria-label={t('common.cardNumber')}
                                                          name="cardNumber"

                                                          onChange={(e) => setFieldValue('cardNumber', e.target.value)}
                                                          isInvalid={touched?.cardNumber && !!errors?.cardNumber}/>
                                            <Form.Control.Feedback type="invalid">
                                                {errors?.cardNumber}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row className="mb-3">
                                    <Col sm={6} md={6} lg={6}>
                                        <Form.Group controlId="paymentFormExpirationDate">
                                            <Form.Label>{t('common.expirationDate')}</Form.Label>
                                            <Form.Control type="text" placeholder="MM/AA"
                                                          aria-label={t('common.expirationDate')}
                                                          name="expirationDate"
                                                          onChange={(e) => setFieldValue('expirationDate', e.target.value)}
                                                          isInvalid={touched?.expirationDate && !!errors?.expirationDate}/>
                                            <Form.Control.Feedback type="invalid">
                                                {errors?.expirationDate}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                    </Col>
                                    <Col sm={6} md={6} lg={6}>
                                        <Form.Group controlId="paymentFormCVV">
                                            <Form.Label>{t('common.CVV')}</Form.Label>
                                            <Form.Control type="text" placeholder="CVV"
                                                          aria-label={t('common.cVV')}
                                                          name="cvv"
                                                          onChange={(e) => setFieldValue('cvv', e.target.value)}
                                             isInvalid={touched?.cvv && !!errors?.cvv}/>
                                    <Form.Control.Feedback type="invalid">
                                        {errors?.cvv}
                                    </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </>
                        )}
                        <Row>
                            <Col xs={12} md={12} lg={12} className="justify-content-center text-center">
                                <Button variant="primary" type="submit" className="mt-3">
                                    {t('common.save')}
                                </Button>
                            </Col>
                        </Row>

                    </Form>
                )}
            </Formik>
        </Container>
    );
}

export default PaymentForm;
