import {MapContainer, TileLayer, Marker, Tooltip} from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import {useState, useEffect} from 'react';
import {Card, Button, Form} from 'react-bootstrap';
import axios from 'axios'; // for making API requests
import {FaFilter, FaChartBar} from 'react-icons/fa';
import EventsService from "../../../services/EventsService"; // Import FontAwesome icons

// @ts-ignore
import markerIcon from 'leaflet/dist/images/marker-icon.png';  // Path to default marker icon
// @ts-ignore
import markerShadow from 'leaflet/dist/images/marker-shadow.png'; // Path to marker shadow
import L from 'leaflet';
import {OptionType} from "../../../@interfaces/OptionType";
// Define custom icon
const customIcon = L.icon({
    iconUrl: markerIcon,
    shadowUrl: markerShadow,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
    shadowSize: [41, 41],
});
const EventsAndVenuesCount = () => {
    const [venues, setVenues] = useState<any[]>([]);
    const [totalEventCount, setTotalEventCount] = useState(0);
    const [totalVenueCount, setTotalVenueCount] = useState(0);
    const [filter, setFilter] = useState({
        start_date: '',
        end_date: '',
        tags: [] as OptionType[],
        city: '',
        zipcode: ''
    });
    const [showFilterForm, setShowFilterForm] = useState(true); // State to toggle form and count card

    // API call to fetch filtered venues and event counts
    const fetchEventsAndVenues = async (filterParams: any) => {

        try {
            const response = await EventsService.retrieveEventsAndVenuesCounters(filterParams)
            setVenues(response.data.venues);
            setTotalEventCount(response.data.total_event_counter);
            setTotalVenueCount(response.data.total_venue_counter);
        } catch (error) {
            console.error("Error fetching venues:", error);
        } finally {
            console.log('hello there')
        }
    };

    // Call API when filter is applied
    const applyFilter = (e: any) => {
        e.preventDefault();
        fetchEventsAndVenues(filter);
        setShowFilterForm(false); // Switch to count view after applying filters
    };

    // Handling form input changes
    const handleFilterChange = (e: any) => {
        const {name, value} = e.target;
        setFilter(prev => ({...prev, [name]: value}));
    };

    // Initial fetch on component mount
    useEffect(() => {
        fetchEventsAndVenues(filter);
    }, []);


    const createCustomClusterIcon = (cluster: any) => {

        const markers = cluster.getAllChildMarkers();
        console.log(markers)
        let totalEvents = 0;

        markers.forEach((marker: any) => {
            const {event_counter} = marker.options;
            totalEvents += event_counter;
        });

        const iconHtml = `<div><span>${totalEvents}</span></div>`;
        return L.divIcon({
            html: iconHtml,
            className: 'custom-cluster-icon',
            iconSize: L.point(40, 40, true),
        });
    };

    return (
        <div className="d-flex" style={{height: '100vh'}}>
            {/* Left Section: Toggle between filter form and count card */}
            <div style={{width: '30%', padding: '10px', overflow: "auto"}}>
                <div className="d-flex justify-content-end mb-3">
                    {/* Filter Icon */}
                    <FaFilter
                        size={24}
                        onClick={() => setShowFilterForm(true)}
                        style={{cursor: 'pointer', color: showFilterForm ? 'blue' : 'gray'}}
                    />
                    {/* Result Icon */}
                    <FaChartBar
                        size={24}
                        onClick={() => setShowFilterForm(false)}
                        style={{cursor: 'pointer', color: !showFilterForm ? 'blue' : 'gray'}}
                    />
                </div>

                {showFilterForm ? (
                    <Card className="mb-3">
                        <Card.Body>
                            <Card.Title>Filter Events</Card.Title>
                            <Form onSubmit={applyFilter}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Date</Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="date"
                                        value={filter.start_date}
                                        onChange={handleFilterChange}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Postal Code</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="postalCode"
                                        value={filter.zipcode}
                                        onChange={handleFilterChange}
                                        placeholder="Enter postal code"
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>City</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="city"
                                        value={filter.city}
                                        onChange={handleFilterChange}
                                        placeholder="Enter city"
                                    />
                                </Form.Group>
                                <Button type="submit" variant="primary">Apply Filter</Button>
                            </Form>
                        </Card.Body>
                    </Card>
                ) : (
                    <Card className="mb-3">
                        <Card.Body>
                            <Card.Title>Event and Venue Summary</Card.Title>
                            <Card.Text>
                                <strong>Total Events:</strong> {totalEventCount} <br/>
                                <strong>Total Venues:</strong> {totalVenueCount}
                            </Card.Text>
                            <Button variant="secondary" onClick={() => setFilter({
                                start_date: '',
                                end_date: '',
                                tags: [],
                                city: '',
                                zipcode: ''
                            })}>Reset
                                Filter</Button>
                        </Card.Body>
                    </Card>
                )}
            </div>

            {/* Map Section (Always Visible) */}
            <div style={{width: '70%', height: '100%'}}>
                <MapContainer center={[46.6034, 1.8883]} zoom={6} style={{height: "100%"}}>
                    <TileLayer url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"/>
                    <MarkerClusterGroup>
                        {venues
                            .filter(venue => venue.lat !== 0 && venue.lon !== 0) // Filter out invalid locations
                            .map((venue, index) => (
                                <Marker
                                    key={index}
                                    position={[venue.lat, venue.lon]}
                                    icon={customIcon}
                                >
                                    <Tooltip permanent>
                                        {venue.event_counter} event(s) at this venue
                                    </Tooltip>
                                </Marker>
                            ))}
                    </MarkerClusterGroup>
                </MapContainer>
            </div>
        </div>
    );
};

export default EventsAndVenuesCount;
