import AccountPage from "../pages/AccountPage/AccountPage";
import EventsPage from "../pages/EventsPage/EventsPage";
import i18next from 'i18next';
import "../i18n/i18n"
import CompaniesListPage from "../pages/CompaniesListPage/CompaniesListPage";
import VenueListPage from "../pages/VenueListPage/VenueListPage";
import AccountListPage from "../pages/AccountListPage/AccountListPage";
import SettingsPage from "../pages/SettingsPage/SettingsPage";
import RegistrationRequestsListPage from "../pages/RegistrationRequestsListPage/RegistrationRequestsListPage";
import ApiKeysListPage from "../pages/ApiKeysListPage/ApiKeysListPage";
import DashboardPage from "../pages/DashboardPage/DashboardPage";
import ITRListPage from "../pages/ITRListPage/ITRListPage";


export interface Route {
    path: string;
    element?: React.ReactNode;
    showOnSideBar: boolean;
    children?: Route[];
    label: string;
    icon?: string;
    protected: boolean;
    isForTicketrTeamOnly:boolean;
}

const routes: Route[] = [
    {
        path: "/",
        element: <EventsPage/>,
        showOnSideBar: false,
        label: 'sideBar.dashboard',
        icon: "bi bi-grid",
        protected: true,
        isForTicketrTeamOnly:true,
    },
    {
        path: "/restricted-access",
        element: (
            <div>
                "restricted-access"
            </div>
        ),
        showOnSideBar: false,
        label: 'sideBar.forbiddenAccess',
        icon: "bi bi-restricted",
        protected: false,
        isForTicketrTeamOnly:false,
    },
    {
        path: "/settings",
        element: <SettingsPage />,
        icon: "bi bi-ticket-fill",
        showOnSideBar: false,
        label: 'common.settings',
        protected: true,
        isForTicketrTeamOnly:false,
    },
    {
        path: "/events",
        element: <EventsPage/>,
        icon: "bi bi-ticket-fill",
        showOnSideBar: true,
        label: 'sideBar.events',
        protected: true,
        isForTicketrTeamOnly:false,
    },
    {
        path: "/chanel-manager",
        element: (
            <div>
                {i18next.t('sideBar.chanelManager')}
            </div>
        ),
        showOnSideBar: false,
        label: 'sideBar.chanelManager',
        icon: "bi bi-grid-fill",
        protected: true,
        isForTicketrTeamOnly:false,
    },
    {
        path: "/statistique",
        element: (
            <div>
                {i18next.t('sideBar.statistics')}
            </div>
        ),
        showOnSideBar: false,
        label: 'sideBar.statistics',
        icon: "bi bi-file-bar-graph-fill",
        protected: true,
        isForTicketrTeamOnly:false,
    },
    {
        path: "/account",
        element: <AccountPage/>,
        icon: "bi bi-ticket-fill",
        showOnSideBar: false,
        label: 'sideBar.account',
        protected: true,
        isForTicketrTeamOnly:false,
    },

    {
        path: "/venues",
        element: <VenueListPage/>,  // Corrected from string to JSX
        icon: "bi bi-buildings-fill",  // Fixed the icon reference
        showOnSideBar: true,
        label: 'sideBar.venues',
        protected: true,
        isForTicketrTeamOnly:false,
    },
    {
        path: "/companies",
        element: <CompaniesListPage/>,
        icon: "bi bi-briefcase-fill",
        showOnSideBar: true,
        label: 'sideBar.companies',
        protected: true,
        isForTicketrTeamOnly:true,
    },
    {
        path: "/accounts",
        element: <AccountListPage/>,  // Corrected from string to JSX
        icon: "bi bi-file-person-fill",
        showOnSideBar: true,
        label: 'sideBar.users',
        protected: true,
        isForTicketrTeamOnly:false,
    },
     {
        path: "/registration-requests",
        element: <RegistrationRequestsListPage/>,
        icon: "bi bi-envelope",
        showOnSideBar: true,
        label: 'sideBar.registrationRequest',
        protected: true,
        isForTicketrTeamOnly:true,
    },
    {
        path: "/api-keys",
        element: <ApiKeysListPage/>,  // Corrected from string to JSX
        icon: "bi bi-key",
        showOnSideBar: false,
        label: 'sideBar.apiKeys',
        protected: true,
        isForTicketrTeamOnly:true,
    },
    {
        path: "/itrs",
        element: <ITRListPage/>,  // Corrected from string to JSX
        icon: "bi bi-key",
        showOnSideBar: false,
        label: 'sideBar.apiKeys',
        protected: true,
        isForTicketrTeamOnly:true,
    },

];

export default routes;
