import {Badge, Button, Carousel, Col, Container, Form, Row, Table} from "react-bootstrap";
import {EventInterface} from "../../../@interfaces/EventInterface";
import AlertModal from "../../../modals/AlertModal/AlertModal";
import React, {Key, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {formatDateTimeWithTimeZone} from "../../../utils/dateFormatter";
import EventsMap from "../EventsMap/EventsMap";
import {LatLng} from "leaflet";
import {EventForMapInterface} from "../../../@interfaces/EventForMapInterface";
import EventDetailsModal from "../../../modals/EventDetailsModal/EventDetailsModal";
import {getCountryName} from "../../../utils/utils";
// @ts-ignore
import eventCuate from "../../../assets/events-cuate.svg"
// @ts-ignore
import emptyState from "../../../assets/empty-state.svg"

interface EventSharedWithMeViewProps {
    events: EventInterface[];
    viewMode: string
}

function EventSharedWithMe({events, viewMode}: EventSharedWithMeViewProps) {
    const {t, i18n} = useTranslation();

    const [modalShow, setModalShow] = useState(false);

    const [selectedAll, setSelectedAll] = useState(false);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState(events.map(event => false));
    const [eventsForMapView, setEventsForMapView] = useState<EventForMapInterface[]>([]);
    const [eventToBeShow, setEventToBeShow] = useState<EventInterface>({} as EventInterface);

    useEffect(() => {
        setSelectedCheckboxes(events.map(() => false));
        setSelectedAll(false);

        const eventsForMap = events.map((value: EventInterface) => {

            if (value.venue?.address) {
                return {
                    id: value.id,
                    name: value.name,
                    category: value.tags.map((data) => data.name).join(','),
                    position: new LatLng(value.venue.address?.latitude, value.venue.address?.longitude), // Paris
                    iconUrl: value.image_urls[0],
                    details: value
                }
            }
            return null

        }).filter((event) => event != null)
        setEventsForMapView(eventsForMap)
    }, [events]);


    //this is also used to handle all selected checkbox
    const handleSelectAll = () => {
        const newSelectedAll = !selectedAll;
        setSelectedAll(newSelectedAll);
        setSelectedCheckboxes(events.map(() => newSelectedAll));
    };

    // this is used to handle simple select
    const handleSelectCheckbox = (index: any) => {
        const newSelectedCheckboxes = [...selectedCheckboxes];
        newSelectedCheckboxes[index] = !newSelectedCheckboxes[index];
        setSelectedCheckboxes(newSelectedCheckboxes);
        setSelectedAll(newSelectedCheckboxes.every(selected => selected));
    };

    return (
        <>
            {selectedCheckboxes.filter(val => val).length > 0 &&
                <Container>
                    <Row className="pt-3">
                        <Col xs={12} sm={12} md={12}>
                            <Button className="btn-sm rounded"
                                    variant="primary"><i className="bi bi-share-fill"></i> {t('common.action')}</Button>
                        </Col>
                    </Row>
                </Container>
            }
            {viewMode === "map" && <EventsMap events={eventsForMapView} showEventDetails={(event: EventInterface) => {
                setModalShow(true);
                setEventToBeShow(event)
            }}/>}
            {viewMode === "list" &&
                <Table striped responsive>
                    <thead className="thead-light">
                    <tr>
                        <th>#</th>

                        <th>
                            <Form.Check
                                type="checkbox"
                                id={`select-all-checkbox`}
                                checked={selectedAll}
                                onChange={handleSelectAll}
                            />
                        </th>
                        <th></th>
                        <th>{t('common.title')}</th>
                        <th>{t('common.venue')}</th>
                        <th>{t('common.tags')}</th>
                        {/*<th>{t('common.owner')}</th>
                        <th>{t('common.source')}</th>*/}
                        <th>{t('common.localStartDate')}</th>
                        <th>{t('common.localEndDate')}</th>
                        <th>{t('common.lastUpdatedAt')}</th>
                        <th>{t('common.action')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {events.length > 0 ? (

                        events.map((event: EventInterface, index) => (
                            <tr key={event.id}>
                                <td>{index + 1}</td>
                                <td>
                                    <Form.Check // prettier-ignore
                                        type="checkbox"
                                        id={`checkbox-${event.id}`}
                                        checked={selectedCheckboxes[index] || false}
                                        onChange={() => handleSelectCheckbox(index)}
                                    />
                                </td>
                                <td>
                                    <Carousel indicators={false} slide={false} style={{
                                        width: '100px', // largeur fixe
                                        height: '50px', // hauteur fixe
                                        objectFit: 'cover', // conserver le ratio d'aspect
                                    }}>
                                        {event.image_urls.map((img: string | undefined, index: Key | null | undefined) => (
                                            <Carousel.Item key={index}>
                                                <img
                                                    className="d-block"
                                                    src={img}
                                                    alt={`Slide ${index}`}
                                                    style={{
                                                        width: '100px', // largeur fixe
                                                        height: '50px', // hauteur fixe
                                                        objectFit: 'cover', // conserver le ratio d'aspect
                                                    }}
                                                    onError={(e:any) => e.target.src = eventCuate}
                                                />
                                            </Carousel.Item>
                                        ))}
                                    </Carousel>

                                </td>
                                <td>{event.name}</td>
                                <td>{event.venue ? `${event.venue?.name} (${event.venue?.address?.address}, ${event.venue?.address?.postal_code}, ${event.venue?.address?.city},  ${getCountryName(event.venue?.address?.country_code)}) ` : "N/A"} </td>
                                <td>{event.tags.map((tag, index) => (
                                    <Badge bg="secondary" className="me-2 mt-1"
                                           key={index}>{tag.name}</Badge>
                                ))}</td>

                                <td>{formatDateTimeWithTimeZone(event.local_start_date, `${i18n.resolvedLanguage}-${i18n.resolvedLanguage?.toUpperCase()}`)}</td>
                                <td>{formatDateTimeWithTimeZone(event.local_end_date, `${i18n.resolvedLanguage}-${i18n.resolvedLanguage?.toUpperCase()}`)}</td>
                                <td>{formatDateTimeWithTimeZone(event.created_at, `${i18n.resolvedLanguage}-${i18n.resolvedLanguage?.toUpperCase()}`)}</td>
                                <td>
                                    <Button variant="outline-primary" size="sm" onClick={() => {
                                        setModalShow(true)
                                        setEventToBeShow(event)
                                    }}>
                                        <i className="bi bi-eye-fill"></i>
                                    </Button>
                                </td>
                            </tr>
                        ))) : (
                        <tr className="bg-white">
                            <td colSpan={11} className="text-center bg-red">
                                <div className="my-3">
                                    <i className="bi bi-info-circle"></i> Aucun événement disponible pour le moment.
                                </div>
                                <img src={emptyState} alt="No data found " className="login-logo"
                             height={400}/>
                            </td>
                        </tr>
                    )}

                    </tbody>
                </Table>
            }
            <AlertModal show={modalShow} onHide={() => setModalShow(false)} title={"Details d'évènement"}
                        body={"An automated Boy wil be there "}/>
            <EventDetailsModal show={modalShow} event={eventToBeShow} onHide={() => setModalShow(false)}/>
        </>
    )
}

export default EventSharedWithMe