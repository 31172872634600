import {Button, Col, Form, FormGroup, InputGroup, Row, Spinner} from "react-bootstrap";
import 'react-phone-number-input/style.css'
import PhoneInput, {isValidPhoneNumber} from 'react-phone-number-input';

import {useTranslation} from "react-i18next";
import React, {Fragment, useState} from "react";
import * as yup from "yup";
import {Formik} from "formik";

import {LegalStatus} from "../../../utils/legalStatusEnum";
import Select from "react-select";
import countries from "../../../utils/countries";
import fr from "react-phone-number-input/locale/fr.json";
import en from "react-phone-number-input/locale/en.json";
import {OptionType} from "../../../@interfaces/OptionType";
import {BUSINESS_SECTORS, COMPANY_LEGAL_STATUS_LIST} from "../../../utils/constants";
import {createSiretValidation} from "../../../utils/validation";
import AuthenticationService from "../../../services/AuthenticationService";
import showToast from "../../../utils/showToast";
import {isEmpty} from "../../../utils/utils";

const renderLegalStatusOptions = (option: string) => {
    return <option value={option}>{option}</option>;
};
const legalStatusOptions = COMPANY_LEGAL_STATUS_LIST;

const businessSectorsOption: OptionType[] = BUSINESS_SECTORS;

type CompanySelfRegistrationFormProps = {
    stepOneFormData: {
        name: string,
        businessName: string,
        email: string,
        accountType: string,
        plan: string,
        firstname: string,
        lastname: string
    } | null,
    stepTwoFormData: {
        name: string,
        headOfficeAddress: string,
        headOfficePostalCode: string,
        headOfficeCity: string,
        siren: string,
        siretNumber: string,
    } | null,
    setIsFormSubmitted: () => void,
}


function CompanySelfRegistrationForm({
                                         stepOneFormData,
                                         stepTwoFormData,
                                         setIsFormSubmitted
                                     }: CompanySelfRegistrationFormProps) {
    const {t, i18n} = useTranslation();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const formInitialValues = {
        name: stepOneFormData ? stepOneFormData.name : '',
        businessName: stepOneFormData ? stepOneFormData.businessName : '',
        businessSector: [],
        headOfficeCountry: 'FR',
        legalStatus: '',
        headOfficeAddress: stepTwoFormData ? stepTwoFormData.headOfficeAddress : '',
        headOfficePostalCode: stepTwoFormData ? stepTwoFormData.headOfficePostalCode : '',
        headOfficeCity: stepTwoFormData ? stepTwoFormData.headOfficeCity : '',
        headOfficeRegion: stepTwoFormData ? stepTwoFormData.headOfficeCity : '',
        headOfficeAdditionalAddress: '',
        email: '',
        phoneNumber: '',
        websiteUrl: '',
        vatNumber: '',
        siretNumber: stepTwoFormData ? stepTwoFormData.siretNumber : '',
    };


    const CompanySelfRegistrationFormSchema = yup.object().shape({
        name: yup.string().required(i18n.t('common.inputRequired')),
        businessName: yup.string().optional(),
        businessSector: yup.array().optional(),
        headOfficeCountry: yup.string().required(i18n.t('common.inputRequired')),
        legalStatus: yup.string().required(i18n.t('common.inputRequired')),
        headOfficeAddress: yup.string().required(i18n.t('common.inputRequired')),
        headOfficeAdditionalAddress: yup.string().optional(),
        headOfficePostalCode: yup.string().required(i18n.t('common.inputRequired')),
        headOfficeCity: yup.string().required(i18n.t('common.inputRequired')),
        headOfficeRegion: yup.string().required(i18n.t('common.inputRequired')),
        email: yup.string().email('common.invalidEmail').required(i18n.t('common.inputRequired')),
        phoneNumber: yup.string().required(i18n.t('common.inputRequired')).test('is-valid-phone-number', i18n.t('common.invalidPhoneNumber'), (value) => {
                return isValidPhoneNumber(value)
            }
        ),
        websiteUrl: yup.string().optional().url(i18n.t('common.inputURLInvalid')),
        vatNumber: yup.string().optional(),
        siretNumber: yup.string().when(['legalStatus', 'headOfficeCountry'], {
            is: (legalStatus: LegalStatus, headOfficeCountry: string) => [LegalStatus.SAS, LegalStatus.SARL, LegalStatus.SASU, LegalStatus.SCP, LegalStatus.EURL].includes(legalStatus) && headOfficeCountry == "FR",
            then: () => createSiretValidation(), // Utiliser le schéma SIRET
            otherwise: () => yup.string().optional() // Validation si la condition est fausse
        })
    })


    const handleFormSubmit = async (values: any) => {
        setIsLoading(true)
        const payload = {
            account: {
                firstname: stepOneFormData?.firstname,
                lastname: stepOneFormData?.lastname,
                email_address: stepOneFormData?.email,
                description: null
            },
            address: {
                address: values.headOfficeAddress,
                city: values.headOfficeCity,
                postal_code: values.headOfficePostalCode,
                country_code: values.headOfficeCountry,
            },
            company: {
                name: values.name,
                siret_number: values.siretNumber,
                type: stepOneFormData?.accountType.toLowerCase(),
                plan: stepOneFormData?.plan.toLowerCase(),
                business_name: values.businessName,
                description: null,
                email_address: values.email,
                phone_number: values.phoneNumber,
                website_url: values.websiteUrl,
            }
        }

        if(isEmpty(payload.company.website_url)) {delete payload.company.website_url}

        try {
            await AuthenticationService.registerRequest(payload);
            setIsLoading(false)
            setIsFormSubmitted()

        } catch (e: any) {
            setIsLoading(false);
            showToast(t('common.errorWhileSubmitRequestForm'), 'error');
            console.error(e)

        }


    }

    const getCountryName = (countryCode: string): string => {
        return i18n.t(`country.${countryCode}`);
    };

    // @ts-ignore
    return (
        <div>
            <Formik
                validationSchema={CompanySelfRegistrationFormSchema}
                onSubmit={handleFormSubmit}
                initialValues={formInitialValues}
                validateOnBlur={true}
                validationOnChange={false}
            >
                {({handleSubmit, setFieldValue, validateField, values, touched, errors}) => (
                    <Form noValidate onSubmit={handleSubmit}>

                        <Row>

                            <Row className="mb-3">
                                <Col md={12}>
                                    <Row className="mb-2">
                                        <Col md={12} lg={12} sm={12}>
                                            <FormGroup controlId="companyFormSIRET">
                                                <Form.Label>N° {t('common.siret')}</Form.Label>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text id="siret-addon">
                                                        {t('common.siret')}
                                                    </InputGroup.Text>
                                                    <Form.Control aria-describedby="siret-addon"
                                                                  aria-label={t('common.siret')}
                                                                  name="siretNumber"
                                                                  value={values.siretNumber || ''}
                                                                  onChange={(e) => setFieldValue('siretNumber', e.target.value)}
                                                                  isInvalid={touched?.siretNumber && !!errors?.siretNumber}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors?.siretNumber}
                                                    </Form.Control.Feedback>
                                                </InputGroup>

                                            </FormGroup>
                                        </Col>
                                        <Col md={12} lg={12} sm={12}>
                                            <Form.Group controlId="companyFormName">
                                                <Form.Label>{t('companyForm.companyName')}</Form.Label>
                                                <Form.Control type="text"
                                                              placeholder={t('companyForm.companyName')}
                                                              aria-label={t('companyForm.companyName')}
                                                              name="name"
                                                              value={values.name || ''}
                                                              onChange={(e) => setFieldValue('name', e.target.value)}
                                                              isInvalid={touched?.name && !!errors?.name}/>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors?.name}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Col md={6} lg={6} sm={12}>
                                            <Form.Group controlId="companyFormBusinessName">
                                                <Form.Label>{t('companyForm.businessName')}</Form.Label>
                                                <Form.Control type="text"
                                                              placeholder={t('companyForm.businessName')}
                                                              aria-label={t('companyForm.businessName')}
                                                              name="businessName"
                                                              value={values.businessName || ''}
                                                              onChange={(e) => setFieldValue('businessName', e.target.value)}
                                                              isInvalid={touched?.businessName && !!errors?.businessName}/>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors?.businessName}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group controlId="companyFormBusinessSector">
                                                <Form.Label>{t('companyForm.businessSector')}</Form.Label>
                                                {/*<Select
                                                    closeMenuOnSelect={false}
                                                    onChange={(selected) => setFieldValue('businessSector', selected)}
                                                    isMulti

                                                    value={values?.businessSector}
                                                />
                                                {touched?.businessSector && errors?.businessSector && (
                                                    <div className="invalid-feedback d-block">
                                                        {errors?.businessSector as string}
                                                    </div>
                                                )}*/}
                                                <Form.Control type="text"
                                                              placeholder={t('companyForm.businessSector')}
                                                              aria-label={t('companyForm.businessSector')}
                                                              name="businessSector"
                                                              value={values.businessSector || ''}
                                                              onChange={(e) => setFieldValue('businessSector', e.target.value)}
                                                              isInvalid={touched?.businessSector && !!errors?.businessSector}/>
                                                 <Form.Control.Feedback type="invalid">
                                                    {errors?.businessSector}
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                        </Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Col md={12} lg={12} sm={12}>
                                            <Form.Group controlId="companyFormLegalStatus">
                                                <Form.Label>Raison social</Form.Label>
                                                <Form.Select aria-label="Raison sociale"
                                                             value={values.legalStatus || ''}
                                                             name="legalStatus"
                                                             onChange={(e) => {
                                                                 setFieldValue('legalStatus', e.target.value);
                                                             }}
                                                             isInvalid={touched?.legalStatus && !!errors?.legalStatus}>
                                                    <option>{t('common.pleaseSelect')}.</option>
                                                    {legalStatusOptions.map((opt) => (
                                                        <Fragment
                                                            key={opt}>{renderLegalStatusOptions(opt)}</Fragment>
                                                    ))}
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors?.legalStatus}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col md={6} lg={6} sm={12}>
                                    <Form.Group controlId="companyFormCountry">
                                        <Form.Label>{t('companyForm.companyHeadOfficeCountry')}</Form.Label>
                                        <Form.Select aria-label={t('companyForm.companyHeadOfficeCountry')}

                                                     value={values.headOfficeCountry || ''}
                                                     name="headOfficeCountry"
                                                     onChange={(e) => setFieldValue('headOfficeCountry', e.target.value)}
                                                     isInvalid={touched?.headOfficeCountry && !!errors?.headOfficeCountry}>
                                            <option>{t('common.pleaseSelect')}</option>
                                            {countries.map((country) => (
                                                <option key={country.code} value={country.code}>
                                                    {getCountryName(country.code)}
                                                </option>
                                            ))}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            {errors?.headOfficeCountry}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                                <Col md={6} lg={6} sm={12}>
                                    <Form.Group controlId="companyFormRegion">
                                        <Form.Label>{t('companyForm.companyDistrict')}</Form.Label>
                                        <Form.Select aria-label={t('companyForm.companyDistrict')}

                                                     name="headOfficeRegion"
                                                     value={values.headOfficeRegion || ''}
                                                     onChange={(e) => setFieldValue('headOfficeRegion', e.target.value)}
                                                     isInvalid={touched?.headOfficeRegion && !!errors?.headOfficeRegion}
                                        >
                                            <option>{t('common.pleaseSelect')}</option>
                                            <option value="NA">Nouvelle Aquitaine</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            {errors?.headOfficeRegion}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col md={8} lg={0} sm={12} className="mb-3">
                                    <Form.Group controlId="companyFormAdress">
                                        <Form.Label>{t('companyForm.companyAddress')}</Form.Label>
                                        <Form.Control type="text" placeholder={t('companyForm.companyAddress')}
                                                      aria-label={t('companyForm.companyAddress')}
                                                      name="headOfficeAddress"
                                                      value={values.headOfficeAddress || ''}
                                                      onChange={(e) => setFieldValue('headOfficeAddress', e.target.value)}
                                                      isInvalid={touched?.headOfficeAddress && !!errors?.headOfficeAddress}/>
                                        <Form.Control.Feedback type="invalid">
                                            {errors?.headOfficeAddress}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col md={4} lg={4} sm={12} className="mb-3">
                                    <Form.Group controlId="companyAdditionalAddress">
                                        <Form.Label>{t('companyForm.companyAdditionalAddress')}</Form.Label>
                                        <Form.Control type="text"
                                                      placeholder={t('companyForm.companyAdditionalAddress')}
                                                      aria-label={t('companyForm.companyAdditionalAddress')}
                                                      name="headOfficeAdditionalAddress"
                                                      value={values.headOfficeAdditionalAddress || ''}
                                                      onChange={(e) => setFieldValue('headOfficeAdditionalAddress', e.target.value)}
                                                      isInvalid={touched?.headOfficeAdditionalAddress && !!errors?.headOfficeAdditionalAddress}/>
                                        <Form.Control.Feedback type="invalid">
                                            {errors?.headOfficeAdditionalAddress}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row className="mb-2">
                                <Col md={6} lg={6} sm={12}>
                                    <Form.Group controlId="companyFormPostalCode">
                                        <Form.Label>{t('companyForm.companyPostalCode')}</Form.Label>
                                        <Form.Control type="text" placeholder={t('companyForm.companyPostalCode')}
                                                      aria-label={t('companyForm.companyPostalCode')}
                                                      name="headOfficePostalCode"
                                                      value={values.headOfficePostalCode || ''}
                                                      onChange={(e) => setFieldValue('headOfficePostalCode', e.target.value)}
                                                      isInvalid={touched?.headOfficePostalCode && !!errors?.headOfficePostalCode}/>
                                        <Form.Control.Feedback type="invalid">
                                            {errors?.headOfficePostalCode}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col md={6} lg={6} sm={12}>
                                    <Form.Group controlId="companyFormPostalCity">
                                        <Form.Label>{t('companyForm.companyCity')}</Form.Label>
                                        <Form.Control type="text" placeholder={t('companyForm.companyCity')}
                                                      aria-label={t('companyForm.companyCity')}
                                                      name="headOfficeCity"
                                                      value={values.headOfficeCity || ''}
                                                      onChange={(e) => setFieldValue('headOfficeCity', e.target.value)}
                                                      isInvalid={touched?.headOfficeCity && !!errors?.headOfficeCity}/>
                                        <Form.Control.Feedback type="invalid">
                                            {errors?.headOfficeCity}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col md={6} lg={6} sm={12}>
                                    <Form.Group controlId="companyFormEmail">
                                        <Form.Label>{t('common.email')}</Form.Label>
                                        <Form.Control type="email" placeholder={t('common.email')}
                                                      aria-label={t('common.email')}
                                                      name="email"
                                                      value={values.email || ''}
                                                      onChange={(e) => setFieldValue('email', e.target.value)}
                                                      isInvalid={touched?.email && !!errors?.email}/>
                                        <Form.Control.Feedback type="invalid">
                                            {errors?.email}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col md={6} lg={6} sm={12}>
                                    <Form.Group controlId="companyFormPhoneCode">
                                        <Form.Label>{t('common.callSign')}</Form.Label>
                                        <PhoneInput
                                            international
                                            className="form-control"
                                            aria-label={t('common.callSign')}
                                            placeholder={t('common.pleaseEnterPhoneNumber')}
                                            labels={i18n.resolvedLanguage === "fr" ? fr : en}
                                            defaultCountry="FR"
                                            name="phoneNumber"
                                            value={values.phoneNumber || ''}
                                            onChange={(value) => setFieldValue('phoneNumber', value || '')}
                                        />

                                        {touched?.phoneNumber && errors?.phoneNumber && (
                                            <div className="invalid-feedback d-block">
                                                {errors?.phoneNumber as string}
                                            </div>
                                        )}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col md={12} lg={12} sm={12}>
                                    <FormGroup controlId="companyFormWebsite">
                                        <Form.Label>{t('common.website')}</Form.Label>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text id="basic-addon">
                                                www
                                            </InputGroup.Text>
                                            <Form.Control aria-describedby="website url addon"
                                                          aria-label={t('common.website')}
                                                          name="websiteUrl"
                                                          value={values.websiteUrl || ''}
                                                          onChange={(e) => setFieldValue('websiteUrl', e.target.value)}
                                                          isInvalid={touched?.websiteUrl && !!errors?.websiteUrl}/>
                                            <Form.Control.Feedback type="invalid">
                                                {errors?.websiteUrl}
                                            </Form.Control.Feedback>
                                        </InputGroup>

                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col md={12}
                                     lg={12} sm={12}>
                                    <FormGroup controlId="companyFormTVA">
                                        <Form.Label>N° {t('common.vat')}</Form.Label>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text id="vat-addon">
                                                {t('common.vat')}
                                            </InputGroup.Text>
                                            <Form.Control aria-describedby="vat-addon" aria-label={t('common.vat')}
                                                          name="vatNumber"
                                                          value={values.vatNumber || ''}
                                                          onChange={(e) => setFieldValue('vatNumber', e.target.value)}
                                                          isInvalid={touched?.vatNumber && !!errors?.vatNumber}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors?.vatNumber}
                                            </Form.Control.Feedback>
                                        </InputGroup>

                                    </FormGroup>
                                </Col>
                                <Col md={12} lg={12} sm={12} className="justify-content-center align-items-center">
                                    <FormGroup controlId="companySelfRegistrationSubmit">
                                        {isLoading ? (
                                            <Button className="rounded" disabled={isLoading} type="submit"> <Spinner
                                                animation="border"
                                                variant="light"
                                            /> {t('common.loading')}
                                            </Button>) : (
                                            <Button type="submit">{t('common.saveAndRegister')}</Button>
                                        )}
                                    </FormGroup>
                                </Col>
                            </Row>


                        </Row>
                    </Form>
                )
                }
            </Formik>

        </div>
    )
}

export default CompanySelfRegistrationForm;