import React, {useEffect, useState} from 'react';
import {MapContainer, TileLayer, Marker, Popup, useMap} from 'react-leaflet';
import L, {LatLngBounds} from 'leaflet';
import 'leaflet/dist/leaflet.css';
import SearchField from "../../EventsView/EventsMap/MapSearchField";

import {useTranslation} from "react-i18next";
import {VenueForMapInterface} from "../../../@interfaces/VenueForMapInterface";


interface VenuesMapProps {
    venues: VenueForMapInterface[]
}


// @ts-ignore
const FitBounds = ({bounds}) => {
    const map = useMap();

    useEffect(() => {
        if (bounds !== undefined) {
            map.fitBounds(bounds);

        } else{
            map.setZoom(6)
        }
    }, [bounds, map]);

    return null;
};


function VenuesMap({venues}: VenuesMapProps) {
    const {t} = useTranslation();
    const [bounds, setBounds] = useState<LatLngBounds|undefined>();


    useEffect(() => {
        if (venues.length > 0) {
            setBounds(L.latLngBounds(
                venues.map(venue => venue.position)
            ));
        } else{
            setBounds(undefined)
        }
    }, [venues]);
    return (
        <MapContainer zoom={8} style={{height: "100vh", width: "100%"}} center={[48.8566, 2.3522]}
                      >

            <FitBounds bounds={bounds}/>

            {<SearchField apiKey={""}/>}
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />

            {venues.map(venue => (

                <Marker key={venue.id} position={venue.position} >
                    <Popup>
                        <strong>{venue.name}</strong><br/>
                        <strong>{t('common.tags')} </strong>: {venue.category} <br/>
                        <strong>{t('common.venue')} : </strong> {venue.name} <br/>
                        <strong>{t('common.address')}
                            :</strong> {`${venue?.address.address},${venue?.address.postal_code},${venue?.address.city}`}
                        <br/>
                    </Popup>
                </Marker>
            ))}
        </MapContainer>
    );
}

export default VenuesMap;
