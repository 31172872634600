import HeaderTranslation from './layout/headerTranslation';
import SideBarTranslation from "./layout/sideBarTranslation";
import CompanyFormTranslation from "./components/companyFormTranslation";
import CommonTranslation from "./common/commonTranslation";
import CompanyManagementFormTranslation from "./components/companyManagementFormTranslation";
import EventFilterTranslation from "./components/eventFilterTranslation";
import ITRCredentialsModalTranslation from "./modals/iTRCredentialsModalTranslation";
import ShareEventModalTranslation from "./modals/shareEventModalTranslation";
import ITRBilleterieViewTranslation from "./views/iTRBilleterieViewTranslation";
import CompanyAttachmentsUploadModalTranslation from "./modals/companyAttachmentsUploadModalTranslation";
import CountriesTranslation from "./countries/countriesTranslation";

const translationEn = {
  "header": HeaderTranslation,
  "sideBar": SideBarTranslation,
  "companyForm": CompanyFormTranslation,
  "companyManagementForm": CompanyManagementFormTranslation,
  "companyAttachmentModal": CompanyAttachmentsUploadModalTranslation,
  "eventFilter": EventFilterTranslation,
  "ITRCredentialsModal": ITRCredentialsModalTranslation,
  "ShareEventModal": ShareEventModalTranslation,
  "ITRBilleterieView":ITRBilleterieViewTranslation,
  "country":CountriesTranslation,
  "common":CommonTranslation,
}

export default translationEn;