import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import translationFr from "./locales/fr/translation";
import {DateTime} from 'luxon';
import translationEn from "./locales/en/translation";

const userLocale = navigator.language || 'fr-FR';
i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: false,
        fallbackLng: 'fr',
        resources: {
            fr: {
                translation: translationFr
            },
            en:{
                translation: translationEn
            }
        },
    }).then(() => {
    if (i18n.services.formatter) {
        i18n.services.formatter.add('SHORT_WITH_SECONDS', (value, lng = userLocale, _options) => {
            return DateTime.fromJSDate(value).setLocale(lng).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
        });

        i18n.services.formatter.add('CURRENCY', (value, lng, _options) => {
            return new Intl.NumberFormat(lng, {style: 'currency', currency: _options.currency}).format(value);
        });

        i18n.services.formatter.add('UPPERCASE', (value, _lng, _options) => {
            return value.toUpperCase();
        });
    } else {
        console.error('i18n.services.formatter is not available');
    }
}).catch(err => {
    console.error('Error initializing i18n:', err);
});

export default i18n;