// MainLayout.tsx
import React, { useEffect, useState } from 'react';
import Header from "./header/Header";
import SideBar from "./side-bar/SideBar";
import { Outlet } from 'react-router-dom';
import TrialMessageComponent from "../components/TrialMessageComponent/TrialMessageComponent";
import AuthenticationService from "../services/AuthenticationService";


const MainLayout = () => {
    const [isSideBarVisible, setIsSideBarVisible] = useState(true);
    const [isSticky, setIsSticky] = useState(false);
    const [isTrialPlan, setIsTrialPlan] = useState<boolean | null>(null);
    useEffect(() => {

        const handleResize = () => {
            if (window.innerWidth >= 1199) {
                setIsSideBarVisible(true);
            } else {
                setIsSideBarVisible(false);
            }
        };

         const handleScroll = () => {
            const offset = window.scrollY;
            setIsSticky(offset > 100);
        };

        // Initial check
        handleResize();

         const checkPlan= async () => {
            try {

                const isTrialPlan = await AuthenticationService.isInTrial();
                setIsTrialPlan(isTrialPlan);

            } catch (error) {
                console.error('Error checking authentication status:', error);

            }
        };

         checkPlan()



        // Add event listener
        window.addEventListener('resize', handleResize);
        window.addEventListener('scroll', handleScroll);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('scroll', handleScroll);
        };

    }, []);

    return (
        <>
            <Header
                isSideBarVisible={isSideBarVisible}
                setIsSideBarVisible={() => setIsSideBarVisible(!isSideBarVisible)}
            />

            <SideBar isSideBarVisible={isSideBarVisible} />
            <main className={`main-content ${isSideBarVisible ? 'opened' : 'collapsed'}`} id="main">
                {isTrialPlan && <div className={`sticky-trial`}>
                    <TrialMessageComponent/>
                </div>}
                <Outlet/> {/* Renders nested routes here */}
            </main>
        </>
    );
};

export default MainLayout;
