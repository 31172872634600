import React, {useEffect, useState} from 'react';
import {MapContainer, TileLayer, Marker, Popup, useMap} from 'react-leaflet';
import L, {LatLngBounds} from 'leaflet';

import 'leaflet/dist/leaflet.css';
import {EventForMapInterface} from "../../../@interfaces/EventForMapInterface";

import SearchField from "./MapSearchField";
import {EventInterface} from "../../../@interfaces/EventInterface";
import {useTranslation} from "react-i18next";
// @ts-ignore
import eventCuate from "../../../assets/events-cuate.svg"

const createCustomIcon = (iconUrl: any) => {
    return L.divIcon({
        className: "custom-icon",
        html: `
      <div style="position: relative;">
        <img
          src="${iconUrl}"
          style="width: 30px; height: 30px; border-radius: 50%; position: absolute; top: 5px; left: 5px;"
          
        />
      </div>
    `,
        iconSize: [40, 40],
        iconAnchor: [20, 40],
        popupAnchor: [0, -40],
    });
};


interface EventsMapProps {
    events: EventForMapInterface[]
    showEventDetails: (value: EventInterface) => void
}


// @ts-ignore
const FitBounds = ({bounds}) => {
    const map = useMap();

    useEffect(() => {
        if (bounds !== undefined) {
            map.fitBounds(bounds);

        } else{
            map.setZoom(6)
        }
    }, [bounds, map]);

    return null;
};


function EventMap({events, showEventDetails}: EventsMapProps) {
    const {t} = useTranslation();
    const [bounds, setBounds] = useState<LatLngBounds|undefined>();


    const handleMarkerClick = (eventDetails: EventInterface) => {
        return (event: React.MouseEvent<HTMLAnchorElement>) => {
            event.preventDefault();
            showEventDetails(eventDetails);
        };
    };
    useEffect(() => {
        if (events.length > 0) {
            setBounds(L.latLngBounds(
                events.map(event => event.position)
            ));
        } else{
            setBounds(undefined)
        }
    }, [events]);
    return (
        <MapContainer zoom={8} style={{height: "100vh", width: "100%"}} center={[48.8566, 2.3522]}
                      >

            <FitBounds bounds={bounds}/>

            {<SearchField apiKey={""}/>}
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />

            {events.map(event => (

                <Marker key={event.id} position={event.position} icon={createCustomIcon(event.iconUrl)}>
                    <Popup>
                        <strong>{event.name}</strong><br/>
                        <strong>{t('common.tags')} </strong>: {event.category} <br/>
                        <strong>{t('common.venue')} : </strong> {event.details.venue.name} <br/>
                        <strong>{t('common.address')}
                            :</strong> {`${event.details.venue?.address.address},${event.details.venue?.address.postal_code},${event.details.venue?.address.city}`}
                        <br/>
                        <a href="#" onClick={handleMarkerClick(event.details)}>{t('common.moreDetails')}</a>
                    </Popup>
                </Marker>
            ))}
        </MapContainer>
    );
}

export default EventMap;
