// App.tsx
import React from 'react';
import { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import './styles/custom.scss';
import './App.scss';
import Router from "./router/router";
import {Toaster} from "react-hot-toast";
import LoadingComponent from "./components/LoadingComponent/LoadingComponent";

function App() {
    return (

        <Suspense fallback={<LoadingComponent />}>
            <BrowserRouter >
                <Toaster/>
                <Router />
            </BrowserRouter>
        </Suspense>
    );
}

export default App;
