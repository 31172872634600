import React, {useState} from "react";
import {Button, Form, Modal} from "react-bootstrap";
import ReadOnlyInputWithCopy from "../../components/ReadOnlyInputWithCopy/ReadOnlyInputWithCopy";
import {useTranslation} from "react-i18next";
import CompaniesService from "../../services/CompaniesService";
import {CompanyInterface} from "../../@interfaces/CompanyInterface";
import AsyncSelect from "react-select/async";
import * as yup from "yup";
import {Formik} from "formik";

import showToast from "../../utils/showToast";
import ApiKeysService from "../../services/ApiKeysService";

interface GenerateApiKeyModalProps {
    show: boolean,
    handleClose: () => void,

}

interface CompaniesResponse {
    data: CompanyInterface[];
    headers: any;
}

function GenerateApiKeyModal({show, handleClose}: GenerateApiKeyModalProps) {
    const {t} = useTranslation();
    const [apiKey, setApiKey] = useState<{ api_id: string, api_secret: string } | null>(null);
    const formInitialValues = {
        expiration_date: '',
        company_id: ''
    }
    const formValidationSchema = yup.object().shape({
        company_id: yup.string().optional(),
        expiration_date: yup.string().optional()
    })

    const handleFormSubmit = async (values: any) => {
        try {
            const response = await ApiKeysService.generateApiKeys(values);
            setApiKey(response)
            showToast(t('common.apiKeyCreatedSuccessfully'), "success");
        } catch (error: any) {
            showToast(t('common.failedToCreateApiKey'), "error")
        }


    };

    const handleCloseWithWarning = () => {
        if (apiKey) {
            const userConfirmed = window.confirm(
                "Une fois la modale fermée, vous ne pourrez plus revenir en arrière et la clé ne sera plus disponible. Voulez-vous vraiment fermer ?"
            );
            if (userConfirmed) {

                setApiKey(null)
                handleClose();
            }
        } else {
            handleClose();
        }

    };
    const loadOptions = (inputValue: string) =>
        new Promise<{ value: string, label: string }[]>((resolve) => {
            setTimeout(() => {
                CompaniesService.retrieveCompanies({
                    pagination: {
                        currentPage: 1,
                        pageSize: 0,
                    },
                    filters: {names: [{text: inputValue}]}
                }).then((response: CompaniesResponse) => {

                    const formattedOptions = response.data.map((company: any) => ({
                        value: company.id as string,
                        label: company.name as string
                    }));
                    resolve(formattedOptions);
                }, error => {
                    console.error(error);
                });
            }, 1000);
        });

    return (
        <>
            <Modal show={show} onHide={handleCloseWithWarning}>
                <Modal.Header closeButton>
                    <Modal.Title>Générer une clé API</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={formInitialValues}
                        validationSchema={formValidationSchema}
                        onSubmit={handleFormSubmit}
                    >
                        {({values, setFieldValue, handleSubmit, errors, touched}) => (
                            <>
                                <Form noValidate onSubmit={handleSubmit}>
                                    {!apiKey && (
                                        <>
                                            <Form.Group controlId="formApiKey">
                                                <AsyncSelect
                                                    cacheOptions
                                                    defaultOptions
                                                    name="company_id"
                                                    onChange={(e) => setFieldValue('company_id', e?.value)}
                                                    loadOptions={loadOptions}
                                                    className="mb-3 mt-3"
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.company_id}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group controlId="formApiKey" className="mt-2">
                                                <Form.Label>{t('common.expiredAt')}</Form.Label>
                                                <Form.Control type="date"
                                                              name="expiration_date"
                                                              value={values.expiration_date || ''}
                                                              onChange={(e) => setFieldValue('expiration_date', e.target.value)}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.expiration_date}
                                                </Form.Control.Feedback>
                                            </Form.Group> </>)}
                                    {apiKey && (
                                        <>
                                            <p className="text-danger">{t('common.pleaseCopyIdentifiers')}</p>
                                            <Form.Group controlId="formApiKey">
                                                <Form.Label>{t('common.apiKeyId')}</Form.Label>
                                                <ReadOnlyInputWithCopy value={apiKey.api_id} type={"text"}/>
                                            </Form.Group>
                                            <Form.Group controlId="formApiKey">
                                                <Form.Label>{t('common.apiKeySecret')}</Form.Label>
                                                <ReadOnlyInputWithCopy value={apiKey.api_secret} type={"password"}/>
                                            </Form.Group>
                                        </>
                                    )}
                                    {!apiKey && (<div className="text-center mt-3">
                                        <Button variant="primary" type="submit">
                                             {t('common.generate')}
                                        </Button>
                                    </div>)}


                                </Form>
                            </>)}</Formik>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseWithWarning}>
                        {t('common.close')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}


export default GenerateApiKeyModal;