import React from 'react';
import PaginationComponent from './PaginationComponent'; // Assurez-vous d'importer correctement votre composant
import {Form} from 'react-bootstrap'; // Utilisation de Form.Select (selon ce que vous utilisez pour les formulaires)
import {useTranslation} from 'react-i18next'; // Si vous utilisez i18n pour la traduction

interface PaginationWithPageSizeSelectorProps {
    itemsPerPage: number,
    totalCount: number,
    currentPage: number,
    totalPages: number,
    handlePageChange: (value: number) => void,
    handlePageSizeChange: (value: any) => void
}

function PaginationWithPageSizeSelector({
                                            itemsPerPage,
                                            totalCount,
                                            currentPage,
                                            totalPages,
                                            handlePageChange,
                                            handlePageSizeChange,
                                        }: PaginationWithPageSizeSelectorProps) {
    const {t} = useTranslation(); // Utilisation de i18n pour la traduction

    return (
        <div className="row">
            <div className="col-sm-12">
                <div className="d-md-flex justify-content-md-between justify-content-sm-center mt-4">

                    <PaginationComponent
                        itemsPerPage={itemsPerPage}
                        totalItems={totalCount}
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                    />
                    <Form.Select
                        aria-label="Default select example"
                        size="sm"
                        className="custom-select"
                        onChange={handlePageSizeChange}
                        value={itemsPerPage}
                    >
                        <option value={0}>N / {t('common.pages')}</option>
                        <option value={50}>50 {t('common.perPage')}</option>
                        <option value={100}>100 {t('common.perPage')}</option>
                        <option value={150}>150 {t('common.perPage')}</option>
                        <option value={200}>200 {t('common.perPage')}</option>
                        <option value={250}>250 {t('common.perPage')}</option>
                        <option value={2500}>2500 {t('common.perPage')}</option>
                    </Form.Select>
                </div>
            </div>
        </div>
    );
}

export default PaginationWithPageSizeSelector;
