import {Button, Col, Form, Row} from "react-bootstrap";
import React, {ForwardedRef, forwardRef,  useImperativeHandle, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import * as yup from "yup";
import {Formik} from "formik";
import ComplianceService from "../../services/ComplianceService";
import AuthenticationService from "../../services/AuthenticationService";
import showToast from "../../utils/showToast";

interface CompanySelfRegistrationViewProps {
    setRegistrationStepTwoData: (value: any) => void,
    setParentIsLoading: (value: boolean) => void,
    setNextStep: () => void,
    initialValues: any,
    stepOneFormData: {
        name: string,
        businessName: string,
        email: string,
        accountType: string,
        plan: string,
        firstname: string,
        lastname: string
    } | null,
}

interface FormHandles {
    validateForm: () => Promise<any>;
    submitForm: () => Promise<any>;


}

const LegalInformationRetrievalView = forwardRef<FormHandles, CompanySelfRegistrationViewProps>(({
                                                                                                     setRegistrationStepTwoData,
                                                                                                     setParentIsLoading,
                                                                                                     initialValues,
                                                                                                     setNextStep,
                                                                                                     stepOneFormData
                                                                                                 }: CompanySelfRegistrationViewProps, ref: ForwardedRef<FormHandles>) => {
    const [isLoading, setIsLoading] = useState(false);
    const {t, i18n} = useTranslation();
    const [siretOrSirenCheckValue, setSiretOrSirenCheckValue] = useState<any>(null);
    const formikRef = useRef<any>();

    const SirenOrSiretFormInitialValues = {
        siretOrSirenNumber: initialValues ? initialValues.siretNumber as string : '',
        legalInformationRetrievingMethod: initialValues ? initialValues.legalInformationRetrievingMethod as string : 'SIRET',
    };

    const SirenOrSiretFormSchema = yup.object().shape({
        siretOrSirenNumber: yup.string().when('legalInformationRetrievingMethod', {
            is: (value: any) => value === 'SIREN',
            then: () => yup.string().required(t('common.inputRequired'))
                .length(9, i18n.t('common.inputSiretLength', {value: 9})),
            otherwise: () => yup.string().when('legalInformationRetrievingMethod', {
                is: (value: any) => value === 'MANUAL',
                then: () => yup.string().oneOf(['manual'], t('common.inputMustBeManual')),
                otherwise: () => yup.string().required(t('common.inputRequired'))
                    .length(14, i18n.t('common.inputSiretLength', {value: 14}))
            })
        })
    });

    const handleFormSubmit = async (formValue: any) => {
        try {
            let response: any;
            let data: any = null;
            setIsLoading(true);
            setParentIsLoading(isLoading)
            switch (formValue.legalInformationRetrievingMethod) {
                case 'SIRET':

                    response = await ComplianceService.verifySiretNumber(formValue.siretOrSirenNumber);
                    setSiretOrSirenCheckValue(true)
                    data = {
                        name: response.data.etablissement.uniteLegale.denominationUniteLegale,
                        headOfficeAddress: `${response.data.etablissement.adresseEtablissement.typeVoieEtablissement} ${response.data.etablissement.adresseEtablissement.libelleVoieEtablissement}`,
                        headOfficePostalCode: response.data.etablissement.adresseEtablissement.codePostalEtablissement,
                        headOfficeCity: response.data.etablissement.adresseEtablissement.libelleCommuneEtablissement,
                        siren: response.data.etablissement.siren,
                        siretNumber: response.data.etablissement.siret,
                        legalInformationRetrievingMethod: formValue.legalInformationRetrievingMethod
                    }

                    break;
                case 'SIREN':
                    response = await ComplianceService.verifySirenNumber(formValue.siretOrSirenNumber);
                    setSiretOrSirenCheckValue(true)
                    break;
                default:
                    response = null;
                    data = {legalInformationRetrievingMethod: formValue.legalInformationRetrievingMethod}
                    const payload = {
                        account: {
                            firstname: stepOneFormData?.firstname,
                            lastname: stepOneFormData?.lastname,
                            email_address: stepOneFormData?.email,
                            description: null
                        },
                        company: {
                            name: stepOneFormData?.name,
                            type: stepOneFormData?.accountType.toLowerCase(),
                            plan: stepOneFormData?.plan.toLowerCase(),
                            business_name: stepOneFormData?.businessName,
                            description: null,
                            email_address: null,
                            phone_number: null,

                        }
                    }
                    await AuthenticationService.registerRequest(payload)
                    setSiretOrSirenCheckValue(null);
                    break;
            }

            setRegistrationStepTwoData(data);
            setIsLoading(false)
            setParentIsLoading(false)
            setNextStep();

        } catch (e: any) {
            setSiretOrSirenCheckValue(false)
            setIsLoading(false);
            setParentIsLoading(false)
            showToast(t('common.errorOccurred'), "error")
            console.error(e)

        }
    };

    // Expose methods to parent via ref
    useImperativeHandle(ref, () => {
        return {
            validateForm: () => {

                return formikRef.current?.validateForm() ?? Promise.reject();
            },
            submitForm: () => {
                return formikRef.current?.submitForm() ?? Promise.reject();
            },
            values: () => {
                return formikRef.current ?? {};
            }
        }

    }, []);


    return (
        <Row>

            <Col md={12} className="justify-content-center text-center">
                <Formik
                    innerRef={formikRef}
                    validationSchema={SirenOrSiretFormSchema}
                    onSubmit={handleFormSubmit}
                    initialValues={SirenOrSiretFormInitialValues}
                    validateOnBlur={true}
                    validationOnChange={false}
                >
                    {({handleSubmit, setFieldValue, values, touched, errors}) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <p>{t('common.legalInformationRetrievingMethod')}</p>
                            <Form.Group controlId="formSiretOrSiren" className="mb-4 mt-4">
                                <Form.Check
                                    type="radio"
                                    label={t('common.byMySiret')}
                                    name="legalInformationRetrievingMethod"
                                    id="radioSiret"
                                    value="SIRET"
                                    checked={values.legalInformationRetrievingMethod === 'SIRET'}
                                    onChange={() => setFieldValue('legalInformationRetrievingMethod', 'SIRET')}
                                    inline
                                />
                                {/*<Form.Check
                                    type="radio"
                                    label={t('common.byMySiren')}
                                    name="legalInformationRetrievingMethod"
                                    id="radioSiren"
                                    value="SIREN"
                                    checked={values.legalInformationRetrievingMethod === 'SIREN'}
                                    onChange={() => setFieldValue('legalInformationRetrievingMethod', 'SIREN')}
                                    inline
                                />*/}

                                <Form.Check
                                    type="radio"
                                    label={t('common.manual')}
                                    name="legalInformationRetrievingMethod"
                                    id="radioManual"
                                    value="MANUAL"
                                    checked={values.legalInformationRetrievingMethod === 'MANUAL'}
                                    onChange={() => {
                                        setFieldValue('legalInformationRetrievingMethod', 'MANUAL');
                                        setFieldValue('siretOrSirenNumber', '')
                                    }}
                                    inline
                                />
                            </Form.Group>

                            {values.legalInformationRetrievingMethod !== 'MANUAL' &&
                                <Form.Group controlId="formSiret">
                                    <Form.Control
                                        type="text"
                                        onChange={(e) => setFieldValue('siretOrSirenNumber', e.target.value)}
                                        placeholder={`${t('common.pleaseEnter')} ${values.legalInformationRetrievingMethod}`}
                                        isInvalid={touched?.siretOrSirenNumber && !!errors?.siretOrSirenNumber}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors?.siretOrSirenNumber}
                                    </Form.Control.Feedback>
                                    {siretOrSirenCheckValue === false && <p className="text-danger">
                                        {t('common.invalidSiretNumber')}
                                    </p>
                                    }
                                </Form.Group>
                            }

                            {values.legalInformationRetrievingMethod !== 'MANUAL' &&
                                <Button
                                    variant="primary"
                                    type="submit"
                                    disabled={isLoading}
                                    className="mt-3"
                                >
                                    {!isLoading ? t("common.retrieve") : t('common.isLoading')}
                                </Button>
                            }

                        </Form>
                    )}
                </Formik>

            </Col>
        </Row>
    );
})

export default LegalInformationRetrievalView;
