const headerTranslation = {
    newMessageCount: 'You have {{count}} new messages',
    newNotificationCount: 'You have {{count}} new notifications',
    viewAll: 'View All',
    myProfile: 'My Profile',
    accountSettings: 'Account Settings',
    signOut: 'Sign Out',
    needHelp: 'Need Help?'
}
export default headerTranslation;
