import {Button, Modal, Form, Container, Row, Col} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import * as yup from "yup";
import {Formik} from "formik";
import React, {Fragment} from "react";
import {ACCOUNT_TYPE_LIST, AVAILABLE_PLANS} from "../../utils/constants";
import PhoneInput, {isValidPhoneNumber} from "react-phone-number-input";
import fr from "react-phone-number-input/locale/fr.json";
import en from "react-phone-number-input/locale/en.json";
import i18n from "i18next";
import AuthenticationService from "../../services/AuthenticationService";
import showToast from "../../utils/showToast";
import countries from "../../utils/countries";
import {createSiretValidation} from "../../utils/validation";
import i18next from "i18next";
import {getCountryName} from "../../utils/utils";


interface CompanyInitializerFormModalProps {
    showModal: boolean;
    initialValues?:{
        account: {
            email_address: string,
            firstname: string,
            lastname: string,
        },
        company: {
            name: string,
            business_name: string,
            type: string,
            plan: string,
            email_address: string,
            phone_number: string,
            siret_number: string
        },
        address: {
            address: string,
            city: string,
            postal_code: string,
            country_code: string
        }
    },

    onHide(): void;

    fetchCompanies(): void;
}

const accountTypeOptions = ACCOUNT_TYPE_LIST;
const renderAccountTypeOptions = (option: string) => {
    return <option value={option.toLowerCase()}>{option}</option>;
};

const planTypeOptions = AVAILABLE_PLANS.filter(value => value !== "TRIAL");
const renderPlansTypeOptions = (option: string) => {
    return <option value={option.toLowerCase()}>{option}</option>;
}

function CompanyInitializerFormModal({showModal, onHide, fetchCompanies,initialValues}: CompanyInitializerFormModalProps) {
    const {t} = useTranslation();
    const formInitialValues = {
        account: initialValues?.account ?? {
            email_address: null,
            firstname: null,
            lastname: null,
        },
        company: initialValues?.company ?? {
            name: '',
            business_name: '',
            type: '',
            plan: '',
            email_address: '',
            phone_number: '',
            siret_number: ''
        },
        address: initialValues?.address ?? {
            address: null,
            city: null,
            postal_code: null,
            country_code: null
        }

    };


    const accountValidationSchema = yup.object().shape({
        firstname: yup.string().required(t('common.inputRequired')),
        lastname: yup.string().required(t('common.inputRequired')),
        email_address: yup.string().email(t('common.invalidEmail')).required(t('common.inputRequired')),
    })

    const addressValidationSchema = yup.object().shape({
        address: yup.string().required(t('common.inputRequired')),
        city: yup.string().required(t('common.inputRequired')),
        postal_code: yup.string().required(t('common.inputRequired')).max(20,t('common.inputMaxLength', {value: 20})),
        country_code: yup.string().required(t('common.inputRequired'))
    })

    const companyValidationSchema = yup.object().shape({
        name: yup.string().required(t('common.inputRequired')),
        email_address: yup.string().email(t('common.invalidEmail')).required(t('common.inputRequired')),
        type: yup.string().required(t('common.inputRequired')),
        plan: yup.string().required(t('common.inputRequired')),
        siret_number:  yup.string().when( {
            is: (value:string)=> value !== '' && value !== null && value !== undefined,
            then: () => createSiretValidation(), // Utiliser le schéma SIRET
            otherwise: () => yup.string().optional() // Validation si la condition est fausse
        }),
            ///yup.string().required(t('common.inputRequired')),
        business_name: yup.string().optional(),
        phone_number: yup.string().test('is-valid-phone-number', i18n.t('common.invalidPhoneNumber'), (value) => {
                if (value) {
                    return isValidPhoneNumber(value)
                }
                return true
            }
        )
    })

    const validationSchema = yup.object().shape({
        account: accountValidationSchema,
        company: companyValidationSchema,
        address: addressValidationSchema,
    });




    const handleFormSubmit = async (values: any) => {

        try {
            await AuthenticationService.registerCompany(values);
            showToast(t('common.companyRegisteredSuccessfully'), "success");

            fetchCompanies();
            onHide();

        } catch (error:any) {
            error.response.status === 409 ?  showToast(t('common.companyRegisteredErrorDuplicatedEntry'), "error") : showToast(t('common.companyRegisteredFailed'), "error")
        }


    };

    return (
        <Modal
            show={showModal}
            onHide={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {t('common.initializeCompanyAccount')}
                </Modal.Title>
            </Modal.Header>
            <Formik
                initialValues={formInitialValues}
                validationSchema={validationSchema}
                onSubmit={handleFormSubmit}
            >
                {({values, setFieldValue, handleSubmit, errors, touched}) => (
                    <>
                        <Form noValidate onSubmit={handleSubmit}>
                            <Modal.Body className="justify-content-center">
                                <Container>
                                    <Row>
                                        <Col lg={12} md={12} sm={12}>
                                            <fieldset className="form-group  p-3">

                                                <legend className="w-auto"> {t('common.contactPerson')}</legend>
                                                <Row className="mb-3">
                                                    <Col md={6} sm={12}>
                                                        <Form.Group className="mb-3"
                                                                    controlId="companyInitializerFormContactPersonFirstNameInput">
                                                            <Form.Label>{t('common.firstname')}</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                aria-label={t('common.firstname')}
                                                                name="account.firstname"
                                                                placeholder={t('common.pleaseEnterName')}
                                                                value={values.account.firstname || ''}
                                                                onChange={(e) => setFieldValue('account.firstname', e.target.value)}
                                                                isInvalid={touched.account?.firstname && !!errors.account?.firstname}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.account?.firstname}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6} sm={12}>
                                                        <Form.Group className="mb-3"
                                                                    controlId="companyInitializerFormContactPersonLastNameInput">
                                                            <Form.Label>{t('common.lastname')}</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                aria-label={t('common.lastname')}
                                                                name="account.lastname"
                                                                placeholder={t('common.pleaseEnterLastName')}
                                                                value={values.account.lastname || ''}
                                                                onChange={(e) => setFieldValue('account.lastname', e.target.value)}
                                                                isInvalid={touched.account?.lastname && !!errors.account?.lastname}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.account?.lastname}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col md={12} sm={12}>
                                                        <Form.Group className="mb-3"
                                                                    controlId="companyInitializerFormContactPersonEmailInput">
                                                            <Form.Label>{t('common.email')}</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                aria-label={t('common.email')}
                                                                name="account.email_address"
                                                                placeholder={t('common.pleaseEnterEmail')}
                                                                value={values.account.email_address || ''}
                                                                onChange={(e) => setFieldValue('account.email_address', e.target.value)}
                                                                isInvalid={touched.account?.email_address && !!errors.account?.email_address}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.account?.email_address}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </fieldset>
                                            <fieldset className="form-group p-3">

                                                <legend className="w-auto"> {t('common.company')}</legend>
                                                <Row className="mb-3">
                                                    <Col md={6} sm={12}>
                                                        <Form.Group className="mb-3"
                                                                    controlId="companyInitializerFormCompanyNameInput">
                                                            <Form.Label>{t('common.name')}</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                aria-label={t('common.name')}
                                                                name="company.name"
                                                                placeholder={t('common.pleaseEnterName')}
                                                                value={values.company.name || ''}
                                                                onChange={(e) => setFieldValue('company.name', e.target.value)}
                                                                isInvalid={touched.company?.name && !!errors.company?.name}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.company?.name}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6} sm={12}>
                                                        <Form.Group className="mb-3"
                                                                    controlId="companyInitializerFormBusinessNameInput">
                                                            <Form.Label>{t('common.businessName')}</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                aria-label={t('common.businessName')}
                                                                name="company.business_name"
                                                                placeholder={t('common.pleaseEnterBusinessName')}
                                                                value={values.company.business_name || ''}
                                                                onChange={(e) => setFieldValue('company.business_name', e.target.value)}
                                                                isInvalid={touched.company?.business_name && !!errors.company?.business_name}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.company?.business_name}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col md={12} sm={12}>
                                                        <Form.Group className="mb-3"
                                                                    controlId="companyInitializerFormCompanySiretInput">
                                                            <Form.Label>{t('common.siretNumber')}</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                aria-label={t('common.siretNumber')}
                                                                name="company.siret_number"
                                                                placeholder={t('common.pleaseEnterSiretNumber')}
                                                                value={values.company.siret_number || ''}
                                                                onChange={(e) => setFieldValue('company.siret_number', e.target.value)}
                                                                isInvalid={touched.company?.siret_number && !!errors.company?.siret_number}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.company?.siret_number}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col md={6} sm={12}>
                                                        <Form.Group className="mb-3"
                                                                    controlId="companyInitializerFormCompanyEmailInput">
                                                            <Form.Label>{t('common.email')}</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                aria-label={t('common.email')}
                                                                name="company.email_adress"
                                                                placeholder={t('common.pleaseEnterEmail')}
                                                                value={values.company.email_address || ''}
                                                                onChange={(e) => setFieldValue('company.email_address', e.target.value)}
                                                                isInvalid={touched.company?.email_address && !!errors.company?.email_address}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.company?.email_address}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6} sm={12}>
                                                        <Form.Group className="mb-3"
                                                                    controlId="companyInitializerFormPhoneNumberInput">
                                                            <Form.Label>{t('common.phone')}</Form.Label>
                                                            <PhoneInput
                                                                international
                                                                className="form-control"
                                                                aria-label={t('common.callSign')}
                                                                placeholder={t('common.pleaseEnterPhoneNumber')}
                                                                labels={i18n.resolvedLanguage === "fr" ? fr : en}
                                                                defaultCountry="FR"
                                                                name="company.phone_number"
                                                                value={values.company.phone_number || ''}
                                                                countrySelectProps={{flags: undefined}}
                                                                onChange={(value) => setFieldValue('company.phone_number', value || '')}
                                                            />

                                                            {touched?.company?.phone_number && errors?.company?.phone_number && (
                                                                <div className="invalid-feedback d-block">
                                                                    {errors?.company.phone_number as string}
                                                                </div>
                                                            )}
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col md={6} sm={12}>
                                                        <Form.Group className="mb-3"
                                                                    controlId="companyInitializerFormAccountTypeInput">
                                                            <Form.Label>{t('common.accountType')}</Form.Label>
                                                            <Form.Select aria-label={t('common.accountType')}
                                                                         value={values.company.type || ''}
                                                                         name="company.type"
                                                                         onChange={(e) => {
                                                                             setFieldValue('company.type', e.target.value);
                                                                         }}
                                                                         isInvalid={touched?.company?.type && !!errors?.company?.type}>
                                                                <option value=''>{t('common.pleaseSelect')}.</option>
                                                                {accountTypeOptions.map((opt) => (
                                                                    <Fragment
                                                                        key={opt}>{renderAccountTypeOptions(opt)}</Fragment>
                                                                ))}
                                                            </Form.Select>
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors?.company?.type}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6} sm={12}>
                                                        <Form.Group className="mb-3"
                                                                    controlId="companyInitializerFormPlanInput">
                                                            <Form.Label>{t('common.plan')}</Form.Label>
                                                            <Form.Select aria-label={t('common.plan')}
                                                                         value={values.company.plan || ''}
                                                                         name="company.plan"
                                                                         onChange={(e) => {
                                                                             setFieldValue('company.plan', e.target.value);
                                                                         }}
                                                                         isInvalid={touched?.company?.plan && !!errors?.company?.plan}>
                                                                <option value=''>{t('common.pleaseSelect')}.</option>
                                                                {planTypeOptions.map((opt) => (
                                                                    <Fragment
                                                                        key={opt}>{renderPlansTypeOptions(opt)}</Fragment>
                                                                ))}
                                                            </Form.Select>
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors?.company?.plan}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </fieldset>
                                            <fieldset className="form-group p-3">

                                                <legend className="w-auto"> {t('common.address')}</legend>
                                                <Row className="mb-3">
                                                    <Col md={6} sm={12}>
                                                        <Form.Group className="mb-3"
                                                                    controlId="companyInitializerFormCompanyAddressStreetInput">
                                                            <Form.Label>{t('common.address')}</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                aria-label={t('common.address')}
                                                                name="address.address"
                                                                placeholder={t('common.pleaseEnterAddress')}
                                                                value={values.address.address || ''}
                                                                onChange={(e) => setFieldValue('address.address', e.target.value)}
                                                                isInvalid={touched.address?.address && !!errors.address?.address}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.address?.address}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6} sm={12}>
                                                        <Form.Group className="mb-3"
                                                                    controlId="companyInitializerFormAdressCityInput">
                                                            <Form.Label>{t('common.city')}</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                aria-label={t('common.city')}
                                                                name="address.city"
                                                                placeholder={t('common.pleaseEnterCityName')}
                                                                value={values.address.city || ''}
                                                                onChange={(e) => setFieldValue('address.city', e.target.value)}
                                                                isInvalid={touched.address?.city && !!errors.address?.city}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.address?.city}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col md={6} sm={12}>
                                                        <Form.Group className="mb-3"
                                                                    controlId="companyInitializerFormCompanyAdressPostalCodeInput">
                                                            <Form.Label>{t('common.postalCode')}</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                aria-label={t('common.postalCode')}
                                                                name="address.postal_code"
                                                                placeholder={t('common.pleaseEnterPostalCode')}
                                                                value={values.address.postal_code || ''}
                                                                onChange={(e) => setFieldValue('address.postal_code', e.target.value)}
                                                                isInvalid={touched.address?.postal_code && !!errors.address?.postal_code}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.address?.postal_code}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6} sm={12}>
                                                        <Form.Group className="mb-3"
                                                                    controlId="companyInitializerFormCompanyAdressCountryCodeInput">
                                                            <Form.Label>{t('common.country')}</Form.Label>
                                                            <Form.Select
                                                                aria-label={t('common.country')}
                                                                value={values.address.country_code || ''}
                                                                name="address.country_code"
                                                                onChange={(e) => setFieldValue('address.country_code', e.target.value)}
                                                                isInvalid={touched.address?.country_code && !!errors.address?.country_code}>
                                                                <option value=''>{t('common.pleaseSelect')}</option>
                                                                {countries.map((country) => (
                                                                    <option key={country.code} value={country.code}>
                                                                        {getCountryName(country.code)}
                                                                    </option>
                                                                ))}
                                                            </Form.Select>
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.address?.country_code}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>

                                            </fieldset>
                                        </Col>
                                    </Row>
                                </Container>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button className="rounded" variant="primary"
                                        type="submit">{t('common.save')}</Button>
                                <Button onClick={onHide} className="rounded"
                                        variant="danger">{t('common.close')}</Button>
                            </Modal.Footer>


                        </Form>

                    </>
                )}
            </Formik>
        </Modal>
    )
        ;
}


export default CompanyInitializerFormModal;