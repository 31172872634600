import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";

import {PaginationHeader} from "../../@interfaces/PaginationHeader";

import LoadingComponent from "../../components/LoadingComponent/LoadingComponent";


import VenuesService from "../../services/VenuesService";
import VenuesFilters from "../../components/VenuesFilters/VenuesFilters";
import VenueListView from "../../views/VenuesViews/VenueListView/VenueListView";
import {VenueInterface} from "../../@interfaces/VenueInterface";
import {parseResponseHeaders} from "../../utils/ResponseWrapper";
import PaginationWithPageSizeSelector from "../../components/Pagination/PaginationWithSizeSelectorComponent";

import {Button, Dropdown} from "react-bootstrap";
import {FaFileCode, FaFileCsv} from "react-icons/fa";

import downloadJSON from "../../utils/exportDataToJSON";
import downloadCSV from "../../utils/exportDataToCsv";
import downloadXML from "../../utils/exportDataToXml";


interface VenuesPerPage {
    page: number;
    items: VenueInterface[];
}

export function VenuesListPage() {
     const {t} = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [viewMode, setViewMode] = useState<string>('list');
    const [venues, setVenues] = useState<VenuesPerPage[]>([]);
    const [currentPageVenuesData, setCurrentPageVenuesDatas] = useState<VenueInterface[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(50);
    const [totalCount, setTotalCount] = useState(0);

    const handlePageChange = (pageNumber: number) => {
        if (pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
            const pageData = getCurrentPageVenues(pageNumber);
            if (pageData.items.length > 0) {
                setCurrentPageVenues(pageNumber);
            } else {
                fetchVenues({currentPage: pageNumber, pageSize: itemsPerPage});
            }
        }
    };

    const getCurrentPageVenues = (pageNumber: number): VenuesPerPage => {

        return venues.find(element => element.page === pageNumber) || {page: pageNumber, items: []};
    };

    const setCurrentPageVenues = (pageNumber: number) => {
        try {
            const venuesPage = getCurrentPageVenues(pageNumber);

            setCurrentPageVenuesDatas(venuesPage.items)
        } catch (e: any) {
            console.error('error on line ', e);
        }

    };

    const handlePageSizeChange = (event: any) => {
        try {
            setItemsPerPage(Number(event.target.value));
            setVenues([]);
            fetchVenues({currentPage: 1, pageSize: Number(event.target.value)});
        } catch (e: any) {
            console.error('error on line ', e);
        }

    };

    const applyFilters = async (values: any) => {
        try {
            await fetchVenues({currentPage: 1, pageSize: itemsPerPage}, values);
        } catch (e: any) {
            console.error('error on line ', e);
        }
    }


    const fetchVenues = async (pagingData: PaginationHeader = {
        currentPage: 1,
        pageSize: itemsPerPage
    }, filter = {}) => {
        try {

            setIsLoading(true);
            const response = await VenuesService.retrieveVenues({pagination: pagingData, filters: filter});

            const {total_pages, page_size, total_count} = parseResponseHeaders(response.headers);
            setTotalPages(total_pages);
            setItemsPerPage(page_size);
            setTotalCount(total_count);
            setVenues(prevData => [...prevData, {
                page: Number.isNaN(Number(response.headers.page)) ? 0 : Number(response.headers.page),
                items: response.data
            }]);
            setCurrentPageVenuesDatas(response.data)
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching events venues:', error);
            setIsLoading(false);
        }
    };

    const venuesForDownload = (type: string) => {
        const reduced = venues.reduce((acc: VenueInterface[], currentValue) => {
            const items = Array.isArray(currentValue.items) ? currentValue.items : [];
            return [...acc, ...items];
        }, []);
        switch (type) {
            case 'json':
                downloadJSON({data: reduced, fileName: `events_export_${(new Date()).toISOString()}`});
                break;
            case 'csv':
                downloadCSV({data: reduced, fileName: `events_export_${(new Date()).toISOString()}`});
                break;
            case 'xml':
                downloadXML({data: reduced, fileName: `events_export_${(new Date()).toISOString()}`});
                break;
            default:
                console.error('error on line ', type);
                break;
        }
    }
    const handleDownloadJSON = () => {
        venuesForDownload('json');
    };

    const handleDownloadCSV = () => {
        venuesForDownload('csv');
    };
    const handleDownloadXML = () => {
        venuesForDownload('xml');
    };


    return (
        <>
            {isLoading && (
                <LoadingComponent/>
            )}
            {<VenuesFilters setFiltersValue={applyFilters}/>}
            <PaginationWithPageSizeSelector itemsPerPage={itemsPerPage} totalCount={totalCount}
                                            currentPage={currentPage} totalPages={totalPages}
                                            handlePageChange={handlePageChange}
                                            handlePageSizeChange={handlePageSizeChange}/>
            <div className="d-flex justify-content-between my-sm-3 my-3">
                <div>
                    <Button variant={viewMode === 'list' ? "primary" : "light"} size="sm"
                            onClick={() => setViewMode('list')}><i className="bi bi-card-list"></i></Button>
                    <Button variant={viewMode === 'map' ? "primary" : "light"} size="sm" className="mx-2"
                            onClick={() => setViewMode('map')}><i className="bi bi-map"></i></Button>
                </div>
                <Dropdown>
                    <Dropdown.Toggle variant="primary" id="dropdown-basic-export" size={"sm"}>
                        {t('common.export')}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item href="#" onClick={handleDownloadCSV}>
                            <FaFileCsv/> {t('common.exportTo', {value: "Csv"})}</Dropdown.Item>
                        <Dropdown.Item href="#" onClick={handleDownloadJSON}>
                            <FaFileCode/> {t('common.exportTo', {value: "Json"})}</Dropdown.Item>
                        <Dropdown.Item href="#" onClick={handleDownloadXML}>
                            <FaFileCode/> {t('common.exportTo', {value: "Xml"})} (beta)</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <VenueListView venues={currentPageVenuesData} viewMode={viewMode}/>

        </>
    );
}

export default VenuesListPage;