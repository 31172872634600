import {Badge, Button, Dropdown, Tab, Tabs} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import EventsService from "../../services/EventsService";
import {EventInterface} from "../../@interfaces/EventInterface";


import EventSharedWithMe from "../../views/EventsView/EventsSharedWithMe/EventsSharedWithMe";
import MyEvents from "../../views/EventsView/MyEvents/MyEvents";
import EventsFilters from "../../components/Event/EventsFilters/EventsFilters";
import {PaginationHeader} from "../../@interfaces/PaginationHeader";
import LoadingComponent from "../../components/LoadingComponent/LoadingComponent";
import {VenueInterface} from "../../@interfaces/VenueInterface";
import {TagInterface} from "../../@interfaces/TagInterface";
import VenuesService from "../../services/VenuesService";
import {useTranslation} from "react-i18next";
import TagsService from "../../services/TagsService";
import {parseResponseHeaders} from "../../utils/ResponseWrapper";
import PaginationWithPageSizeSelector from "../../components/Pagination/PaginationWithSizeSelectorComponent";

import {FaFileCode, FaFileCsv} from "react-icons/fa";
import downloadJSON from "../../utils/exportDataToJSON";
import downloadCSV from "../../utils/exportDataToCsv";
import downloadXML from "../../utils/exportDataToXml";
import './EventsPage.scss';
import showToast from "../../utils/showToast";

//import EventsSharedByMe from "../../views/EventsView/EventsSharedByMe/EventsSharedByMe";


interface EventsPerPage {
    page: number;
    items: EventInterface[];
}

function EventsPage() {
    const {t} = useTranslation();
    const [viewMode, setViewMode] = useState<string>('list');
    const [isLoading, setIsLoading] = useState(false);
    const [eventsOwnedMappedByPage, setEventsOwnedMappedByPage] = useState<EventsPerPage[]>([]);
    const [eventsNotOwnedMappedByPage, setEventsNotOwnedMappedByPage] = useState<EventsPerPage[]>([]);
    const [eventsSharedByMeMappedByPage, setEventsSharedByMeMappedByPage] = useState<EventsPerPage[]>([]);

    const [venuesList, setVenuesList] = useState<VenueInterface[]>([]);
    const [tagsList, setTagsList] = useState<TagInterface[]>([]);
    const [selectedTab, setSelectedTab] = useState('owned');
    const [curlCommand, setCurlCommand] = useState<string | null>(null)


    const [myEvents, setMyEvents] = useState<EventInterface[]>([]);
    const [sharedWithMeEvents, setSharedWithMeEvents] = useState<EventInterface[]>([]);
    const [sharedByMeEvents, setSharedByMeEvents] = useState<EventInterface[]>([]);


    const [currentPageOwned, setCurrentPageOwned] = useState(1);
    const [totalPagesOwned, setTotalPagesOwned] = useState(0);
    const [totalOwnedCount, setTotalOwnedCount] = useState(0);

    const [currentPageNotOwned, setCurrentPageNotOwned] = useState(1);
    const [totalPagesNotOwned, setTotalPagesNotOwned] = useState(0);
    const [totalNotOwnedCount, setTotalNotOwnedCount] = useState(0);

    const [currentPageSharedByMe, setCurrentPageSharedByMe] = useState(1);
    const [totalPagesSharedByMe, setTotalPagesSharedByMe] = useState(0);
    const [totalSharedByMeCount, setTotalSharedByMeCount] = useState(0);


    const [itemsPerPage, setItemsPerPage] = useState(50);


    const handlePageChangeOwned = (pageNumber: number) => {
        if (pageNumber <= totalPagesOwned) {
            setCurrentPageOwned(pageNumber);
            const pageData = getCurrentPageEventsOwned(pageNumber);
            if (pageData.items.length > 0) {
                setMyEvents(pageData.items);
            } else {
                fetchEvents(true, {currentPage: pageNumber, pageSize: itemsPerPage});
            }
        }
    };
    const handlePageChangeNotOwned = (pageNumber: number) => {
        if (pageNumber <= totalPagesNotOwned) {
            setCurrentPageNotOwned(pageNumber);
            const pageData = getCurrentPageEventsNotOwned(pageNumber);
            if (pageData.items.length > 0) {
                setSharedWithMeEvents(pageData.items);
            } else {
                fetchEvents(false, {currentPage: pageNumber, pageSize: itemsPerPage});
            }
        }
    };

    const handlePageChangeSharedByMe = (pageNumber: number) => {
        if (pageNumber <= totalPagesNotOwned) {
            setCurrentPageSharedByMe(pageNumber);
            const pageData = getCurrentPageEventsSharedByMe(pageNumber);
            if (pageData.items.length > 0) {
                setSharedByMeEvents(pageData.items);
            } else {
                fetchEventSharedByMe({currentPage: pageNumber, pageSize: itemsPerPage});
            }
        }
    };


    const getCurrentPageEventsOwned = (pageNumber: number): EventsPerPage => {
        return eventsOwnedMappedByPage.find(element => element.page === pageNumber) || {page: pageNumber, items: []};
    };

    const getCurrentPageEventsNotOwned = (pageNumber: number): EventsPerPage => {

        return eventsNotOwnedMappedByPage.find(element => element.page === pageNumber) || {page: pageNumber, items: []};
    };

    const getCurrentPageEventsSharedByMe = (pageNumber: number): EventsPerPage => {

        return eventsSharedByMeMappedByPage.find(element => element.page === pageNumber) || {
            page: pageNumber,
            items: []
        };
    };


    const handlePageSizeChange = async (event: any) => {
        try {
            setItemsPerPage(Number(event.target.value));
            setEventsOwnedMappedByPage([])
            setEventsNotOwnedMappedByPage([])
            setEventsSharedByMeMappedByPage([])
            setCurrentPageOwned(1)
            setCurrentPageNotOwned(1)
            setCurrentPageSharedByMe(1)

            await Promise.all([
                fetchEvents(true, {currentPage: 1, pageSize: Number(event.target.value)}),
                fetchEvents(false, {currentPage: 1, pageSize: Number(event.target.value)}),
                //fetchEventSharedByMe()
            ]);

        } catch (e: any) {
            console.error('error on line ', e);
        }

    };

    const applyFilters = async (values: any) => {
        try {
            await Promise.all([
                fetchEvents(true, {currentPage: 1, pageSize: itemsPerPage}, values),
                fetchEvents(false, {currentPage: 1, pageSize: itemsPerPage}, values),
                //fetchEventSharedByMe(values)
            ]);
        } catch (e: any) {
            console.error('error on line ', e);
        }
    }

    const generateCurlCommand = async (values: any) => {
        try {
            const value = await EventsService.generateRetrieveEventCurlCommand(values);
            setCurlCommand(value);
            await navigator.clipboard.writeText(value);
            showToast(t('common.filterCopySuccessfully'), "success")
        } catch (e: any) {
            console.error('error on line ', e);
        }
    }

    const fetchVenues = async () => {
        try {
            setIsLoading(true);
            const response = await VenuesService.retrieveVenues();
            setVenuesList(response.data)
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            console.error('error on line ', e);
        }
    }
    const fetchTags = async () => {
        try {
            setIsLoading(true);
            const response = await TagsService.retrieveTags();
            setTagsList(response.data)
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            console.error('error on line ', e);
        }
    }

    const fetchEvents = async (owned: boolean, pagingData: PaginationHeader = {
        currentPage: 1,
        pageSize: itemsPerPage
    }, filter = {
        localStartDate: null,
        localEndDate: null,
        tags: [],
        owners: [],
        venues: [],
        names: [],
        owned: false
    }) => {
        try {

            setIsLoading(true);
            filter.owned = owned

            const response = await EventsService.retrieveEvents({
                pagination: pagingData,
                filters: filter
            })
            const {total_pages, page_size, total_count} = parseResponseHeaders(response.headers);
            setItemsPerPage(page_size);


            if (owned) {
                setEventsOwnedMappedByPage(prevData => [...prevData, {
                    page: Number.isNaN(Number(response.headers.page)) ? 0 : Number(response.headers.page),
                    items: response.data
                }])
                setMyEvents(response.data);

                setTotalOwnedCount(total_count)
                setTotalPagesOwned(total_pages)
            } else {
                setEventsNotOwnedMappedByPage(prevData => [...prevData, {
                    page: Number.isNaN(Number(response.headers.page)) ? 0 : Number(response.headers.page),
                    items: response.data
                }])
                setSharedWithMeEvents(response.data);
                setTotalNotOwnedCount(total_count)
                setTotalPagesNotOwned(total_pages)

            }
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching events profile:', error);
            setIsLoading(false);
        }
    };

    const fetchEventSharedByMe = async (pagingData: PaginationHeader = {
        currentPage: 1,
        pageSize: itemsPerPage
    }, filter = {
        localStartDate: null,
        localEndDate: null,
        tags: [],
        owners: [],
        venues: [],
        names: [],
    }) => {
        try {

            setIsLoading(true);
            const response = await EventsService.retrieveEventsSharedByMe({pagination: pagingData, filters: filter});
            const {total_pages, page_size, total_count} = parseResponseHeaders(response.headers);
            setItemsPerPage(page_size);

            setEventsSharedByMeMappedByPage(prevData => [...prevData, {
                page: Number.isNaN(Number(response.headers.page)) ? 0 : Number(response.headers.page),
                items: response.data
            }])
            setSharedByMeEvents(response.data);

            setTotalSharedByMeCount(total_count)
            setTotalPagesSharedByMe(total_pages)


            setIsLoading(false);
        } catch (error) {

            console.error('Error fetching events profile:', error);
            setIsLoading(false);
        }
    };


    const eventsForDownload = (type: string) => {
        const reducedEventsOwnedMappedByPage = eventsOwnedMappedByPage.reduce((acc: EventInterface[], currentValue) => {
            const items = Array.isArray(currentValue.items) ? currentValue.items : [];
            return [...acc, ...items];
        }, []);
        const reducedEventsNotOwnedMappedByPage = eventsNotOwnedMappedByPage.reduce((acc: EventInterface[], currentValue) => {
            const items = Array.isArray(currentValue.items) ? currentValue.items : [];
            return [...acc, ...items];
        }, []);

        const reduced = reducedEventsOwnedMappedByPage.concat(reducedEventsNotOwnedMappedByPage)

        switch (type) {
            case 'json':
                downloadJSON({data: reduced, fileName: `events_export_${(new Date()).toISOString()}`});
                break;
            case 'csv':
                downloadCSV({data: reduced, fileName: `events_export_${(new Date()).toISOString()}`});
                break;
            case 'xml':
                downloadXML({data: reduced, fileName: `events_export_${(new Date()).toISOString()}`});
                break;
            default:
                console.error('error on line ', type);
                break;
        }
    }
    const handleDownloadJSON = () => {
        eventsForDownload('json');
    };

    const handleDownloadCSV = () => {
        eventsForDownload('csv');
    };
    const handleDownloadXML = () => {
        eventsForDownload('xml');
    };


    useEffect(() => {

        fetchVenues();

        fetchTags();


    },[]);

    return (
        <>
            {isLoading && (
                <LoadingComponent/>
            )}

            {<EventsFilters setFiltersValue={applyFilters} venues={venuesList} tags={tagsList} curlCommand={curlCommand}
                            generateCurlCommand={generateCurlCommand}/>}

            <PaginationWithPageSizeSelector itemsPerPage={itemsPerPage}
                                            totalCount={selectedTab === 'owned' ? totalOwnedCount
                                                : selectedTab === 'notOwned' ? totalNotOwnedCount
                                                    : totalSharedByMeCount}
                                            currentPage={selectedTab === 'owned' ? currentPageOwned
                                                : selectedTab === 'notOwned' ? currentPageNotOwned
                                                    : currentPageSharedByMe}
                                            totalPages={selectedTab === 'owned' ? totalPagesOwned
                                                : selectedTab === 'notOwned' ? totalPagesNotOwned
                                                    : totalPagesSharedByMe}
                                            handlePageChange={selectedTab === 'owned' ? handlePageChangeOwned
                                                : selectedTab === 'notOwned' ? handlePageChangeNotOwned
                                                    : handlePageChangeSharedByMe}
                                            handlePageSizeChange={handlePageSizeChange}/>
            <div className="d-flex justify-content-between my-sm-3 my-3">
                <div>
                    <Button variant={viewMode === 'list' ? "primary" : "light"} size="sm"
                            onClick={() => setViewMode('list')}><i className="bi bi-card-list"></i></Button>
                    <Button variant={viewMode === 'map' ? "primary" : "light"} size="sm" className="mx-2"
                            onClick={() => setViewMode('map')}><i className="bi bi-map"></i></Button>
                </div>
                <Dropdown>
                    <Dropdown.Toggle variant="primary" id="dropdown-basic-export" size={"sm"}>
                        {t('common.export')}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item href="#" onClick={handleDownloadCSV}>
                            <FaFileCsv/> {t('common.exportTo', {value: "Csv"})}</Dropdown.Item>
                        <Dropdown.Item href="#" onClick={handleDownloadJSON}>
                            <FaFileCode/> {t('common.exportTo', {value: "Json"})}</Dropdown.Item>
                        <Dropdown.Item href="#" onClick={handleDownloadXML}>
                            <FaFileCode/> {t('common.exportTo', {value: "Xml"})} (beta)</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>


            <Tabs defaultActiveKey="ownedby_me" className="mt-3" id="setting-tabs" fill
                  onSelect={(value) => {

                      if (value === "ownedby_me") {
                          setSelectedTab('owned');
                      }
                      if (value === "sharedwith_me") {


                          setSelectedTab('notOwned');
                      }
                      if (value === "sharedby_me") {

                          setSelectedTab('ownedAndSharedByMe')

                      }
                  }
                  }>
                <Tab eventKey="ownedby_me" title={
                    <>
                        {t('common.myEvents')} <Badge bg="primary">{totalOwnedCount}</Badge>
                    </>
                } className="settings-tab-pannel">
                    <MyEvents events={myEvents} viewMode={viewMode}/>
                </Tab>
                <Tab eventKey="sharedwith_me" title={
                    <>
                        {t('common.shareWithMe')} <Badge bg="primary">{totalNotOwnedCount}</Badge>
                    </>
                }
                     className="settings-tab-pannel">
                    <EventSharedWithMe events={sharedWithMeEvents} viewMode={viewMode}/>
                </Tab>

                {/*<Tab eventKey="sharedby_me" title={
                    <>
                        {t('common.shareByMe')} <Badge bg="primary">{totalSharedByMeCount}</Badge>
                    </>
                }
                     className="settings-tab-pannel">
                    <EventsSharedByMe events={sharedByMeEvents} viewMode={viewMode}/>
                </Tab>*/}
            </Tabs>
        </>
    );
}

export default EventsPage;
