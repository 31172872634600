import {useEffect, useState} from "react";

import AuthenticationService from "../../../services/AuthenticationService";
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import LoadingComponent from "../../../components/LoadingComponent/LoadingComponent";
import showToast from "../../../utils/showToast";
import {useTranslation} from "react-i18next";
import {Container} from "react-bootstrap";
// @ts-ignore
import noData from "../../../../src/assets/no-data-pana.svg";
// @ts-ignore

import logo from "../../../assets/logo-ticketr.svg";


const Callback = () => {
    const {t} = useTranslation();
    const [searchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const handleAuth = async () => {
        try {
            // Handle sign-in redirect callback
            setIsLoading(true);
            const response = await AuthenticationService.handleSigninRedirectCallback();

            if (response) {
                await AuthenticationService.tokenIntrospection(response.access_token);
                navigate('/events', {replace: true});
            }
        } catch (error) {
            console.error("Error checking authentication:", error);
            //await AuthenticationService.logout();
            setIsLoading(false);
            showToast(t('common.unableToLogin'), 'error')
            //navigate('/auth/login', {replace: true});

        }
    };

    const handleNavigation = async () => {
        // Perform authentication check and set states
        await handleAuth();
    };

    useEffect(() => {
        handleNavigation();
    }, [searchParams]);

    return (<>

        {isLoading ? (<LoadingComponent/>) : (

            <Container className="d-flex flex-column justify-content-center align-items-center"
                       style={{height: '100vh'}}>
                <div className="text-center">
                    <img src={logo} alt="Logo" className="login-logo mb-3" height={60}/>
                    <h4 className="mt-3 mb-2 text-danger">{t('common.canBeIdentified')}</h4>
                    <p className="mt-2">{t('common.pleaseTryAgainOrContact')}</p>
                    <Link to="/auth/login"
                          className="btn btn-primary mt-2">{t('common.backToLogin')}</Link>
                    <p>
                        <img src={noData} alt="Mail sent " className="login-logo"
                             height={400}/>
                    </p>

                </div>

            </Container>
        )}
    </>);
};

export default Callback;
