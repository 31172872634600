import axiosClient from "./AxiosInstance";


import {PaginationHeader} from "../@interfaces/PaginationHeader";
import {isEmpty} from "../utils/utils";
import {ApiKeyInterface} from "../@interfaces/ApiKeyInterface";

// Define the expected response structure
interface ApiKeysResponse {
    data: ApiKeyInterface[];
    headers: any;
}

const emptyFilter = {
    pagination: {
        currentPage: 1,
        pageSize: 0,
    },
    filters: null
}


export default class ApiKeysService {
    static async retrieveApiKeys({pagination, filters}: {
        pagination: PaginationHeader,
        filters: any
    } = emptyFilter): Promise<ApiKeysResponse> {
        try {
            const formattedFilters = ApiKeysService.formatFiltersToQueryString(filters)
            const response = await axiosClient.get(`/api-keys${!isEmpty(formattedFilters) ? `?${formattedFilters}` : ''}`, {
                headers: {
                    'page': pagination.currentPage,
                    'page-size': pagination.pageSize
                }
            })
            return {
                data: response.data,
                headers: response.headers
            };
        } catch (error) {
            throw error;
        }

    }
    static async generateApiKeys(payload:any){
        try {
            const response = await axiosClient.post('/api-keys', payload);
            return response.data;
        } catch(error){
            throw error;
        }
    }
    static async retrieveApiKey(uuid: number) {
        try {
            const response = await axiosClient.get(`/ApiKeys/${uuid}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    }


    static formatFiltersToQueryString(filters: any) {
        if (!isEmpty(filters)) {
            const filter = {
                names: filters.names?.map((val: any) => val.text),
            }
            // Remove all undefined keys
            /*const filteredFilter = Object.fromEntries(
                Object.entries(filter).filter(([_key, value]) => value !== undefined && value !== null && value !== '' && value.length !== 0)
            );
            return new URLSearchParams(Object.entries(filteredFilter)).toString();*/
         // Remove all undefined keys

            const filteredFilter = Object.fromEntries(
                Object.entries(filter).filter(([key, value]) => {
                    if (Array.isArray(value)) {
                        return value.length !== 0 && value.every((val: any) => val !== undefined && val !== null && val !== '')
                    } else {
                        return value !== '' && value !== undefined && value !== null
                    }
                })
            );

            // Initialize an array to hold query string parts
            const queryParts: string[] = [];

            // Build the query string manually
            for (const [key, value] of Object.entries(filteredFilter)) {
                if (Array.isArray(value)) {
                    // If value is an array, add each item with the same key
                    value.forEach((item) => {
                        queryParts.push(`${encodeURIComponent(key)}=${encodeURIComponent(item)}`);
                    });
                } else {
                    // For single values, just add it directly
                    queryParts.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
                }
            }

            // Join all parts with '&' to form the final query string
            return queryParts.join('&');
        }

        return filters;
    }

}