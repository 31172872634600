
interface Country {
  code: string;
  nameEn: string;
  nameFr: string;
}
const countries: Country[] = [
  { code: 'AF', nameEn: 'Afghanistan', nameFr: 'Afghanistan' },
  { code: 'AL', nameEn: 'Albania', nameFr: 'Albanie' },
  { code: 'DZ', nameEn: 'Algeria', nameFr: 'Algérie' },
  { code: 'AS', nameEn: 'American Samoa', nameFr: 'Samoa américaines' },
  { code: 'AD', nameEn: 'Andorra', nameFr: 'Andorre' },
  { code: 'AO', nameEn: 'Angola', nameFr: 'Angola' },
  { code: 'AI', nameEn: 'Anguilla', nameFr: 'Anguilla' },
  { code: 'AQ', nameEn: 'Antarctica', nameFr: 'Antarctique' },
  { code: 'AG', nameEn: 'Antigua and Barbuda', nameFr: 'Antigua-et-Barbuda' },
  { code: 'AR', nameEn: 'Argentina', nameFr: 'Argentine' },
  { code: 'AM', nameEn: 'Armenia', nameFr: 'Arménie' },
  { code: 'AW', nameEn: 'Aruba', nameFr: 'Aruba' },
  { code: 'AU', nameEn: 'Australia', nameFr: 'Australie' },
  { code: 'AT', nameEn: 'Austria', nameFr: 'Autriche' },
  { code: 'AZ', nameEn: 'Azerbaijan', nameFr: 'Azerbaïdjan' },
  { code: 'BS', nameEn: 'Bahamas', nameFr: 'Bahamas' },
  { code: 'BH', nameEn: 'Bahrain', nameFr: 'Bahreïn' },
  { code: 'BD', nameEn: 'Bangladesh', nameFr: 'Bangladesh' },
  { code: 'BB', nameEn: 'Barbados', nameFr: 'Barbade' },
  { code: 'BY', nameEn: 'Belarus', nameFr: 'Biélorussie' },
  { code: 'BE', nameEn: 'Belgium', nameFr: 'Belgique' },
  { code: 'BZ', nameEn: 'Belize', nameFr: 'Belize' },
  { code: 'BJ', nameEn: 'Benin', nameFr: 'Bénin' },
  { code: 'BM', nameEn: 'Bermuda', nameFr: 'Bermudes' },
  { code: 'BT', nameEn: 'Bhutan', nameFr: 'Bhoutan' },
  { code: 'BO', nameEn: 'Bolivia', nameFr: 'Bolivie' },
  { code: 'BA', nameEn: 'Bosnia and Herzegovina', nameFr: 'Bosnie-Herzégovine' },
  { code: 'BW', nameEn: 'Botswana', nameFr: 'Botswana' },
  { code: 'BV', nameEn: 'Bouvet Island', nameFr: 'Île Bouvet' },
  { code: 'BR', nameEn: 'Brazil', nameFr: 'Brésil' },
  { code: 'IO', nameEn: 'British Indian Ocean Territory', nameFr: 'Territoire britannique de l’océan Indien' },
  { code: 'BN', nameEn: 'Brunei Darussalam', nameFr: 'Brunei' },
  { code: 'BG', nameEn: 'Bulgaria', nameFr: 'Bulgarie' },
  { code: 'BF', nameEn: 'Burkina Faso', nameFr: 'Burkina Faso' },
  { code: 'BI', nameEn: 'Burundi', nameFr: 'Burundi' },
  { code: 'CV', nameEn: 'Cabo Verde', nameFr: 'Cap-Vert' },
  { code: 'KH', nameEn: 'Cambodia', nameFr: 'Cambodge' },
  { code: 'CM', nameEn: 'Cameroon', nameFr: 'Cameroun' },
  { code: 'CA', nameEn: 'Canada', nameFr: 'Canada' },
  { code: 'KY', nameEn: 'Cayman Islands', nameFr: 'Îles Caïmans' },
  { code: 'CF', nameEn: 'Central African Republic', nameFr: 'République centrafricaine' },
  { code: 'TD', nameEn: 'Chad', nameFr: 'Tchad' },
  { code: 'CL', nameEn: 'Chile', nameFr: 'Chili' },
  { code: 'CN', nameEn: 'China', nameFr: 'Chine' },
  { code: 'CX', nameEn: 'Christmas Island', nameFr: 'Île Christmas' },
  { code: 'CC', nameEn: 'Cocos (Keeling) Islands', nameFr: 'Îles Cocos' },
  { code: 'CO', nameEn: 'Colombia', nameFr: 'Colombie' },
  { code: 'KM', nameEn: 'Comoros', nameFr: 'Comores' },
  { code: 'CG', nameEn: 'Congo', nameFr: 'Congo' },
  { code: 'CD', nameEn: 'Congo (Democratic Republic)', nameFr: 'République démocratique du Congo' },
  { code: 'CK', nameEn: 'Cook Islands', nameFr: 'Îles Cook' },
  { code: 'CR', nameEn: 'Costa Rica', nameFr: 'Costa Rica' },
  { code: 'CI', nameEn: 'Côte d\'Ivoire', nameFr: 'Côte d’Ivoire' },
  { code: 'HR', nameEn: 'Croatia', nameFr: 'Croatie' },
  { code: 'CU', nameEn: 'Cuba', nameFr: 'Cuba' },
  { code: 'CW', nameEn: 'Curaçao', nameFr: 'Curaçao' },
  { code: 'CY', nameEn: 'Cyprus', nameFr: 'Chypre' },
  { code: 'CZ', nameEn: 'Czech Republic', nameFr: 'République tchèque' },
  { code: 'DK', nameEn: 'Denmark', nameFr: 'Danemark' },
  { code: 'DJ', nameEn: 'Djibouti', nameFr: 'Djibouti' },
  { code: 'DM', nameEn: 'Dominica', nameFr: 'Dominique' },
  { code: 'DO', nameEn: 'Dominican Republic', nameFr: 'République dominicaine' },
  { code: 'EC', nameEn: 'Ecuador', nameFr: 'Équateur' },
  { code: 'EG', nameEn: 'Egypt', nameFr: 'Égypte' },
  { code: 'SV', nameEn: 'El Salvador', nameFr: 'Salvador' },
  { code: 'GQ', nameEn: 'Equatorial Guinea', nameFr: 'Guinée équatoriale' },
  { code: 'ER', nameEn: 'Eritrea', nameFr: 'Érythrée' },
  { code: 'EE', nameEn: 'Estonia', nameFr: 'Estonie' },
  { code: 'SZ', nameEn: 'Eswatini', nameFr: 'Eswatini' },
  { code: 'ET', nameEn: 'Ethiopia', nameFr: 'Éthiopie' },
  { code: 'FK', nameEn: 'Falkland Islands', nameFr: 'Îles Falkland' },
  { code: 'FO', nameEn: 'Faroe Islands', nameFr: 'Îles Féroé' },
  { code: 'FJ', nameEn: 'Fiji', nameFr: 'Fidji' },
  { code: 'FI', nameEn: 'Finland', nameFr: 'Finlande' },
  { code: 'FR', nameEn: 'France', nameFr: 'France' },
  { code: 'GF', nameEn: 'French Guiana', nameFr: 'Guyane française' },
  { code: 'PF', nameEn: 'French Polynesia', nameFr: 'Polynésie française' },
  { code: 'TF', nameEn: 'French Southern Territories', nameFr: 'Terres australes françaises' },
  { code: 'GA', nameEn: 'Gabon', nameFr: 'Gabon' },
  { code: 'GM', nameEn: 'Gambia', nameFr: 'Gambie' },
  { code: 'GE', nameEn: 'Georgia', nameFr: 'Géorgie' },
  { code: 'DE', nameEn: 'Germany', nameFr: 'Allemagne' },
  { code: 'GH', nameEn: 'Ghana', nameFr: 'Ghana' },
  { code: 'GI', nameEn: 'Gibraltar', nameFr: 'Gibraltar' },
  { code: 'GR', nameEn: 'Greece', nameFr: 'Grèce' },
  { code: 'GL', nameEn: 'Greenland', nameFr: 'Groenland' },
  { code: 'GD', nameEn: 'Grenada', nameFr: 'Grenade' },
  { code: 'GP', nameEn: 'Guadeloupe', nameFr: 'Guadeloupe' },
  { code: 'GU', nameEn: 'Guam', nameFr: 'Guam' },
  { code: 'GT', nameEn: 'Guatemala', nameFr: 'Guatemala' },
  { code: 'GG', nameEn: 'Guernsey', nameFr: 'Guernesey' },
  { code: 'GN', nameEn: 'Guinea', nameFr: 'Guinée' },
  { code: 'GW', nameEn: 'Guinea-Bissau', nameFr: 'Guinée-Bissau' },
  { code: 'GY', nameEn: 'Guyana', nameFr: 'Guyane' },
  { code: 'HT', nameEn: 'Haiti', nameFr: 'Haïti' },
  { code: 'HM', nameEn: 'Heard Island and McDonald Islands', nameFr: 'Îles Heard et McDonald' },
  { code: 'HN', nameEn: 'Honduras', nameFr: 'Honduras' },
  { code: 'HK', nameEn: 'Hong Kong', nameFr: 'Hong Kong' },
  { code: 'HU', nameEn: 'Hungary', nameFr: 'Hongrie' },
  { code: 'IS', nameEn: 'Iceland', nameFr: 'Islande' },
  { code: 'IN', nameEn: 'India', nameFr: 'Inde' },
  { code: 'ID', nameEn: 'Indonesia', nameFr: 'Indonésie' },
  { code: 'IR', nameEn: 'Iran', nameFr: 'Iran' },
  { code: 'IQ', nameEn: 'Iraq', nameFr: 'Irak' },
  { code: 'IE', nameEn: 'Ireland', nameFr: 'Irlande' },
  { code: 'IM', nameEn: 'Isle of Man', nameFr: 'Île de Man' },
  { code: 'IL', nameEn: 'Israel', nameFr: 'Israël' },
  { code: 'IT', nameEn: 'Italy', nameFr: 'Italie' },
  { code: 'JE', nameEn: 'Jersey', nameFr: 'Jersey' },
  { code: 'JO', nameEn: 'Jordan', nameFr: 'Jordanie' },
  { code: 'KZ', nameEn: 'Kazakhstan', nameFr: 'Kazakhstan' },
  { code: 'KE', nameEn: 'Kenya', nameFr: 'Kenya' },
  { code: 'KI', nameEn: 'Kiribati', nameFr: 'Kiribati' },
  { code: 'KP', nameEn: 'Korea (North)', nameFr: 'Corée du Nord' },
  { code: 'KR', nameEn: 'Korea (South)', nameFr: 'Corée du Sud' },
  { code: 'KW', nameEn: 'Kuwait', nameFr: 'Koweït' },
  { code: 'KG', nameEn: 'Kyrgyzstan', nameFr: 'Kirghizistan' },
  { code: 'LA', nameEn: 'Laos', nameFr: 'Laos' },
  { code: 'LV', nameEn: 'Latvia', nameFr: 'Lettonie' },
  { code: 'LB', nameEn: 'Lebanon', nameFr: 'Liban' },
  { code: 'LS', nameEn: 'Lesotho', nameFr: 'Lesotho' },
  { code: 'LR', nameEn: 'Liberia', nameFr: 'Libéria' },
  { code: 'LY', nameEn: 'Libya', nameFr: 'Libye' },
  { code: 'LI', nameEn: 'Liechtenstein', nameFr: 'Liechtenstein' },
  { code: 'LT', nameEn: 'Lithuania', nameFr: 'Lituanie' },
  { code: 'LU', nameEn: 'Luxembourg', nameFr: 'Luxembourg' },
  { code: 'MO', nameEn: 'Macao', nameFr: 'Macao' },
  { code: 'MK', nameEn: 'North Macedonia', nameFr: 'Macédoine du Nord' },
  { code: 'MG', nameEn: 'Madagascar', nameFr: 'Madagascar' },
  { code: 'MW', nameEn: 'Malawi', nameFr: 'Malawi' },
  { code: 'MY', nameEn: 'Malaysia', nameFr: 'Malaisie' },
  { code: 'MV', nameEn: 'Maldives', nameFr: 'Maldives' },
  { code: 'ML', nameEn: 'Mali', nameFr: 'Mali' },
  { code: 'MT', nameEn: 'Malta', nameFr: 'Malte' },
  { code: 'MH', nameEn: 'Marshall Islands', nameFr: 'Îles Marshall' },
  { code: 'MQ', nameEn: 'Martinique', nameFr: 'Martinique' },
  { code: 'MR', nameEn: 'Mauritania', nameFr: 'Mauritanie' },
  { code: 'MU', nameEn: 'Mauritius', nameFr: 'Île Maurice' },
  { code: 'YT', nameEn: 'Mayotte', nameFr: 'Mayotte' },
  { code: 'MX', nameEn: 'Mexico', nameFr: 'Mexique' },
  { code: 'FM', nameEn: 'Micronesia', nameFr: 'Micronésie' },
  { code: 'MD', nameEn: 'Moldova', nameFr: 'Moldavie' },
  { code: 'MC', nameEn: 'Monaco', nameFr: 'Monaco' },
  { code: 'MN', nameEn: 'Mongolia', nameFr: 'Mongolie' },
  { code: 'ME', nameEn: 'Montenegro', nameFr: 'Monténégro' },
  { code: 'MS', nameEn: 'Montserrat', nameFr: 'Montserrat' },
  { code: 'MA', nameEn: 'Morocco', nameFr: 'Maroc' },
  { code: 'MZ', nameEn: 'Mozambique', nameFr: 'Mozambique' },
  { code: 'MM', nameEn: 'Myanmar', nameFr: 'Birmanie' },
  { code: 'NA', nameEn: 'Namibia', nameFr: 'Namibie' },
  { code: 'NR', nameEn: 'Nauru', nameFr: 'Nauru' },
  { code: 'NP', nameEn: 'Nepal', nameFr: 'Népal' },
  { code: 'NL', nameEn: 'Netherlands', nameFr: 'Pays-Bas' },
  { code: 'NC', nameEn: 'New Caledonia', nameFr: 'Nouvelle-Calédonie' },
  { code: 'NZ', nameEn: 'New Zealand', nameFr: 'Nouvelle-Zélande' },
  { code: 'NI', nameEn: 'Nicaragua', nameFr: 'Nicaragua' },
  { code: 'NE', nameEn: 'Niger', nameFr: 'Niger' },
  { code: 'NG', nameEn: 'Nigeria', nameFr: 'Nigeria' },
  { code: 'NU', nameEn: 'Niue', nameFr: 'Niué' },
  { code: 'NF', nameEn: 'Norfolk Island', nameFr: 'Île Norfolk' },
  { code: 'MP', nameEn: 'Northern Mariana Islands', nameFr: 'Îles Mariannes du Nord' },
  { code: 'NO', nameEn: 'Norway', nameFr: 'Norvège' },
  { code: 'OM', nameEn: 'Oman', nameFr: 'Oman' },
  { code: 'PK', nameEn: 'Pakistan', nameFr: 'Pakistan' },
  { code: 'PW', nameEn: 'Palau', nameFr: 'Palaos' },
  { code: 'PS', nameEn: 'Palestine', nameFr: 'Palestine' },
  { code: 'PA', nameEn: 'Panama', nameFr: 'Panama' },
  { code: 'PG', nameEn: 'Papua New Guinea', nameFr: 'Papouasie-Nouvelle-Guinée' },
  { code: 'PY', nameEn: 'Paraguay', nameFr: 'Paraguay' },
  { code: 'PE', nameEn: 'Peru', nameFr: 'Pérou' },
  { code: 'PH', nameEn: 'Philippines', nameFr: 'Philippines' },
  { code: 'PN', nameEn: 'Pitcairn', nameFr: 'Pitcairn' },
  { code: 'PL', nameEn: 'Poland', nameFr: 'Pologne' },
  { code: 'PT', nameEn: 'Portugal', nameFr: 'Portugal' },
  { code: 'PR', nameEn: 'Puerto Rico', nameFr: 'Porto Rico' },
  { code: 'QA', nameEn: 'Qatar', nameFr: 'Qatar' },
  { code: 'RE', nameEn: 'Réunion', nameFr: 'Réunion' },
  { code: 'RO', nameEn: 'Romania', nameFr: 'Roumanie' },
  { code: 'RU', nameEn: 'Russia', nameFr: 'Russie' },
  { code: 'RW', nameEn: 'Rwanda', nameFr: 'Rwanda' },
  { code: 'BL', nameEn: 'Saint Barthélemy', nameFr: 'Saint-Barthélemy' },
  { code: 'SH', nameEn: 'Saint Helena', nameFr: 'Sainte-Hélène' },
  { code: 'KN', nameEn: 'Saint Kitts and Nevis', nameFr: 'Saint-Kitts-et-Nevis' },
  { code: 'LC', nameEn: 'Saint Lucia', nameFr: 'Sainte-Lucie' },
  { code: 'MF', nameEn: 'Saint Martin', nameFr: 'Saint-Martin' },
  { code: 'PM', nameEn: 'Saint Pierre and Miquelon', nameFr: 'Saint-Pierre-et-Miquelon' },
  { code: 'VC', nameEn: 'Saint Vincent and the Grenadines', nameFr: 'Saint-Vincent-et-les-Grenadines' },
  { code: 'WS', nameEn: 'Samoa', nameFr: 'Samoa' },
  { code: 'SM', nameEn: 'San Marino', nameFr: 'Saint-Marin' },
  { code: 'ST', nameEn: 'Sao Tome and Principe', nameFr: 'Sao Tomé-et-Principe' },
  { code: 'SA', nameEn: 'Saudi Arabia', nameFr: 'Arabie Saoudite' },
  { code: 'SN', nameEn: 'Senegal', nameFr: 'Sénégal' },
  { code: 'RS', nameEn: 'Serbia', nameFr: 'Serbie' },
  { code: 'SC', nameEn: 'Seychelles', nameFr: 'Seychelles' },
  { code: 'SL', nameEn: 'Sierra Leone', nameFr: 'Sierra Leone' },
  { code: 'SG', nameEn: 'Singapore', nameFr: 'Singapour' },
  { code: 'SX', nameEn: 'Sint Maarten', nameFr: 'Sint Maarten' },
  { code: 'SK', nameEn: 'Slovakia', nameFr: 'Slovaquie' },
  { code: 'SI', nameEn: 'Slovenia', nameFr: 'Slovénie' },
  { code: 'SB', nameEn: 'Solomon Islands', nameFr: 'Îles Salomon' },
  { code: 'SO', nameEn: 'Somalia', nameFr: 'Somalie' },
  { code: 'ZA', nameEn: 'South Africa', nameFr: 'Afrique du Sud' },
  { code: 'GS', nameEn: 'South Georgia and the South Sandwich Islands', nameFr: 'Géorgie du Sud et îles Sandwich du Sud' },
  { code: 'SS', nameEn: 'South Sudan', nameFr: 'Soudan du Sud' },
  { code: 'ES', nameEn: 'Spain', nameFr: 'Espagne' },
  { code: 'LK', nameEn: 'Sri Lanka', nameFr: 'Sri Lanka' },
  { code: 'SD', nameEn: 'Sudan', nameFr: 'Soudan' },
  { code: 'SR', nameEn: 'Suriname', nameFr: 'Suriname' },
  { code: 'SJ', nameEn: 'Svalbard and Jan Mayen', nameFr: 'Svalbard et Jan Mayen' },
  { code: 'SE', nameEn: 'Sweden', nameFr: 'Suède' },
  { code: 'CH', nameEn: 'Switzerland', nameFr: 'Suisse' },
  { code: 'SY', nameEn: 'Syria', nameFr: 'Syrie' },
  { code: 'TW', nameEn: 'Taiwan', nameFr: 'Taïwan' },
  { code: 'TJ', nameEn: 'Tajikistan', nameFr: 'Tadjikistan' },
  { code: 'TZ', nameEn: 'Tanzania', nameFr: 'Tanzanie' },
  { code: 'TH', nameEn: 'Thailand', nameFr: 'Thaïlande' },
  { code: 'TL', nameEn: 'Timor-Leste', nameFr: 'Timor-Leste' },
  { code: 'TG', nameEn: 'Togo', nameFr: 'Togo' },
  { code: 'TK', nameEn: 'Tokelau', nameFr: 'Tokelau' },
  { code: 'TO', nameEn: 'Tonga', nameFr: 'Tonga' },
  { code: 'TT', nameEn: 'Trinidad and Tobago', nameFr: 'Trinité-et-Tobago' },
  { code: 'TN', nameEn: 'Tunisia', nameFr: 'Tunisie' },
  { code: 'TR', nameEn: 'Turkey', nameFr: 'Turquie' },
  { code: 'TM', nameEn: 'Turkmenistan', nameFr: 'Turkménistan' },
  { code: 'TC', nameEn: 'Turks and Caicos Islands', nameFr: 'Îles Turques-et-Caïques' },
  { code: 'TV', nameEn: 'Tuvalu', nameFr: 'Tuvalu' },
  { code: 'UG', nameEn: 'Uganda', nameFr: 'Ouganda' },
  { code: 'UA', nameEn: 'Ukraine', nameFr: 'Ukraine' },
  { code: 'AE', nameEn: 'United Arab Emirates', nameFr: 'Émirats Arabes Unis' },
  { code: 'GB', nameEn: 'United Kingdom', nameFr: 'Royaume-Uni' },
  { code: 'US', nameEn: 'United States', nameFr: 'États-Unis' },
  { code: 'UY', nameEn: 'Uruguay', nameFr: 'Uruguay' },
  { code: 'UZ', nameEn: 'Uzbekistan', nameFr: 'Ouzbékistan' },
  { code: 'VU', nameEn: 'Vanuatu', nameFr: 'Vanuatu' },
  { code: 'VA', nameEn: 'Vatican City', nameFr: 'Cité du Vatican' },
  { code: 'VE', nameEn: 'Venezuela', nameFr: 'Venezuela' },
  { code: 'VN', nameEn: 'Vietnam', nameFr: 'Vietnam' },
  { code: 'WF', nameEn: 'Wallis and Futuna', nameFr: 'Wallis-et-Futuna' },
  { code: 'EH', nameEn: 'Western Sahara', nameFr: 'Sahara Occidental' },
  { code: 'YE', nameEn: 'Yemen', nameFr: 'Yémen' },
  { code: 'ZM', nameEn: 'Zambia', nameFr: 'Zambie' },
  { code: 'ZW', nameEn: 'Zimbabwe', nameFr: 'Zimbabwe' }
];

export default countries;
