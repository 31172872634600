import {Button, Modal, Form, Container, Row, Col} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import * as yup from "yup";
import {Formik} from "formik";
import React, {useEffect, useState} from "react";
import showToast from "../../utils/showToast";
import AccountsService from "../../services/AccountsService";

import CompaniesService from "../../services/CompaniesService";
import {CompanyInterface} from "../../@interfaces/CompanyInterface";
import AsyncSelect from "react-select/async";
import AuthenticationService from "../../services/AuthenticationService";

interface CompaniesResponse {
    data: CompanyInterface[];
    headers: any;
}

interface AddAccountFormModalProps {
    showModal: boolean;
    initialValues?:
        {
            email_address: null,
            firstname: null,
            lastname: null,
            description: null,
            company_id: null,
        }


    onHide(): void;

    fetchAccounts(): void;
}


function AddAccountFormModal({showModal, onHide, fetchAccounts, initialValues}: AddAccountFormModalProps) {
    const {t} = useTranslation();
    const [selectedCompany, setSelectedCompany] = useState<any>(null);
    const [isTicketrAdminAccount, setIsTicketrAdminAccount] = useState<boolean>(false);
    const loadOptions = (inputValue: string) =>
        new Promise<{ value: string, label: string }[]>((resolve) => {
            setTimeout(() => {
                CompaniesService.retrieveCompanies({
                    pagination: {
                        currentPage: 1,
                        pageSize: 0,
                    },
                    filters: {names: [{text: inputValue}]}
                }).then((response: CompaniesResponse) => {

                    const formattedOptions = response.data.map((company: any) => ({
                        value: company.id as string,
                        label: company.name as string
                    }));
                    resolve(formattedOptions);
                }, error => {
                    console.error(error);
                });
            }, 1000);
        });
    const formInitialValues =
        {
            email_address: null,
            firstname: null,
            lastname: null,
            description: '',
            company_id: ''
        }


    const accountValidationSchema = yup.object().shape({
        firstname: yup.string().required(t('common.inputRequired')),
        lastname: yup.string().required(t('common.inputRequired')),
        email_address: yup.string().email(t('common.invalidEmail')).required(t('common.inputRequired')),
        description: yup.string().optional(),
        company_id: yup.string().optional(),
    })


    const handleFormSubmit = async (values: any) => {

        try {
            selectedCompany === null || selectedCompany === '' ? delete values.company_id : values.company_id = selectedCompany.value;
            if (values.description == null || values.description === '') {
                delete values.description
            }

            await AccountsService.registerAccount(values);
            showToast(t('common.accountRegisteredSuccessfully'), "success");

            fetchAccounts();
            onHide();

        } catch (error: any) {
            error.response.status === 409 ? showToast(t('common.accountRegisteredErrorDuplicatedEntry'), "error") : showToast(t('common.accountRegisteredFailed'), "error")
        }


    };

    useEffect(() => {
        const checkAdminUser = async () => {
            const isAdmin = await AuthenticationService.isTicketrAdminUser();
            setIsTicketrAdminAccount(isAdmin);
        };

        checkAdminUser();
    }, []);

    return (
        <Modal
            show={showModal}
            onHide={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {t('common.initializeAccount')}
                </Modal.Title>
            </Modal.Header>
            <Formik
                initialValues={formInitialValues}
                validationSchema={accountValidationSchema}
                onSubmit={handleFormSubmit}
            >
                {({values, setFieldValue, handleSubmit, errors, touched}) => (
                    <>
                        <Form noValidate onSubmit={handleSubmit}>
                            <Modal.Body className="justify-content-center">
                                <Container>
                                    <Row>
                                        <Col lg={12} md={12} sm={12}>
                                            <fieldset className="form-group  p-3">

                                                <legend className="w-auto"></legend>
                                                <Row className="mb-3">
                                                    <Col md={12} sm={12}>
                                                        <Form.Group className="mb-3"
                                                                    controlId="companyInitializerFormContactPersonFirstNameInput">
                                                            <Form.Label>{t('common.firstname')}</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                aria-label={t('common.firstname')}
                                                                name="firstname"
                                                                placeholder={t('common.pleaseEnterName')}
                                                                value={values.firstname || ''}
                                                                onChange={(e) => setFieldValue('firstname', e.target.value)}
                                                                isInvalid={touched.firstname && !!errors.firstname}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.firstname}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={12} sm={12}>
                                                        <Form.Group className="mb-3"
                                                                    controlId="companyInitializerFormContactPersonLastNameInput">
                                                            <Form.Label>{t('common.lastname')}</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                aria-label={t('common.lastname')}
                                                                name="lastname"
                                                                placeholder={t('common.pleaseEnterLastName')}
                                                                value={values.lastname || ''}
                                                                onChange={(e) => setFieldValue('lastname', e.target.value)}
                                                                isInvalid={touched.lastname && !!errors.lastname}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.lastname}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col md={12} sm={12}>
                                                        <Form.Group className="mb-3"
                                                                    controlId="companyInitializerFormContactPersonEmailInput">
                                                            <Form.Label>{t('common.email')}</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                aria-label={t('common.email')}
                                                                name="account.email_address"
                                                                placeholder={t('common.pleaseEnterEmail')}
                                                                value={values.email_address || ''}
                                                                onChange={(e) => setFieldValue('email_address', e.target.value)}
                                                                isInvalid={touched.email_address && !!errors.email_address}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.email_address}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                {isTicketrAdminAccount &&
                                                    <Row className="mb-3">
                                                        <Col md={12} sm={12}>
                                                            <Form.Group className="mb-3"
                                                                        controlId="accountAssociateCompanyId">
                                                                <Form.Label>{t('common.company')}</Form.Label>
                                                                <AsyncSelect

                                                                    cacheOptions
                                                                    defaultOptions
                                                                    onChange={setSelectedCompany}
                                                                    loadOptions={loadOptions}
                                                                    className="mb-3 mt-3"
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                }
                                            </fieldset>
                                        </Col>
                                    </Row>
                                </Container>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button className="rounded" variant="primary"
                                        type="submit">{t('common.save')}</Button>
                                <Button onClick={onHide} className="rounded"
                                        variant="danger">{t('common.close')}</Button>
                            </Modal.Footer>


                        </Form>

                    </>
                )}
            </Formik>
        </Modal>
    );


}


export default AddAccountFormModal;