import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";

import {PaginationHeader} from "../../@interfaces/PaginationHeader";

import LoadingComponent from "../../components/LoadingComponent/LoadingComponent";


import PaginationWithPageSizeSelector from "../../components/Pagination/PaginationWithSizeSelectorComponent";
import {parseResponseHeaders} from "../../utils/ResponseWrapper";
import AuthenticationService from "../../services/AuthenticationService";

import RegistrationRequestsListView from "../../views/RegistrationRequestsListView/RegistrationRequestsListView";
import {RegistrationRequestsInterface} from "../../@interfaces/RegistrationRequestsInterface";
import RegistrationRequestsFilters from "../../components/RegistrationRequestsFilters/RegistrationRequestsFilters";


interface RegistrationRequestsPerPage {
    page: number;
    items: RegistrationRequestsInterface[];
}

export function RegistrationRequestsListPage() {

    const [isLoading, setIsLoading] = useState(false);
    const [registrationRequests, setRegistrationRequests] = useState<RegistrationRequestsPerPage[]>([]);
    const [currentPageRegistrationRequestsData, setCurrentPageRegistrationRequestsDatas] = useState<RegistrationRequestsInterface[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(50);
    const [totalCount, setTotalCount] = useState(0);

    const handlePageChange = (pageNumber: number) => {
        if (pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
            const pageData = getCurrentPageRegistrationRequests(pageNumber);
            if (pageData.items.length > 0) {
                setCurrentPageRegistrationRequests(pageNumber);
            } else {
                fetchRegistrationRequests({currentPage: pageNumber, pageSize: itemsPerPage});
            }
        }
    };

    const getCurrentPageRegistrationRequests = (pageNumber: number): RegistrationRequestsPerPage => {

        return registrationRequests.find(element => element.page === pageNumber) || {page: pageNumber, items: []};
    };

    const setCurrentPageRegistrationRequests = (pageNumber: number) => {
        try {
            const registrationRequestsPage = getCurrentPageRegistrationRequests(pageNumber);
            setCurrentPageRegistrationRequestsDatas(registrationRequestsPage.items)
        } catch (e: any) {
            console.error('error on line ', e);
        }

    };

    const handlePageSizeChange = (event: any) => {
        try {
            setItemsPerPage(Number(event.target.value));
            setRegistrationRequests([]);
            fetchRegistrationRequests({currentPage: 1, pageSize: Number(event.target.value)});
        } catch (e: any) {
            console.error('error on line ', e);
        }

    };

    const applyFilters = (values: any) => {
        try {
            fetchRegistrationRequests({currentPage: 1, pageSize: itemsPerPage}, values);
        } catch (e: any) {
            console.error('error on line ', e);
        }
    }


    const fetchRegistrationRequests = async (pagingData: PaginationHeader = {
        currentPage: 1,
        pageSize: itemsPerPage
    }, filter = {}) => {
        try {

            setIsLoading(true);

            const response = await AuthenticationService.retrieveRegistrationRequests({pagination: pagingData, filters: filter});
            const {total_pages, page_size, total_count} = parseResponseHeaders(response.headers);
            setTotalPages(total_pages);
            setItemsPerPage(page_size);
            setTotalCount(total_count);
            setRegistrationRequests(prevData => [...prevData, {
                page: Number.isNaN(Number(response.headers.page)) ? 0 : Number(response.headers.page),
                items: response.data
            }]);
            setCurrentPageRegistrationRequestsDatas(response.data)
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    };

    /*useEffect(() => {

        fetchRegistrationRequests();
    }, []);*/

    return (
        <>
            {isLoading && (
                <LoadingComponent/>
            )}
            <RegistrationRequestsFilters setFiltersValue={applyFilters}/>
            <PaginationWithPageSizeSelector itemsPerPage={itemsPerPage} totalCount={totalCount}
                                            currentPage={currentPage} totalPages={totalPages}
                                            handlePageChange={handlePageChange}
                                            handlePageSizeChange={handlePageSizeChange}/>

            <RegistrationRequestsListView registrationRequests={currentPageRegistrationRequestsData}/>


        </>
    );
}

export default RegistrationRequestsListPage;