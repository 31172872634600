import React from "react";

import './LoadingComponent.scss'
import {useTranslation} from "react-i18next";

function LoadingComponent() {
    const {t, i18n} = useTranslation();
    return (
        <div className="loader-backdrop">
            <div className="loader-container">
                <svg className="loader" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                    <circle className="loader__circle" cx="50" cy="10" r="6"/>
                    <circle className="loader__circle" cx="90" cy="50" r="6"/>
                    <circle className="loader__circle" cx="50" cy="90" r="6"/>
                    <circle className="loader__circle" cx="10" cy="50" r="6"/>

                    <line className="loader__line" x1="50" y1="10" x2="90" y2="50"/>
                    <line className="loader__line" x1="90" y1="50" x2="50" y2="90"/>
                    <line className="loader__line" x1="50" y1="90" x2="10" y2="50"/>
                    <line className="loader__line" x1="10" y1="50" x2="50" y2="10"/>
                </svg>
                <div className="loader-text">{t('common.loading')}</div>
            </div>
        </div>


    )
}

export default LoadingComponent;