import {Button, Row, Col, Card} from "react-bootstrap";
import {BiCart} from 'react-icons/bi';
import "./ApiKeysListPage.scss"

import LoadingComponent from "../../components/LoadingComponent/LoadingComponent";

import PaginationWithPageSizeSelector from "../../components/Pagination/PaginationWithSizeSelectorComponent";

import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {PaginationHeader} from "../../@interfaces/PaginationHeader";
import ApiKeysService from "../../services/ApiKeysService";
import {parseResponseHeaders} from "../../utils/ResponseWrapper";
import ApiKeysListView from "../../views/ApiKeysListView/ApiKeysListView";
import {ApiKeyInterface} from "../../@interfaces/ApiKeyInterface";

import GenerateApiKeyModal from "../../modals/GenerateApiKeyModal/GenerateApiKeyModal";

interface ApiKeysPerPage {
    page: number;
    items: ApiKeyInterface[];
}

function ApiKeysListPage() {
    const {t} = useTranslation();

    const [isLoading, setIsLoading] = useState(false);


    const [ApiKeys, setApiKeys] = useState<ApiKeysPerPage[]>([]);
    const [currentPageApiKeysData, setCurrentPageApiKeysDatas] = useState<ApiKeyInterface[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(50);
    const [totalCount, setTotalCount] = useState(0);
    const [showGenerateApiKeyModal, setShowGenerateApiKeyModal] = useState(false);

    useEffect(() => {
       fetchApiKeys()
    }, []);
    const handlePageChange = (pageNumber: number) => {
        if (pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
            const pageData = getCurrentPageApiKeys(pageNumber);
            if (pageData.items.length > 0) {
                setCurrentPageApiKeys(pageNumber);
            } else {
                fetchApiKeys({currentPage: pageNumber, pageSize: itemsPerPage});
            }
        }
    };

    const getCurrentPageApiKeys = (pageNumber: number): ApiKeysPerPage => {

        return ApiKeys.find(element => element.page === pageNumber) || {page: pageNumber, items: []};
    };

    const setCurrentPageApiKeys = (pageNumber: number) => {
        try {
            const ApiKeysPage = getCurrentPageApiKeys(pageNumber);
            setCurrentPageApiKeysDatas(ApiKeysPage.items)
        } catch (e: any) {
            console.error('error on line ', e);
        }

    };

    const handlePageSizeChange = (event: any) => {
        try {
            setItemsPerPage(Number(event.target.value));
            setApiKeys([]);
            fetchApiKeys({currentPage: 1, pageSize: Number(event.target.value)});
        } catch (e: any) {
            console.error('error on line ', e);
        }

    };


    const fetchApiKeys = async (pagingData: PaginationHeader = {
        currentPage: 1,
        pageSize: itemsPerPage
    }, filter = {}) => {
        try {

            setIsLoading(true);

            const response = await ApiKeysService.retrieveApiKeys({pagination: pagingData, filters: filter});
            const {total_pages, page_size, total_count} = parseResponseHeaders(response.headers);
            setTotalPages(total_pages);
            setItemsPerPage(page_size);
            setTotalCount(total_count);
            setApiKeys(prevData => [...prevData, {
                page: Number.isNaN(Number(response.headers.page)) ? 0 : Number(response.headers.page),
                items: response.data
            }]);
            setCurrentPageApiKeysDatas(response.data)
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching events profile:', error);
            setIsLoading(false);
        }
    };

    return (
        <>
            {isLoading && (
                <LoadingComponent/>
            )}
            <Row className="justify-content-center">
                {/* Active API Keys Card */}
                <Col xxl={4} md={6}>
                    <Card className="info-card active-keys-card">
                        <Card.Body className="text-center">
                            <Card.Title>
                                Active API Keys
                            </Card.Title>
                            <div className="text-center">
                                <div className="ps-3 ">
                                    <h6>45</h6> {/* Number of active keys */}
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>

                {/* Total API Keys Card */}
                <Col xxl={4} md={6}>
                    <Card className="info-card total-keys-card">
                        <Card.Body className="text-center">
                            <Card.Title>
                                Total API Keys
                            </Card.Title>
                            <div className="text-center">
                                <div className="ps-3 text-center">
                                    <h6>100</h6> {/* Total number of keys */}
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>


            <PaginationWithPageSizeSelector itemsPerPage={itemsPerPage} totalCount={totalCount}
                                            currentPage={currentPage} totalPages={totalPages}
                                            handlePageChange={handlePageChange}
                                            handlePageSizeChange={handlePageSizeChange}/>
            <div className="d-flex justify-content-end my-sm-3 my-3">


                <Button variant="primary" size={"sm"} onClick={() => setShowGenerateApiKeyModal(true)}> <i
                    className="bi bi-plus"></i>{t('common.add')}</Button>

            </div>


            <ApiKeysListView apiKeys={currentPageApiKeysData}/>
            <GenerateApiKeyModal show={showGenerateApiKeyModal} handleClose={() => {setShowGenerateApiKeyModal(false);fetchApiKeys()}}
                                 />


        </>
    );
}

export default ApiKeysListPage;