import {Button, Col, Container, Form, Modal, Row, Table} from "react-bootstrap";
import {useTranslation} from "react-i18next";

function ITRCredentialsModal(props: any) {
     const {t, i18n} = useTranslation();
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {t('ITRCredentialsModal.title')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="justify-content-center">
                <Container>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} className="justify-content-center text-center">
                            <p className="text-center">{t('ITRCredentialsModal.description')}
                            </p>
                            <Form>
                                <Form.Group className="mb-3" controlId="credentialsIdInput">
                                    <Form.Control type="email" placeholder={t('ITRCredentialsModal.username')} id="credentialsIdInputEmail"/>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="credentialsPasswordInput">
                                    <Form.Control type="password" placeholder={t('common.password')} id="credentialsPasswordInputPassword"/>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="credentialsSubmitButton">
                                    <Button variant="primary" size={"lg"}>{t('ITRCredentialsModal.activate')}</Button>
                                </Form.Group>
                                {/*<Form.Group className="mb-3" controlId="credentialsSubmitButton">
                                    <p className="text-start">
                                        {t('common.noAccount')} <a href="javascript:void(0)"> {t('common.createAccount')}</a>
                                        <br/>
                                        <a href="javascript:void(0)"> {t('common.forgotPassword')}</a>
                                    </p>
                                </Form.Group>*/}
                            </Form>

                        </Col>
                    </Row>
                </Container>


            </Modal.Body>
            <Modal.Footer>

                <Button onClick={props.onHide} className="rounded">{t('common.close')}</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ITRCredentialsModal;