import React, {useState} from 'react';
import {Modal, Button, Form, Table, Container, Row, Col} from 'react-bootstrap';
import {useTranslation} from "react-i18next";


interface ApiKeyConfigModalProps {
    showModal: boolean;

    onHide(): void;
}



function ApiKeyConfigModal({showModal, onHide}: ApiKeyConfigModalProps) {
    const {t} = useTranslation();

    const [venuesPermissions, setVenuesPermissions] = useState<any>({
        create: false,
        read: false,
        update: false,
        delete: false,
    });

    const [eventsPermissions, setEventsPermissions] = useState<any>({
        create: false,
        read: false,
        update: false,
        delete: false,
    });


    const handleVenuePermissionChange = (e: any) => {
        const {name, checked} = e.target;
        setVenuesPermissions((prev: any) => ({...prev, [name]: checked}));
    };

    const handleEventPermissionChange = (e: any) => {
        const {name, checked} = e.target;
        setEventsPermissions((prev: any) => ({...prev, [name]: checked}));
    };

    const handleSave = () => {
        console.log('Venues Permissions:', venuesPermissions);
        console.log('Events Permissions:', eventsPermissions);
        onHide(); // Fermer la modale après sauvegarde
    };

    return (
        <Modal show={showModal} onHide={onHide} size={"lg"} centered>
            <Modal.Header closeButton>
                <Modal.Title>{t('common.configApiKey')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col xs={12} md={12}>

                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>{t('common.expiredAt')}</Form.Label>
                                <Form.Control type="date" />
                            </Form.Group>
                        </Col>
                    </Row>
                </Container>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>Type</th>
                        <th>Permissions</th>
                        <th>Filtres Personnalisés</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Venues</td>
                        <td>
                            {Object.keys(venuesPermissions).map((key) => (
                                <Form.Check
                                    key={key}
                                    type="checkbox"
                                    label={key.charAt(0).toUpperCase() + key.slice(1)}
                                    name={key}
                                    checked={venuesPermissions[key]}
                                    onChange={handleVenuePermissionChange}
                                />
                            ))}
                        </td>
                        <td>
                            <Form.Select multiple={true}>
                                <option value="" disabled selected>
                                    Sélectionnez un filtre
                                </option>
                                <option value="location">Par localisation</option>
                                <option value="capacity">Par capacité</option>
                            </Form.Select>
                        </td>
                    </tr>
                    <tr>
                        <td>Events</td>
                        <td>
                            {Object.keys(eventsPermissions).map((key) => (
                                <Form.Check
                                    key={key}
                                    type="checkbox"
                                    label={key.charAt(0).toUpperCase() + key.slice(1)}
                                    name={key}
                                    checked={eventsPermissions[key]}
                                    onChange={handleEventPermissionChange}
                                />
                            ))}
                        </td>
                        <td>
                            <Form.Select>
                                <option value="" disabled selected>
                                    Sélectionnez un filtre
                                </option>
                                <option value="date">Par date</option>
                                <option value="category">Par catégorie</option>
                            </Form.Select>
                        </td>
                    </tr>
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>Annuler</Button>
                <Button variant="primary" onClick={handleSave}>Enregistrer</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ApiKeyConfigModal;
