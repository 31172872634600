import axiosClient from "./AxiosInstance";

import {createZitadelAuth, ZitadelAuth, ZitadelConfig} from "@zitadel/react";
import {User} from 'oidc-client-ts';
import {isEmpty} from "../utils/utils";
import {AVAILABLE_PLAN_TYPE} from "../utils/constants";

import {RegistrationRequestsInterface} from "../@interfaces/RegistrationRequestsInterface";
import {PaginationHeader} from "../@interfaces/PaginationHeader";
import CompaniesService from "./CompaniesService";


const config: ZitadelConfig = {
    authority: process.env.REACT_APP_ZITADEL_AUTHORITY,
    client_id: process.env.REACT_APP_ZITADEL_CLIENT_ID,
    //scope:"openid%20email%20profile%20urn%3Azitadel%3Aiam%3Aorg%3Aid%3A283308643338682679",
    //scope:"urn:zitadel:iam:org:id:283308643338682679",
    redirect_uri: process.env.REACT_APP_ZITADEL_REDIRECT_URI,
    post_logout_redirect_uri: process.env.REACT_APP_ZITADEL_POST_LOGOUT_REDIRECT_URI
};

const zitadelConfig = createZitadelAuth(config,);
interface RegistrationRequestsResponse {
    data: RegistrationRequestsInterface[];
    headers: any;
}

const emptyFilter = {
    pagination: {
        currentPage: 1,
        pageSize: 0,
    },
    filters: null
}

export default class AuthenticationService {

    private static zitadelConfig: ZitadelAuth;

    static configureZitadel(config: ZitadelConfig) {
        AuthenticationService.zitadelConfig = createZitadelAuth(config);
    }

    static async login(credential: { identifier: string, secret: string }) {
        try {
            const response = await axiosClient.post("/auth", credential);
            localStorage.setItem("token", response.data.jwt);
        } catch (error) {
            throw error;
        }
    }

    static async tokenIntrospection(token: string) {
        try {
            const response = await axiosClient.post("/auth/introspect", {token: token});

            localStorage.setItem("loggedUser", JSON.stringify(response.data));

        } catch (error) {
            throw error;
        }
    }

    static async loginZitadel() {
        try {
            await zitadelConfig.authorize();
        } catch (error) {
            console.error("Login error:", error);
            throw error;
        }
    }

    static async isAuthenticated(): Promise<boolean> {
        try {
            if (AuthenticationService.zitadelConfig) {
                const user = await AuthenticationService.zitadelConfig.userManager.getUser();
                return !!user && !!localStorage.getItem('loggedUser');
            }
            return false;
        } catch (error) {
            throw error;
        }
    }
    static async isInTrial(): Promise<boolean> {
        try {
            const loggedUser = JSON.parse(localStorage.getItem("loggedUser") ?? "");
            if(!isEmpty(loggedUser)) {
                return loggedUser.company.plan === AVAILABLE_PLAN_TYPE.TRIAL
            }
            return false;
        } catch (error){
            throw error
        }
    }

    static async isTicketrAdminUser(): Promise<boolean> {
        try {
            const loggedUser = JSON.parse(localStorage.getItem("loggedUser") ?? "");
            if(!isEmpty(loggedUser)) {
                return loggedUser.company.name.toString().trim() === "ticketr"
            }
            return false;
        } catch (error){
            throw error
        }
    }

    static async getCurrentUserCompany(): Promise<any> {
         const loggedUser = JSON.parse(localStorage.getItem("loggedUser") ?? "");
            if(!isEmpty(loggedUser)) {
                return loggedUser.company
            }
            return null;
    }

    static async handleSigninRedirectCallback(): Promise<User | null> {
        try {
            return await AuthenticationService.zitadelConfig.userManager.signinRedirectCallback();

        } catch (error) {
            //console.error("Error handling signin redirect callback:", error);
            throw error;
        }
    }

    static async getLoggedUserInfo(): Promise<User | null> {
        try {
            return await AuthenticationService.zitadelConfig.userManager.getUser();
        } catch (error) {
            throw error;
        }
    }

    static async logout() {
        try {
            if (AuthenticationService.zitadelConfig) {
                localStorage.removeItem('loggedUser');
                localStorage.clear();
                await AuthenticationService.zitadelConfig.signout();
            }
        } catch (error) {
            throw error;
        }
    }

    static async registerCompany(payload: any): Promise<any> {
        try {
            const response = await axiosClient.post("/auth/register", payload);
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    static async registerRequest(payload: any): Promise<any> {
        try {
            const response = await axiosClient.post("/auth/register/request", payload);
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    static async refreshToken(): Promise<any> {
        try {
             const loggedUser = localStorage.getItem('loggedUser')
            if(loggedUser) {
                const user = JSON.parse(loggedUser);
                const response = await axiosClient.post("/auth/refresh", {refresh_token:user.refresh_token})
                user.access_token = response.data.access_token;
                user.refresh_token = response.data.refresh_token;
                localStorage.setItem('loggedUser', JSON.stringify(user));
                return response.data;

            } else{
                return await AuthenticationService.logout()

            }


        }catch (error){
            throw error;
        }
    }
    static async retrieveRegistrationRequests({pagination, filters}: {
        pagination: PaginationHeader,
        filters: any
    } = emptyFilter): Promise<RegistrationRequestsResponse> {
        try {
            const formattedFilters = CompaniesService.formatFiltersToQueryString(filters)
            const response = await axiosClient.get(`/auth/register/request${!isEmpty(formattedFilters) ? `?${formattedFilters}` : ''}`, {
                headers: {
                    'page': pagination.currentPage,
                    'page-size': pagination.pageSize
                }
            })
            return {
                data: response.data,
                headers: response.headers
            };
        } catch (error) {
            throw error;
        }

    }




}
AuthenticationService.configureZitadel(config);