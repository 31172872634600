import "./SideBar.scss";
import routes from "../../router/routes";

import React, {useEffect, useState} from "react";
import { NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";
import AuthenticationService from "../../services/AuthenticationService";


interface SideBarProps {
    isSideBarVisible?: boolean;
}

function SideBar({isSideBarVisible}: SideBarProps) {
    // const menus = routes.filter((item: any) => item.showOnSideBar === true );

    const [menus, setMenus] = useState<any[]>([]);
    const {t} = useTranslation();
    useEffect(() => {

        const checkUserType = async () => {
            try {
                const isTicketrUser = await AuthenticationService.isTicketrAdminUser();
                const availableMenus = routes.filter((item: any) =>
                    item.showOnSideBar && (!item.isForTicketrTeamOnly || isTicketrUser)
                );
                setMenus(availableMenus);

            } catch (error) {
                console.error('Error checking authentication status:', error);

            }
        };

        checkUserType();
    }, []);

    return (
        <aside id="sidebar" className={`sidebar ${isSideBarVisible ? 'opened' : 'collapsed'}`}>
            <ul className="sidebar-nav" id="sidebar-nav">
                {menus.map((item: any, index: number) => (
                    <li className="nav-item" key={index}>
                        <NavLink
                            className={({isActive}) =>
                                `nav-link collapsed ${isActive ? 'active' : ''} ${isSideBarVisible ? '' : 'side-closed'}`
                            }
                            to={item.path}
                        >
                            <i className={item.icon}></i>
                            {isSideBarVisible && <span>{t(item.label)}</span>}
                        </NavLink>
                    </li>
                ))}
            </ul>
        </aside>
    );
}

export default SideBar;
