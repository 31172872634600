import axiosClient from "./AxiosInstance";
import {EventInterface} from "../@interfaces/EventInterface";

import {PaginationHeader} from "../@interfaces/PaginationHeader";
import {isEmpty} from "../utils/utils";

// Define the expected response structure
interface EventsResponse {
    data: EventInterface[];
    headers: any;
}


export default class EventsService {
    static async retrieveEventsAndVenuesCounters(filters:any): Promise<{ data:any }> {
        try {

            //const formattedFilters = EventsService.formatFiltersToQueryString(filters)
            const response = await axiosClient.get(`/counters`)
            return {
                data: response.data
            };
        } catch (error) {
            throw error;
        }

    }
    static async retrieveEvents({pagination, filters}: {
        pagination: PaginationHeader,
        filters: any
    }): Promise<EventsResponse> {
        try {

            const formattedFilters = EventsService.formatFiltersToQueryString(filters)
            const response = await axiosClient.get(`/events${!isEmpty(formattedFilters) ? `?${formattedFilters}` : ''}`, {
                headers: {
                    'page': pagination.currentPage,
                    'page-size': pagination.pageSize
                }
            })
            return {
                data: response.data,
                headers: response.headers
            };
        } catch (error) {
            throw error;
        }

    }

    static async retrieveEventsSharedByMe({pagination, filters}: {
        pagination: PaginationHeader,
        filters: any
    }): Promise<EventsResponse> {
        try {
            //const formattedFilters = EventsService.formatFiltersToQueryString(filters)
            /*const response = await axiosClient.get(`/events${!isEmpty(formattedFilters) ? `?${formattedFilters}` : ''}`, {
                headers: {
                    'page': pagination.currentPage,
                    'page-size': pagination.pageSize
                }
            })*/

            const response = await axiosClient.get(`/events/share`, {
                headers: {
                    'page': pagination.currentPage,
                    'page-size': pagination.pageSize
                }
            })
            return {
                data: response.data,
                headers: response.headers
            };
        } catch (error) {
            throw error;
        }

    }

    static async retrieveEvent(uuid: number) {
        try {
            const response = await axiosClient.get(`/events/${uuid}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    static async shareEvent(payload: { recipient_id: string, target_type: string, target_ids: string[] }) {
        try {
            return await axiosClient.post(`/events/share`, payload);
        } catch (error) {
            throw error;
        }
    }

    /* static formatFiltersToQueryString(filters: any) {
         if (!isEmpty(filters)) {
             const filter = {
                 venue_ids: filters.venues.map((val: any) => val.value),
                 tags: filters.tags.map((val: any) => val.value),
                 start_date: filters.localStartDate,
                 end_date: filters.localEndDate,
                 names: filters.names.map((val: any) => val.text),
                 owned: filters.owned
             }
             // Remove all undefined keys
             const filteredFilter = Object.fromEntries(
                 Object.entries(filter).filter(([key, value]) => value !== undefined && value !== null && value !== '' && value.length !== 0)
             );
             return new URLSearchParams(Object.entries(filteredFilter)).toString();
         }

         return filters;
     }*/

    static formatFiltersToQueryString(filters: any) {
        if (!isEmpty(filters)) {
            const filter = {
                venue_ids: filters.venues.map((val: any) => val.value),
                tags: filters.tags.map((val: any) => val.value),
                start_date: filters.localStartDate,
                end_date: filters.localEndDate,
                names: filters.names.map((val: any) => val.text),
                owned: filters.owned
            };

            // Remove all undefined keys
            const filteredFilter = Object.fromEntries(
                Object.entries(filter).filter(([key, value]) => value !== undefined && value !== null && value !== '' && value.length !== 0)
            );

            // Initialize an array to hold query string parts
            const queryParts: string[] = [];

            // Build the query string manually
            for (const [key, value] of Object.entries(filteredFilter)) {
                if (Array.isArray(value)) {
                    // If value is an array, add each item with the same key
                    value.forEach((item) => {
                        queryParts.push(`${encodeURIComponent(key)}=${encodeURIComponent(item)}`);
                    });
                } else {
                    // For single values, just add it directly
                    queryParts.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
                }
            }

            // Join all parts with '&' to form the final query string
            return queryParts.join('&');
        }

        return filters;
    }


    static async generateRetrieveEventCurlCommand(filters: any) {
        const formattedFilters = EventsService.formatFiltersToQueryString(filters)
        const url = `${process.env.REACT_APP_API_URL}/events${!isEmpty(formattedFilters) ? `?${formattedFilters}` : ''}`
        const token = 'YOUR_BEARER_TOKEN'
        return `curl -X GET "${url}" -H "Authorization: Bearer ${token}"`
    }
}