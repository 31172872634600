import { useState } from "react";
import { Container } from "react-bootstrap";
import './UploadComponent.scss';

interface IUploadComponentProps {
    label: string;
    maxSize: string;
}

function UploadComponent({ label, maxSize }: IUploadComponentProps) {
    const [file, setFile] = useState<File | null>(null);
    const [preview, setPreview] = useState<string | null>(null);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0];
        if (selectedFile) {
            setFile(selectedFile);
            const fileUrl = URL.createObjectURL(selectedFile);
            setPreview(fileUrl);
        }
    };

    return (
        <Container className="file-upload-wrapper-container">
            <div className="file-upload-wrapper">
                <input
                    type="file"
                    id="file-upload"
                    onChange={handleFileChange}
                />

                {preview ? (
                    <div className="preview-wrapper">
                        {/* Aperçu de l'image */}
                        {file?.type.startsWith("image/") && (
                            <img src={preview} alt="Preview" className="preview-image" />
                        )}

                        {/* Aperçu de la vidéo */}
                        {file?.type.startsWith("video/") && (
                            <video controls className="preview-video">
                                <source src={preview} type={file.type} />
                                Votre navigateur ne supporte pas la lecture vidéo.
                            </video>
                        )}

                        {/* Aperçu d'autres types de fichiers */}
                        {!file?.type.startsWith("image/") && !file?.type.startsWith("video/") && (
                            <div className="file-preview">
                                <p>Aperçu du fichier: {file?.name}</p>
                            </div>
                        )}
                    </div>
                ) : (
                    <>
                        <div>{label}</div>
                        <i className="bi bi-camera-fill camera-icon"></i>
                        <div>Maximum file size: {maxSize}</div>
                    </>
                )}
            </div>
        </Container>
    );
}

export default UploadComponent;
