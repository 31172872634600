import {Badge, Button, Card, Col, Modal, Row} from "react-bootstrap";

import {useTranslation} from "react-i18next";
import {VenueInterface} from "../../@interfaces/VenueInterface";
import React from "react";
import {MapContainer, Marker, Popup, TileLayer} from "react-leaflet";
import SearchField from "../../views/EventsView/EventsMap/MapSearchField";
import {LatLng} from "leaflet";
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

interface VenueDetailsModalProps {
    venue: VenueInterface,
    onHide: () => void,
    show: boolean
}

function VenueDetailsModal({venue, onHide, show}: VenueDetailsModalProps) {
    const {t} = useTranslation();
    const position = new LatLng(venue.address?.latitude ?? 0.0, venue.address?.longitude ?? 0.0);
    return (
        <Modal
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={show}
            animation={true}
            onHide={onHide}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {t('common.venueDetails')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Card>
                    <Card.Body>
                        <Row>
                            <Col sm={12} md={8}>
                                <MapContainer zoom={16} style={{height: "50vh", width: "100%"}}
                                              center={[venue.address?.latitude ?? 48.8566, venue.address?.longitude ?? 2.3522]}
                                >

                                    {<SearchField apiKey={""}/>}
                                    <TileLayer
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                    />


                                    <Marker position={position}>
                                        <Popup>
                                            <strong>{venue.name}</strong><br/>
                                            <strong>{t('common.tags')} </strong>: {venue?.tags?.map((data) => data.name).join(',')}
                                            <br/>
                                            <strong>{t('common.venue')} : </strong> {venue.name} <br/>
                                            <strong>{t('common.address')} : </strong> {`${venue?.address?.address},${venue?.address?.postal_code},${venue?.address?.city}`}
                                        </Popup>
                                    </Marker>
                                </MapContainer>
                            </Col>
                            <Col sm={12} md={4}>
                                <Card.Title><strong>{venue.name}</strong></Card.Title>
                                <Card.Text>
                                    <p>
                                        <strong>{t('common.address')}</strong> : {venue.address ? `${venue?.address?.address}, ${venue?.address?.postal_code}, ${venue?.address?.city}, ${venue?.address.country} ` : "N/A"}
                                    </p>
                                    <div className="mb-3">
                                        <p><strong>{t('common.tags')}</strong></p>
                                        {venue.tags?.map((tag, index) => (
                                            <Badge bg="secondary" className="me-2"
                                                   key={index}>{tag.name}</Badge>
                                        ))}
                                    </div>
                                </Card.Text>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onHide} className="rounded">{t('common.close')}</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default VenueDetailsModal;