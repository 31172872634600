import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {ApiKeyInterface} from "../../@interfaces/ApiKeyInterface";
import {Form, Table, Button, Badge} from "react-bootstrap";
import {formatDateTimeWithTimeZone} from "../../utils/dateFormatter";
import ApiKeyConfigModal from "../../modals/ApiKeyConfigModal/ApiKeyConfigModal";


interface ApiKeysListViewProps {
    apiKeys: ApiKeyInterface[];
}

function ApiKeysListView({apiKeys}: ApiKeysListViewProps) {
    const {t, i18n} = useTranslation();
    const [showApiKeyConfigModal, setShowApiKeyConfigModal] = useState(false);
    const [selectedAll, setSelectedAll] = useState(false);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState(apiKeys.map(_ApiKeys => false));


    useEffect(() => {
        setSelectedCheckboxes(apiKeys.map(() => false));
        setSelectedAll(false);
    }, [apiKeys]);
    const handleSelectAll = () => {
        const newSelectedAll = !selectedAll;
        setSelectedAll(newSelectedAll);
        setSelectedCheckboxes(apiKeys.map(() => newSelectedAll));
    };

    // this is used to handle simple select
    const handleSelectCheckbox = (index: any) => {
        const newSelectedCheckboxes = [...selectedCheckboxes];
        newSelectedCheckboxes[index] = !newSelectedCheckboxes[index];
        setSelectedCheckboxes(newSelectedCheckboxes);
        setSelectedAll(newSelectedCheckboxes.every(selected => selected));
    };

    return (<>
        {<Table striped responsive>
            <thead>
            <tr>
                <th>#</th>
                <th>
                    <Form.Check
                        type="checkbox"
                        id={`select-all-checkbox`}
                        checked={selectedAll}
                        onChange={handleSelectAll}
                    />
                </th>
                <th>{t('common.apiKeyIdentifier')}</th>
                <th>{t('common.owner')}</th>
                <th>{t('common.callCount')}</th>
                <th>{t('common.status')}</th>
                <th>{t('common.expiredAt')}</th>
                <th>{t('common.createdAt')}</th>
                <th>{t('common.action')}</th>
            </tr>
            </thead>
            <tbody>

            {apiKeys.map((ApiKeys: ApiKeyInterface, index) => (
                <tr key={ApiKeys.id}>
                    <td>{index + 1}</td>
                    <td>
                        <Form.Check // prettier-ignore
                            type="checkbox"
                            id={`checkbox-${ApiKeys.id}`}
                            checked={selectedCheckboxes[index] || false}
                            onChange={() => handleSelectCheckbox(index)}
                        />
                    </td>
                    <td>{ApiKeys.api_id}</td>
                    <td>{ApiKeys.company?.name}</td>
                    <td>{ApiKeys.callCount}</td>
                    <td>{ApiKeys.status == 1 ? <Badge className="primary">{t('common.active')}</Badge> :
                        <Badge className="secondary">{t('common.notActive')}</Badge>}</td>
                    <td>{formatDateTimeWithTimeZone(ApiKeys.expiration_date, `${i18n.resolvedLanguage}-${i18n.resolvedLanguage?.toUpperCase()}`)}</td>
                    <td>{formatDateTimeWithTimeZone(ApiKeys.created_at, `${i18n.resolvedLanguage}-${i18n.resolvedLanguage?.toUpperCase()}`)}</td>
                    <td className="justify-content-between">
                        <Button variant="outline-primary" size="sm" onClick={() => setShowApiKeyConfigModal(true)}>
                            <i className="bi bi-pencil-fill"></i>
                        </Button>
                    </td>
                </tr>
            ))
            }

            </tbody>
        </Table>
        }
        <ApiKeyConfigModal showModal={showApiKeyConfigModal} onHide={() => setShowApiKeyConfigModal(false)}/>

    </>)
}

export default ApiKeysListView;