import axiosClient from "./AxiosInstance";
import {VenueInterface} from "../@interfaces/VenueInterface";
import {PaginationHeader} from "../@interfaces/PaginationHeader";
import {isEmpty} from "../utils/utils";

interface VenuesResponse {
    data: VenueInterface[];
    headers: any;
}

const emptyFilter = {
    pagination: {
        currentPage: 1,
        pageSize: 0,
    },
    filters: null
}

export default class VenuesService {
    static async retrieveVenues({pagination, filters}: {
        pagination: PaginationHeader,
        filters: any
    } = emptyFilter): Promise<VenuesResponse> {
        try {
            const formattedFilters = VenuesService.formatFiltersToQueryString(filters)
            const response = await axiosClient.get(`/venues${!isEmpty(formattedFilters) ? `?${formattedFilters}` : ''}`, {
                headers: {
                    'page': pagination.currentPage,
                    'page-size': pagination.pageSize
                }
            });
            return {
                data: response.data,
                headers: response.headers
            };
        } catch (error) {
            throw error;
        }

    }

    static formatFiltersToQueryString(filters: any) {
        if (!isEmpty(filters)) {
            const filter = {
                //ids: filters.venues.map((val: any) => val.value),
                tags: filters.tags.map((val: any) => val.text),
                address: filters.address,
                names: [filters.name],
                owned: filters.owned

            }
            // Remove all undefined keys
            /*const filteredFilter = Object.fromEntries(
                Object.entries(filter).filter(([key, value]) => {
                    if (Array.isArray(value)) {
                        return value.length != 0 && value.every((val: any) => val !== undefined && val !== null && val !== '')
                    } else {
                        return value !== '' && value !== undefined && value !== null
                    }
                })
            );
            return new URLSearchParams(Object.entries(filteredFilter)).toString();*/
            // Remove all undefined keys

            const filteredFilter = Object.fromEntries(
                Object.entries(filter).filter(([key, value]) => {
                    if (Array.isArray(value)) {
                        return value.length !== 0 && value.every((val: any) => val !== undefined && val !== null && val !== '')
                    } else {
                        return value !== '' && value !== undefined && value !== null
                    }
                })
            );

            // Initialize an array to hold query string parts
            const queryParts: string[] = [];

            // Build the query string manually
            for (const [key, value] of Object.entries(filteredFilter)) {
                if (Array.isArray(value)) {
                    // If value is an array, add each item with the same key
                    value.forEach((item) => {
                        queryParts.push(`${encodeURIComponent(key)}=${encodeURIComponent(item)}`);
                    });
                } else {
                    // For single values, just add it directly
                    queryParts.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
                }
            }

            // Join all parts with '&' to form the final query string
            return queryParts.join('&');
        }

        return filters;
    }
}