import {fr, enGB} from 'date-fns/locale'
import {format} from 'date-fns/format'
import {isValid} from "date-fns";


export const formatDateTimeWithTimeZone = (date: string,  locale = 'en-GB',dateFormat = 'dd/MM/yyyy HH:mm:ss',) => {
    const parsedDate = new Date(date);
    if(!isValid(parsedDate)){
        return "N/A"
    }
    const formattedDate = format(parsedDate, dateFormat) ?? "N/A";
    const dateTimeFormat = new Intl.DateTimeFormat(locale,  {timeZoneName: 'short'});
    const parts = dateTimeFormat.formatToParts(new Date(date));
    const timeZone = parts.find(part => part.type === 'timeZoneName')?.value;

    return `${formattedDate} ${timeZone}`;
};
