import PaymentForm from "../../components/PaymentForm/PaymentForm";
import {Button, Col, Container, Form, Row, Table} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import React from "react";

function PaymentMethodsView() {
    const {t, i18n} = useTranslation();
    return (
        <Container>
            <Row>
                <Col sm={12} md={8} lg={8} className="py-5">
                    <h4>{t('common.invoices')}</h4>
                    <Table responsive bordered className="rounded-table">
                        <thead className="thead-danger border-left">
                        <tr>
                            <th><Form.Check
                                type="checkbox"
                                id={`checkbox-invoice-check-all`}
                            /></th>
                            <th>{t('common.invoiceNumber')}</th>
                            <th>{t('common.unitPrice')}</th>
                            <th>{t('common.invoiceSentDate')}</th>
                            <th>{t('common.action')}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>
                                <Form.Check
                                    type="checkbox"
                                    id={`checkbox-invoice-line`}
                                />
                            </td>
                            <td>202201-1239</td>
                            <td>290,00€</td>
                            <td>02/01/2022</td>

                            <td>
                                <Button variant="outline-primary" className="rounded"><i
                                    className="bi bi-download"></i> {t('common.download')}</Button>
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                </Col>
                <Col sm={12} md={4} lg={4}>
                    <PaymentForm/>
                </Col>
            </Row>
        </Container>
    )
}

export default PaymentMethodsView