import React, {useEffect, useState} from 'react';
import {Navigate} from 'react-router-dom';
import AuthenticationService from "../../services/AuthenticationService";
import LoadingComponent from "../../components/LoadingComponent/LoadingComponent";

interface ProtectedRouteProps {
    element: JSX.Element;
    isForTicketrTeamOnly: boolean
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({element, isForTicketrTeamOnly}) => {
    const [authenticated, setAuthenticated] = useState<boolean | null>(null);
    //const [isTrialPlan, setIsTrialPlan] = useState<boolean | null>(null);
    const [isTickterUser, setIsTickterUser] = useState<boolean | null>(null);
    useEffect(() => {
        const checkAuthStatus = async () => {
            try {
                const isAuth = await AuthenticationService.isAuthenticated();
                const isTicketrUser = await AuthenticationService.isTicketrAdminUser();
                //const isTrial = await AuthenticationService.isInTrial();
                //setIsTrialPlan(isTrialPlan);
                setIsTickterUser(isTicketrUser);
                setAuthenticated(isAuth);
            } catch (error) {
                //console.error('Error checking authentication status:', error);
                setAuthenticated(false);
            }
        };

        checkAuthStatus();
    }, [authenticated]);
    if (authenticated === null) {
        return <LoadingComponent/>;  // Ou tout autre composant de chargement
    }

    return authenticated ? (isTickterUser === false && isForTicketrTeamOnly ?
            <Navigate to="/restricted-access" replace={true}/> : <>{element}</>)
        : (<Navigate to="/auth/login" replace={true}/>);
};

export default ProtectedRoute;
