import {
    Col, Container,
    Row,
} from "react-bootstrap";

import ProfileForm from "../../components/ProfileForm/ProfileForm";
import UploadComponent from "../../components/Upload/UploadComponent";
import "./ProfileView.scss"

function ProfileView() {

    return (

        <Container fluid className="py-5 px-5">
            <Row>
                <Col lg={4} md={4} sm={12} className="upload-container text-center py-5">
                    <UploadComponent label="Photo" maxSize="2Mo"/>
                </Col>
                <Col lg={8} md={8} sm={12}>
                    <ProfileForm/>
                </Col>
            </Row>
        </Container>
    )
}

export default ProfileView;