import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";

import {PaginationHeader} from "../../@interfaces/PaginationHeader";

import LoadingComponent from "../../components/LoadingComponent/LoadingComponent";

import {CompanyInterface} from "../../@interfaces/CompanyInterface";
import CompaniesService from "../../services/CompaniesService";
import CompaniesFilters from "../../components/Companies/CompaniesFilters/CompaniesFilters";
import CompanyListView from "../../views/CompanyListView/CompanyListView";
import CompanyInitializerFormModal from "../../modals/CompanyInitializerFormModal/CompanyInitializerFormModal";
import PaginationWithPageSizeSelector from "../../components/Pagination/PaginationWithSizeSelectorComponent";
import {parseResponseHeaders} from "../../utils/ResponseWrapper";

import {Button} from "react-bootstrap";



interface CompaniesPerPage {
    page: number;
    items: CompanyInterface[];
}

export function CompaniesListPage() {
    const {t} = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const [showInitializerFormModal, setShowInitializerFormModal] = useState(false);
    const [companies, setCompanies] = useState<CompaniesPerPage[]>([]);
    const [currentPageCompaniesData, setCurrentPageCompaniesDatas] = useState<CompanyInterface[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(50);
    const [totalCount, setTotalCount] = useState(0);

    const handlePageChange = (pageNumber: number) => {
        if (pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
            const pageData = getCurrentPageCompanies(pageNumber);
            if (pageData.items.length > 0) {
                setCurrentPageCompanies(pageNumber);
            } else {
                fetchCompanies({currentPage: pageNumber, pageSize: itemsPerPage});
            }
        }
    };

    const getCurrentPageCompanies = (pageNumber: number): CompaniesPerPage => {

        return companies.find(element => element.page === pageNumber) || {page: pageNumber, items: []};
    };

    const setCurrentPageCompanies = (pageNumber: number) => {
        try {
            const companiesPage = getCurrentPageCompanies(pageNumber);
            setCurrentPageCompaniesDatas(companiesPage.items)
        } catch (e: any) {
            console.error('error on line ', e);
        }

    };

    const handlePageSizeChange = (event: any) => {
        try {
            setItemsPerPage(Number(event.target.value));
            setCompanies([]);
            fetchCompanies({currentPage: 1, pageSize: Number(event.target.value)});
        } catch (e: any) {
            console.error('error on line ', e);
        }

    };

    const applyFilters = (values: any) => {
        try {
            fetchCompanies({currentPage: 1, pageSize: itemsPerPage}, values);
        } catch (e: any) {
            console.error('error on line ', e);
        }
    }


    const fetchCompanies = async (pagingData: PaginationHeader = {
        currentPage: 1,
        pageSize: itemsPerPage
    }, filter = {}) => {
        try {

            setIsLoading(true);

            const response = await CompaniesService.retrieveCompanies({pagination: pagingData, filters: filter});
            const {total_pages, page_size, total_count} = parseResponseHeaders(response.headers);
            setTotalPages(total_pages);
            setItemsPerPage(page_size);
            setTotalCount(total_count);
            setCompanies(prevData => [...prevData, {
                page: Number.isNaN(Number(response.headers.page)) ? 0 : Number(response.headers.page),
                items: response.data
            }]);
            setCurrentPageCompaniesDatas(response.data)
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching events profile:', error);
            setIsLoading(false);
        }
    };


    return (
        <>
            {isLoading && (
                <LoadingComponent/>
            )}
            {<CompaniesFilters setFiltersValue={applyFilters}/>}
            <PaginationWithPageSizeSelector itemsPerPage={itemsPerPage} totalCount={totalCount}
                                            currentPage={currentPage} totalPages={totalPages}
                                            handlePageChange={handlePageChange}
                                            handlePageSizeChange={handlePageSizeChange}/>
            <div className="d-flex justify-content-end my-sm-3 my-3">


                <Button variant="primary" size={"sm"} onClick={() => setShowInitializerFormModal(true)}> <i
                    className="bi bi-plus"></i>{t('common.add')}</Button>

            </div>

            <CompanyListView companies={currentPageCompaniesData}/>
            <CompanyInitializerFormModal showModal={showInitializerFormModal}
                                         onHide={() => setShowInitializerFormModal(false)}
                                         fetchCompanies={fetchCompanies}/>

        </>
    );
}

export default CompaniesListPage;