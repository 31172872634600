import {ITRTicketingInterface} from "../../@interfaces/ITRTicketingInterface";
import {Button, Form, Table} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import ITRCredentialsModal from "../../modals/ITRCredentialsModal/ITRCredentialsModal";
import {useTranslation} from "react-i18next";

const ITRsList: ITRTicketingInterface[] = [
    {name: "YurPlan", status: "Connecté"},
    {name: "Weezevent", status: "Connecté"},
    {name: "Ticket Master", status: "Connecté"},
    {name: "Viva ticket", status: "Connecté"},
    {name: "Fnac", status: "Non connecté"}
]


function ITRListView() {
    const {t, i18n} = useTranslation();
    const [selectedAll, setSelectedAll] = useState(false);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState(ITRsList.map(itr => false));
    const [modalShow, setModalShow] = useState(false);

    useEffect(() => {
        setSelectedCheckboxes(ITRsList.map(() => false));
        setSelectedAll(false);
    }, [ITRsList]);


    //this is also used to handle all selected checkbox
    const handleSelectAll = () => {
        const newSelectedAll = !selectedAll;
        setSelectedAll(newSelectedAll);
        setSelectedCheckboxes(ITRsList.map(() => newSelectedAll));
    };

    // this is used to handle simple select
    const handleSelectCheckbox = (index: any) => {
        const newSelectedCheckboxes = [...selectedCheckboxes];
        newSelectedCheckboxes[index] = !newSelectedCheckboxes[index];
        setSelectedCheckboxes(newSelectedCheckboxes);
        setSelectedAll(newSelectedCheckboxes.every(selected => selected));
    };
    return (
        <>
            <div className="d-flex justify-content-end my-sm-3 my-3">

                <Button variant="primary" size={"sm"}> {t('ITRBilleterieView.findTicketingSoftware')}</Button>
                {/*<Button variant="primary" size={"sm"} onClick={() => setShowAddAccountFormModal(true)}> <i
                    className="bi bi-plus"></i>{t('common.add')}</Button>*/}
                <Button variant="primary" onClick={() => setModalShow(true)}
                        size={"sm"}><i className="bi bi-plus"></i> {t('ITRBilleterieView.addITR')}</Button>
                <Button variant="danger" size={"sm"}>{t('ITRBilleterieView.deleteITR')}</Button>

            </div>
            <Table striped responsive>
                <thead>
                <tr>
                    <th>
                        <Form.Check
                            type="checkbox"
                            id={`select-all-checkbox`}
                            checked={selectedAll}
                            onChange={handleSelectAll}
                        />
                    </th>
                    <th></th>
                    <th>
                        {t('ITRBilleterieView.ticketingSoftware')}
                    </th>
                    <th >{t('common.status')}</th>
                    <th>{t('common.lastImportDate')}</th>

                </tr>
                </thead>
                <tbody>
                {ITRsList.map((itr: ITRTicketingInterface, index) => (
                    <tr key={index}>

                        <td>
                            <Form.Check // prettier-ignore
                                type="checkbox"
                                id={`checkbox-${itr.name}`}
                                checked={selectedCheckboxes[index] || false}
                                onChange={() => handleSelectCheckbox(index)}
                            /></td>
                        <td>
                            <img src="https://upload.wikimedia.org/wikipedia/commons/2/2e/Fnac_Logo.svg"
                                 className="img-responsive" alt="logo img" height="70" width="70"/>
                        </td>
                        <td>{itr.name}</td>

                        <td className="text-primary">{itr.status}</td>
                        <td></td>
                    </tr>))}

                </tbody>
            </Table>


            {/*
            <Row className="py-3">
                <Col xs={12} sm={12} md={8}>
                    <Button variant="primary" className="rounded"> {t('ITRBilleterieView.findTicketingSoftware')}</Button>
                </Col>
                <Col xs={12} sm={12} md={4}>
                    <Button variant="secondary"  onClick={() => setModalShow(true)} className="rounded  rounded-primary">{t('ITRBilleterieView.addITR')}</Button>
                    <Button variant="danger"  className="rounded rounded-red mx-2">{t('ITRBilleterieView.deleteITR')}</Button>
                </Col>
            </Row>*/}

            <ITRCredentialsModal show={modalShow} onHide={() => setModalShow(false)}/>
        </>
    )
}

export default ITRListView;