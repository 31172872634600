import {Button, Col, Form, Row} from "react-bootstrap";
import 'react-phone-number-input/style.css'
import {isValidPhoneNumber} from 'react-phone-number-input';

import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import * as yup from "yup";
import {Formik, FormikErrors, FormikTouched} from "formik";
//import CompanyAttachmentsUploadModal from "../../../modals/CompanyAttachmentsUploadModal/CompanyAttachmentsUploadModal";

//import {LocalOffice} from "../../../@interfaces/LocalOffice";
import {LegalInformationInterface} from "../../../@interfaces/LegalInformation";
import LegalInformation from "./LegalInformation/LegalInformation";
/*import LocalOffices from "./LocalOffices/LocalOffices";
import RegistrationProof from "./RegistrationProof/RegistrationProof";
import {RegistrationProofInterface} from "../../../@interfaces/RegistrationProofInterface";*/
import {LegalStatus} from "../../../utils/legalStatusEnum";
import {createSiretValidation} from "../../../utils/validation";


function CompanyForm() {
    const {t, i18n} = useTranslation();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [attachments, setAttachments] = useState<string[]>([]);
    const [chosenAttachments, setChosenAttachments] = useState<{ documentName: string, file: File | null }[]>([]);
    const formInitialValues = {
        legalInformation: {
            name: '',
            businessName: '',
            businessSector: [],
            headOfficeCountry: 'FR',
            legalStatus: '',
            headOfficeAddress: '',
            headOfficePostalCode: '',
            headOfficeCity: '',
            headOfficeRegion: '',
            headOfficeAdditionalAddress: '',
            email: '',
            phoneNumber: '',
            websiteUrl: '',
            vatNumber: '',
            siretNumber: '',
            logoUrl: '',
        },
        /*localOffices: [{postalAddress: '', siretNumber: ''}],

        registrationProof: {
            attachmentsList: null,
            entertainmentLicenseReceiptNumber: '',
            licenceCategories: [],
            atoutFranceRegistrationNumber: '',

        }*/
    };


    const legalInformationSchema = yup.object().shape({
        name: yup.string().required(i18n.t('common.inputRequired')),
        businessName: yup.string().optional(),
        businessSector: yup.array().optional(),
        headOfficeCountry: yup.string().required(i18n.t('common.inputRequired')),
        legalStatus: yup.string().required(i18n.t('common.inputRequired')),
        headOfficeAddress: yup.string().required(i18n.t('common.inputRequired')),
        headOfficeAdditionalAddress: yup.string().optional(),
        headOfficePostalCode: yup.string().required(i18n.t('common.inputRequired')),
        headOfficeCity: yup.string().required(i18n.t('common.inputRequired')),
        headOfficeRegion: yup.string().required(i18n.t('common.inputRequired')),
        email: yup.string().email('common.invalidEmail').required(i18n.t('common.inputRequired')),
        phoneNumber: yup.string().required(i18n.t('common.inputRequired')).test('is-valid-phone-number', i18n.t('common.invalidPhoneNumber'), (value) => {
                return isValidPhoneNumber(value)
            }
        ),
        websiteUrl: yup.string().url(i18n.t('common.inputURLInvalid')),
        vatNumber: yup.string().optional()/*.test('is-required', i18n.t('common.inputRequired'), function (value) {
            const {legalStatus} = this.parent;
            return !!(legalStatus && [LegalStatus.ONG,LegalStatus.ASSOC].includes(legalStatus));

        })*/,
        siretNumber: yup.string().when(['legalStatus', 'headOfficeCountry'], {
            is: (legalStatus: LegalStatus, headOfficeCountry: string) => [LegalStatus.SAS, LegalStatus.SARL, LegalStatus.SASU, LegalStatus.SCP, LegalStatus.EURL].includes(legalStatus) && headOfficeCountry == "FR",
            then: () => createSiretValidation(), // Utiliser le schéma SIRET
            otherwise: () => yup.string().optional() // Validation si la condition est fausse
        }),
        logoUrl: yup.string().optional()
    })

    const registrationProofSchema = yup.object().shape({
        attachmentsList: yup.array().nullable()
            .test('attachment-required-depend-on-status', i18n.t('common.attachmentRequired', {value: attachments.join(', ').toUpperCase()}), function (value) {
                return !(attachments.length > 0 && !value);
            }),

        entertainmentLicenseReceiptNumber: yup.string().optional().min(40, i18n.t('companyForm.entertainmentReceiptNumberInvalid')).max(40, i18n.t('companyForm.entertainmentLicenceReceiptNumber')),
        licenceCategories: yup.array().optional(),
        atoutFranceRegistrationNumber: yup.string().optional(),
    })

    const localOfficeSchema = yup.array().of(
        yup.object().shape({
            postalAddress: yup.string().optional(),
            siretNumber: yup.string().when('postalAddress', {
                is: (postalAddress: string) => !!postalAddress,
                then: () => yup.string().when(['$legalInformation.legalStatus', '$legalInformation.headOfficeCountry'], {
                    is: (legalStatus: LegalStatus, headOfficeCountry: string) => [LegalStatus.SAS, LegalStatus.SARL, LegalStatus.SASU, LegalStatus.SCP, LegalStatus.EURL].includes(legalStatus) && headOfficeCountry !== "FR",
                    then: () => createSiretValidation().required(i18n.t('common.inputRequired')),
                    otherwise: () => yup.string().optional(),
                }),
                otherwise: () => yup.string().optional(),
            }),
        })
    );


    const companyFormSchema = yup.object().shape({
        legalInformation: legalInformationSchema,
        /* localOffices: localOfficeSchema,
         registrationProof: registrationProofSchema,*/
    })


    const handleFormSubmit = (values: any) => {
        setIsLoading(true)
        alert('Form Submitted endpoint will called once it was improved');
        setIsLoading(false);
    }


    const handleChosenAttachment = async (values: any, setFieldValue: (field: string, value: any) => void, validateField: (field: string) => Promise<any>) => {
        setChosenAttachments(values === null ? [] : values)

        setFieldValue('registrationProof.attachmentsList', values);
        if (values === null) {
            await validateField('registrationProof.attachmentsList');
        }

    }
    return (
        <div>
            <Formik
                validationSchema={companyFormSchema}
                onSubmit={handleFormSubmit}
                initialValues={formInitialValues}
                validateOnBlur={true}
                validationOnChange={false}
            >
                {({handleSubmit, setFieldValue, validateField, values, touched, errors}) => (
                    <Form noValidate onSubmit={handleSubmit}>

                        {<LegalInformation handleSubmit={handleSubmit}
                                           setFieldValue={setFieldValue}
                                           valuesLegalInformation={values.legalInformation}
                                           touched={touched.legalInformation as FormikTouched<LegalInformationInterface>}
                                           errors={errors.legalInformation as FormikErrors<LegalInformationInterface>}
                                           setAttachments={async (value) => {
                                               setAttachments(value);
                                               await handleChosenAttachment(null, setFieldValue, validateField);
                                           }}
                        />}

                        {/* <LocalOffices
                            handleSubmit={handleSubmit}
                            setFieldValue={setFieldValue}
                            localOffices={values.localOffices}
                            touchedLocalOffices={touched.localOffices as FormikTouched<LocalOffice>[]}
                            errorsLocalOffices={errors.localOffices as FormikErrors<LocalOffice>[]}
                            t={t}
                        />

                        {attachments.length > 0 && <>
                            <Row>
                                <Col lg={12} md={12} sm={12} className="mt-3 mb-3">
                                    <h4>{t('common.imatProof')}</h4>
                                </Col>
                            </Row>
                            <RegistrationProof handleSubmit={handleSubmit} attachments={attachments}
                                               chosenAttachments={chosenAttachments} setFieldValue={setFieldValue}
                                               setShowModal={(value) => setShowModal(value)}
                                               valuesRegistrationProof={values.registrationProof}
                                               touched={touched.registrationProof as FormikTouched<RegistrationProofInterface>}
                                               errors={errors.registrationProof as FormikErrors<RegistrationProofInterface>}
                                               t={t}
                                               i18n={i18n}
                            />
                        </>
                        }
                        */}
                        <Row>
                            <Col lg={12} md={12} sm={12} className="mt-3 mb-3">
                                <Form.Group className="mb-3" id="formGridCheckbox">
                                    <Button variant="primary" type="submit">{t('common.save')}</Button>
                                </Form.Group>
                            </Col>

                        </Row>
                        {/*<CompanyAttachmentsUploadModal
                            showModal={showModal}
                            requiredAttachments={attachments}
                            onHide={() => setShowModal(false)}
                            setChosenAttachments={async (values) => {
                                await handleChosenAttachment(values.attachments,setFieldValue,validateField);
                                //setFieldValue('registrationProof.attachmentsList', values.attachments)
                            }}
                        />*/}
                    </Form>
                )
                }
            </Formik>


        </div>
    )
}

export default CompanyForm;