import React, { useState } from 'react';
import { Form, InputGroup, Button } from 'react-bootstrap';

const ReadOnlyInputWithCopy = ({ value,type} :{value:string,type:string}) => {
  const [copySuccess, setCopySuccess] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(value);
    setCopySuccess(true);
    setTimeout(() => setCopySuccess(false), 2000); // Réinitialise l'état après 2 secondes
  };

  return (
    <div>
      <InputGroup className="mb-1">
        <Form.Control
          readOnly
          value={value}
          aria-label="Read-only input"
          type={type}
        />
        <Button variant="outline-primary" onClick={handleCopy}>
          <i className="bi bi-clipboard"></i>
        </Button>
      </InputGroup>
      {copySuccess && (
        <small className="alert text-primary">
          Texte copié dans le presse-papier !
        </small>
      )}
    </div>
  );
};

export default ReadOnlyInputWithCopy;