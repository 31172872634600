import React from 'react';
import { Routes, Route } from 'react-router-dom';
import MainLayout from '../layout/MainLayout';
import AuthRoutes from './AuthRoutes';
import ProtectedRoute from "../pages/AuthenticationPages/ProtectedRoute";
import routes from "./routes";
import PublicRoutes from "./PublicRoutes";

const Router = () => {
    return (
        <Routes>
            {/* Routes d'authentification */}
            <Route path="/auth/*" element={<AuthRoutes />} />
            <Route path="/pub/*" element={<PublicRoutes />} />
            {/* Routes principales protégées */}
            <Route path="/" element={<MainLayout />}>
                {routes.map((item: any, index: number) => (
                    <Route
                        key={index}
                        path={item.path}
                        element={
                            item.children ? (
                                item.children.map((childRoute: any, childIndex: number) => (
                                    <Route
                                        key={childIndex}
                                        path={childRoute.path}
                                        element={
                                            childRoute.protected ? (
                                                <ProtectedRoute
                                                    element={childRoute.element}
                                                    isForTicketrTeamOnly={childRoute.isForTicketrTeamOnly}
                                                />
                                            ) : (
                                                childRoute.element
                                            )
                                        }
                                    />
                                ))
                            ) : (
                                item.protected ? (
                                    <ProtectedRoute
                                        element={item.element}
                                        key={item.key}
                                        isForTicketrTeamOnly={item.isForTicketrTeamOnly}
                                    />
                                ) : (
                                    item.element
                                )
                            )
                        }
                    />
                ))}
            </Route>
        </Routes>
    );
};

export default Router;
