import axios from 'axios';
import {logError} from "../utils/ErrorHandler";
import {handleResponse} from "../utils/ResponseWrapper";
import AuthenticationService from "./AuthenticationService";

const axiosClient = axios.create();

axiosClient.defaults.baseURL = process.env.REACT_APP_API_URL;
// @ts-ignore
axiosClient.defaults.headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json'
};

axiosClient.defaults.timeout = 10000;

axiosClient.interceptors.request.use(function (request) {
    const loggedUser = localStorage.getItem('loggedUser')
    if (loggedUser && !request.url?.includes("http")) {
        request.headers['Authorization'] = `Bearer ${(JSON.parse(loggedUser)).access_token}`;
    }
    return request;
}, null, {synchronous: true});

axiosClient.interceptors.response.use(function (response) {
    //Dispatch any action on success
    return handleResponse(response)

}, async function (error) {
    logError(error);
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        if (!originalRequest.url.includes('/auth/refresh')) {

            try {
                const tokens = await AuthenticationService.refreshToken()
                axiosClient.defaults.headers.Authorization = `Bearer ${tokens.access_token}`;
                originalRequest.headers.Authorization = `Bearer ${tokens.access_token}`;
                return axiosClient(originalRequest);
            } catch (error: any) {
                await AuthenticationService.logout();
                return Promise.reject(error);
            }
        }


    }
    return Promise.reject(error);
});

export default axiosClient;