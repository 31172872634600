import {Button, Modal} from "react-bootstrap";
import "./AlertModal.scss"
import {useTranslation} from "react-i18next";
function AlertModal(props:any) {
     const {t, i18n} = useTranslation();
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props.title ?? ""}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    {props.body ?? ""}
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide} className="rounded">{t('common.close')}</Button>
            </Modal.Footer>
        </Modal>
    );
}
export default AlertModal;