import ProfileView from "../../views/ProfileView/ProfileView";
import CompanyView from "../../views/CompanyView/CompanyView";

import ITRListView from "../../views/ITRListView/ITRListView";
import {Tab, Tabs} from "react-bootstrap";
import "./AccountPage.scss"
import PaymentMethodsView from "../../views/PaymentMethodsView/PaymentMethodsView";
function AccountPage() {
    return (

        <>
            <Tabs
                defaultActiveKey="profile"
                id="setting-tabs"
                fill
            >
                <Tab eventKey="profile" title="Profil" className="settings-tab-pannel">
                    <ProfileView/>
                </Tab>
                <Tab eventKey="company" title="Société"  className="settings-tab-pannel">
                    <CompanyView/>
                </Tab>

                 <Tab eventKey="payment-info" title="Paiement" className="settings-tab-pannel">
                    <PaymentMethodsView/>
                </Tab>
                <Tab eventKey="itr-ticketing" title="ITR Billetterie" className="settings-tab-pannel">
                   <ITRListView/>
                </Tab>

            </Tabs>
        </>
    )
}

export default AccountPage;