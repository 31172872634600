import i18n from "i18next";

export const isEmpty = <T extends object>(obj: T): boolean => {
    if (obj === null) return true;
    return Object.keys(obj).length === 0;
};
export const getCountryName = (countryCode: string | null): string => {
    return countryCode ? i18n.t(`country.${countryCode.toUpperCase()}`) : "";
};


// Example usage

