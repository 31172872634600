// AuthRoutes.tsx
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import EventsAndVenuesCount from "../pages/PublicPages/EventsAndVenuesCount/EventsAndVenuesCount";

const PublicRoutes = () => {
    return (
        <Routes>
            <Route path="events-counts" element={<EventsAndVenuesCount />} />

        </Routes>
    );
};

export default PublicRoutes;
