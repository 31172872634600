import {useTranslation} from "react-i18next";
import * as yup from "yup";
import {Col, Container, Form, Row} from "react-bootstrap";
import {Formik} from "formik";
import React, {ForwardedRef, forwardRef, Fragment, useImperativeHandle, useRef} from "react";
import {ACCOUNT_TYPE_LIST, AVAILABLE_PLANS} from "../../utils/constants";

const accountTypeOptions = ACCOUNT_TYPE_LIST;
const renderAccountTypeOptions = (option: string) => {
    return <option value={option}>{option}</option>;
};


const planTypeOptions = AVAILABLE_PLANS.filter(value => value !== "TRIAL");
const renderPlansTypeOptions = (option: string) => {
    return <option value={option.toLowerCase()}>{option}</option>;
}


interface FormHandles {
    validateForm: () => Promise<any>;
    submitForm: () => Promise<any>;
}

interface RegistrationPersonalInformationViewProps {
    setRegistrationStepOneData: (value: any) => void,
    initialValues: {
        email: string,
        firstname: string,
        lastname: string,
        businessName: string,
        accountType: string,
        plan: string,
        name: string,
    } | null
}


const RegistrationPersonalInformationView = forwardRef<FormHandles, RegistrationPersonalInformationViewProps>(({
                                                                                                                   setRegistrationStepOneData,
                                                                                                                   initialValues
                                                                                                               }: RegistrationPersonalInformationViewProps, ref: ForwardedRef<FormHandles>) => {
    const {t} = useTranslation();
    const formikRef = useRef<any>(null);

    const formInitialValues = {
        email: initialValues?.email,
        name: initialValues?.name,
        firstname: initialValues?.firstname,
        lastname: initialValues?.lastname,
        businessName: initialValues?.businessName,

        accountType: initialValues?.accountType,
        plan: initialValues?.plan
    };
    const validationSchema = yup.object().shape({
        name: yup.string().required(t('common.inputRequired')),
        firstname: yup.string().required(t('common.inputRequired')),
        lastname: yup.string().required(t('common.inputRequired')),
        email: yup.string().email(t('common.invalidEmail')).required(t('common.inputRequired')),
        businessName: yup.string().required(t('common.inputRequired')),

        accountType: yup.string().required(t('common.inputRequired')),
        plan: yup.string().required(t('common.inputRequired'))
    });

    const handleFormSubmit = (values: any) => {
        setRegistrationStepOneData(values)

    };


    // Expose methods to parent via ref
    useImperativeHandle(ref, () => {
        return {
            validateForm: () => {

                return formikRef.current?.validateForm() ?? Promise.reject();
            },
            submitForm: () => {
                return formikRef.current?.submitForm() ?? Promise.reject();
            }
        }

    }, []);

    return (
        <Formik
            innerRef={formikRef}
            initialValues={formInitialValues}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
        >
            {({values, setFieldValue, handleSubmit, errors, touched}) => (
                <>
                    <Form noValidate onSubmit={handleSubmit}>
                        <Container>

                            <Row>
                                <Col lg={12} md={12} sm={12}>


                                    <Form.Group className="mb-3" controlId="companyInitializerFormLastNameInput">
                                        <Form.Label>{t('common.companyName')}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            aria-label={t('common.companyName')}
                                            name="name"
                                            placeholder={t('common.pleaseEnterCompanyName')}
                                            value={values.name || ''}
                                            onChange={(e) => setFieldValue('name', e.target.value)}
                                            isInvalid={touched.name && !!errors.name}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.name}
                                        </Form.Control.Feedback>
                                    </Form.Group>


                                    <Form.Group className="mb-3"
                                                controlId="companyInitializerFormBusinessNameInput">
                                        <Form.Label>{t('common.businessName')}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            aria-label={t('common.businessName')}
                                            name="businessName"
                                            placeholder={t('common.pleaseEnterCompanyBusinessName')}
                                            value={values.businessName || ''}
                                            onChange={(e) => setFieldValue('businessName', e.target.value)}
                                            isInvalid={touched.businessName && !!errors.businessName}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.businessName}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6} md={6} sm={12}>
                                    <Form.Group className="mb-3" controlId="companyInitializerFormNameInput">
                                    <Form.Label>{t('common.yourFirstname')}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        aria-label={t('common.firstname')}
                                        name="firstname"
                                        placeholder={t('common.pleaseEnterYourFirstName')}
                                        value={values.firstname || ''}
                                        onChange={(e) => setFieldValue('firstname', e.target.value)}
                                        isInvalid={touched.firstname && !!errors.firstname}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.firstname}
                                    </Form.Control.Feedback>
                                </Form.Group></Col>
                                <Col lg={6} md={6} sm={12}>
                                    <Form.Group className="mb-3" controlId="companyInitializerFormNameInput">
                                    <Form.Label>{t('common.yourLastName')}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        aria-label={t('common.yourLastName')}
                                        name="lastname"
                                        placeholder={t('common.pleaseEnterYourLastName')}
                                        value={values.lastname || ''}
                                        onChange={(e) => setFieldValue('lastname', e.target.value)}
                                        isInvalid={touched.lastname && !!errors.lastname}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.lastname}
                                    </Form.Control.Feedback>
                                </Form.Group></Col>
                            </Row>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <Form.Group className="mb-3" controlId="companyInitializerFormEmailInput">
                                        <Form.Label>{t('common.yourEmail')}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            aria-label={t('common.email')}
                                            name="email"
                                            placeholder={t('common.pleaseEnterCompanyEmail')}
                                            value={values.email || ''}
                                            onChange={(e) => setFieldValue('email', e.target.value)}
                                            isInvalid={touched.email && !!errors.email}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.email}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                            </Row>

                            <Row>
                                <Col lg={6} md={6} sm={12}>
                                    <Form.Group className="mb-3" controlId="companyInitializerFormAccountTypeInput">
                                        <Form.Label>{t('common.accountType')}</Form.Label>
                                        <Form.Select aria-label={t('common.accountType')}
                                                     value={values.accountType || ''}
                                                     name="accountType"
                                                     onChange={(e) => {
                                                         setFieldValue('accountType', e.target.value);
                                                     }}
                                                     isInvalid={touched?.accountType && !!errors?.accountType}>
                                            <option>{t('common.pleaseSelect')}.</option>
                                            {accountTypeOptions.map((opt) => (
                                                <Fragment key={opt}>{renderAccountTypeOptions(opt)}</Fragment>
                                            ))}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.accountType}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col lg={6} md={6} sm={12}>
                                    <Form.Group className="mb-3" controlId="companyInitializerFormAccountPlanInput">
                                        <Form.Label>{t('common.plan')}</Form.Label>
                                        <Form.Select aria-label={t('common.plan')}
                                                     value={values.plan || ''}
                                                     name="plan"
                                                     onChange={(e) => {
                                                         setFieldValue('plan', e.target.value);
                                                     }}
                                                     isInvalid={touched?.plan && !!errors?.plan}>
                                            <option>{t('common.pleaseSelect')}.</option>
                                            {planTypeOptions.map((opt) => (
                                                <Fragment key={opt}>{renderPlansTypeOptions(opt)}</Fragment>
                                            ))}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.plan}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>

                        </Container>
                    </Form>

                </>
            )}
        </Formik>

    )
})

export default RegistrationPersonalInformationView;